<div>
  <div class="container-fluid">
    <div
      style="display: flex; gap: 10px; align-items: center; margin-bottom: 10px"
    >
      Show with FM Jobs?<nz-switch
        [(ngModel)]="fm"
        (ngModelChange)="getGraph()"
      ></nz-switch>
    </div>
    <div id="legend"></div>
    <div id="graph"></div>
  </div>
</div>
