import { ActivatedRoute, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { StoredSetting } from "../../models/storedSetting";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ShotSettings } from "../../models/shotSettings";
import { SettingTypes } from "../../shared/enums/settingTypes";

@Injectable()
export class StoredSettingsService {
  private _synchroniseSettings: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly synchroniseSettings: Observable<boolean> =
    this._synchroniseSettings.asObservable();

  private _synchronised = false;
  private _synchroniseSubscription = this._synchroniseSettings.subscribe(
    (value) => {
      this._synchronised = value;
    }
  );

  public get synchronised() {
    return this._synchronised;
  }

  // an object with setting types as keys and an array of settings as values
  private _syncedSettings: Map<SettingTypes, string[]>;

  private _settingsUpdated: Subject<SettingTypes[]> = new Subject<
    SettingTypes[]
  >();
  public readonly settingsUpdated$: Observable<SettingTypes[]> =
    this._settingsUpdated.asObservable();

  private _settings: { setting: any; tabId: number; key: string }[] = [];

  constructor(private route: ActivatedRoute, private router: Router) {
    var settings = localStorage.getItem("settings");
    if (settings) {
      this._settings = JSON.parse(settings);
    }
  }

  public getSettings<T extends StoredSetting>(
    key: string,
    settingId: any,
    tabId: number,
    initProjectId = ""
  ): T {
    if (!key) {
      console.error("getSettings: key is null");
    }

    let setting = this._settings.find(
      (s) => s.tabId === tabId && s.setting.id === settingId && s.key === key
    );

    if (!setting && tabId == SettingTypes.SliceSettings) {
      if (initProjectId && this._synchroniseSettings.value) {
        let initSettings = this._settings.filter(
          (s) => s.setting.projectId == initProjectId && s.tabId == tabId
        );

        if (initSettings.length > 0) {
          const initSetting = initSettings[0];

          let newSetting = {
            setting: { ...initSetting.setting, id: settingId },
            tabId: tabId,
            key: key,
          };

          this._settings.push(newSetting);

          return newSetting.setting;
        }
      }
    }
    if (!setting) {
      return null;
    }
    return setting.setting;
  }

  public setSettings<T extends StoredSetting>(
    key: string,
    setting: T,
    tabId: number
  ): void {
    if (!key) {
      console.error("setSettings: key is null");
    }
    let old_setting = this._settings.find(
      (s) => s.tabId === tabId && s.setting.id === setting.id && s.key === key
    );
    if (!old_setting) {
      this._settings.push({ setting: setting, tabId: tabId, key: key });
    } else {
      old_setting.setting = setting;
    }

    if (this._synchroniseSettings.value) {
      this.synchronise<T>(key, setting, tabId);
    }
    this.updateStore();
  }

  public setSynchroniseSettings(value: boolean) {
    if (value != this._synchroniseSettings.value) {
      this._synchroniseSettings.next(value);
    }
  }

  public settingsUpdated(settingTypes: SettingTypes[]) {
    console.log("settings updated");

    this._settingsUpdated.next(settingTypes);
  }

  private synchronise<T extends StoredSetting>(
    key: string,
    newSetting: T,
    tabId: number
  ) {
    if (!key) {
      console.error("synchronise: key is null");
    }
    let settings = this._settings.filter(
      (s) =>
        s.setting.projectId == newSetting.projectId &&
        s.tabId == tabId &&
        s.key === key
    );
    if (newSetting.modelId) {
      settings = settings.filter(
        (s) => s.setting.modelId == newSetting.modelId
      );
    }
    for (let setting of settings) {
      for (let [key, value] of Object.entries(setting.setting)) {
        if (key == "id") continue;

        if (tabId == SettingTypes.Shot && key == "iteration") continue;
        if (tabId == SettingTypes.Model && key == "iteration") continue;
        setting.setting[key] = newSetting[key];
      }
    }
  }

  public synchroniseJob(key: string, sourceJobId: string) {
    if (!this._synchroniseSettings.value) {
      console.log("synchroniseJob: not synchronising");

      return;
    }

    let sourceSettings = this._settings.filter(
      (s) => s.setting.id.includes(sourceJobId) && s.key === key
    );

    sourceSettings.forEach((item) => {
      console.log("synchroniseJob: ", item.tabId);

      this.synchronise(key, item.setting, item.tabId);
    });
  }
  clearSelectedShot(key: string, jobId, projectId) {
    var currentSettings = this.getSettings<ShotSettings>(
      key,
      jobId,
      SettingTypes.Shot
    );
    if (currentSettings) {
      currentSettings.selectedShot = null;
      this.setSettings<ShotSettings>(key, currentSettings, projectId);
    }
  }

  private updateStore() {
    localStorage.setItem("settings", JSON.stringify(this._settings));
  }

  private getPathAsKey() {
    console.log(this.router.url);

    return window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
  }
}
