<div class="header">
  <div [ngClass]="showFull ? 'container full' : 'container'">
    <!-- ROW -->
    <div class="row">
      <!-- LOGO -->

      <div id="logo">
        <a href="/"><img src="/assets/logos/s-cube_cloud_logo.png" /></a>
      </div>

      <!--// LOGO -->
      <!-- EXPAND -->
      <a
        [ngClass]="isActive ? 'expand active' : 'expand'"
        (click)="expandMenu($event)"
      >
        <em>Menu</em>
        <span class="line top"></span>
        <span class="line mid"></span>
        <span class="line bottom"></span>
      </a>
      <!--// EXPAND -->
      <!--<nav class="cta-link"><a class="btn no-bg c5-borders c5-text" href="/contact/">Free Trial</a></nav>-->
      <!-- NAVIGATION -->
      <div [ngClass]="isActive ? 'navigation active' : 'navigation'">
        <!-- REVEAL -->
        <div class="reveal">
          <nav class="main">
            <ul>
              <li *ngIf="auth.isAuthenticated() && this.isStaff">
                <span>
                  <a [routerLink]="['/copilot']"> Copilot </a>
                </span>
              </li>
              <li *ngIf="auth.isAuthenticated()">
                <span>
                  <a [routerLink]="['/projects']"> Projects </a>
                </span>
              </li>
              <li *ngIf="!auth.isAuthenticated()">
                <span>
                  <a href="/login/" (click)="auth.login()">Login</a>
                </span>
              </li>
              <li *ngIf="auth.isAuthenticated() && isBillingUser">
                <span>
                  <a [routerLink]="['/billing']">Billing</a>
                </span>
              </li>
              <li *ngIf="auth.isAuthenticated()">
                <span>
                  <a href="/login/" (click)="auth.logout()">Logout</a>
                </span>
              </li>
            </ul>
          </nav>
        </div>
        <!--// REVEAL -->
      </div>
      <!--// NAVIGATION -->
    </div>
    <!--// ROW -->
  </div>
</div>
