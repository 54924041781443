<div>
  <mat-dialog-content>
    <nz-input-group
      style="width: 100%; max-width: 800px; padding: 0px"
      class="legacy-input-styling"
    >
      <label style="left: 10px">Select Job</label>
      <nz-select
        style="width: 100%"
        nzShowSearch
        nzPlaceHolder="Select Job"
        [(ngModel)]="this.selectedJob"
      >
        <nz-option
          *ngFor="let job of this.jobs"
          [nzLabel]="job.job_name"
          [nzValue]="job"
        ></nz-option>
      </nz-select>
    </nz-input-group>

    <nz-input-group
      style="width: 100%; max-width: 800px; padding: 0px"
      class="legacy-input-styling"
    >
      <label style="left: 10px">Maximum Number of Different Parameters</label>
      <input
        nz-input
        placeholder="Maximum Number of Different Parameters"
        type="number"
        [(ngModel)]="differenceParameter"
        [disabled]="
          !this.selectedJob || getObjectKeys(this.selectedJob).length == 0
        "
      />
    </nz-input-group>

    <nz-input-group
      style="width: 100%; max-width: 800px; padding: 0"
      class="legacy-input-styling"
    >
      <label style="left: 10px">Select Fields to Exclude from Comparison</label>
      <nz-select
        style="width: 100%"
        nzMode="multiple"
        nzShowSearch
        nzAllowClear
        [formControl]="this.exclusionListControl"
        [nzSize]="'large'"
        [disabled]="getObjectKeys(this.selectedJob).length == 0"
      >
        <nz-option
          *ngFor="let val of this.flattenedOptions"
          [nzValue]="val"
          [nzLabel]="val"
        >
        </nz-option>
      </nz-select>
    </nz-input-group>

    <div style="display: flex; justify-content: space-between; gap: 10px">
      <button
        (click)="showDiff()"
        nz-button
        [disabled]="
          !this.selectedJob || getObjectKeys(this.selectedJob).length == 0
        "
        nzType="primary"
      >
        Show Similar Jobs
      </button>

      <button
        (click)="rankByDifference()"
        nz-button
        [disabled]="
          !this.selectedJob || getObjectKeys(this.selectedJob).length == 0
        "
        nzType="primary"
      >
        Rank by difference
      </button>

      <button (click)="excludeAll()" nz-button>
        Select All (Exclude all from comparison)
      </button>
    </div>
  </mat-dialog-content>
</div>
