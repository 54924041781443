<div class="container-fluid">
  <div
    style="
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
    "
  >
    <nz-spin [nzSpinning]="this.loadingChart" style="flex: 1 1 75%">
      <div
        #graph
        echarts
        id="graph-div"
        [options]="options"
        [loading]="this.loadingChart"
        *ngIf="this.options"
        style="
          background-color: white;
          border-radius: 5px;
          padding: 10px;
          height: 70vh;
        "
        class="demo-chart"
        (chartInit)="chartInit($event)"
      ></div>

      <ng-container *ngFor="let opt of this.heatmapOptions">
        <div
          echarts
          [options]="opt"
          [loading]="this.loadingChart"
          style="
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            height: 70vh;
          "
        ></div>
      </ng-container>
    </nz-spin>

    <div style="width: 300px">
      <div
        style="
          background-color: white;
          border-radius: 5px;
          margin: 0px 0px 10px;
          padding: 10px;
        "
      >
        <ng-container *ngTemplateOutlet="selectJobsTemplate"></ng-container>
      </div>
      <div style="background-color: white; border-radius: 5px; padding: 10px">
        <nz-spin [nzSpinning]="this.loadingChart">
          <nz-tabset [nzSelectedIndex]="0">
            <nz-tab nzTitle="Line Chart">
              <div>
                Y-Axis Limit
                <div class="_slider-container">
                  <span>{{ yMin }}</span>
                  <nz-slider
                    nzRange
                    [nzMin]="yMin"
                    [nzMax]="yMax"
                    [(ngModel)]="ylim"
                    (nzOnAfterChange)="onYlimChanged($event)"
                  ></nz-slider>
                  <span>{{ yMax }}</span>
                </div>
              </div>
              <div>
                X-Axis Limit
                <div class="_slider-container">
                  <span>{{ xMin }}</span>
                  <nz-slider
                    nzRange
                    [nzMin]="xMin"
                    [nzMax]="xMax"
                    [(ngModel)]="xlim"
                    (nzOnAfterChange)="onXlimChanged($event)"
                  ></nz-slider>
                  <span>{{ xMax }}</span>
                </div>
              </div>
              <div *ngIf="linePlotXConfig == 'spatial'">
                <nz-divider
                  style="margin: 10px 0px; border-color: #ddd"
                ></nz-divider>
                <div style="display: flex; justify-content: space-between">
                  Iteration
                  <nz-radio-group
                    [(ngModel)]="iteration_type"
                    (ngModelChange)="this.plotLineChart()"
                  >
                    <label nz-radio nzValue="global">Global</label>
                    <label nz-radio nzValue="per-job">Per Job</label>
                  </nz-radio-group>
                </div>

                <div
                  class="_slider-container"
                  *ngIf="iteration_type == 'global'; else perJobSliders"
                >
                  <span>{{ 0 }}</span>
                  <nz-slider
                    [nzMin]="0"
                    [nzMax]="this.spatial_totalIterations"
                    [(ngModel)]="spatial_iteration"
                    (nzOnAfterChange)="this.plotLineChart()"
                  ></nz-slider>
                  <span>{{ this.spatial_totalIterations }}</span>
                </div>

                <ng-template #perJobSliders>
                  <div
                    class="_slider-container"
                    *ngFor="let job of this.jobsSelected"
                  >
                    <div
                      nz-tooltip
                      [nzTooltipTitle]="
                        job.job_name.length > 50
                          ? job.job_name.substring(0, 50) + '...'
                          : job.job_name
                      "
                      style="display: inline"
                    >
                      {{ job.job_name.split("-")[0] }}
                    </div>
                    <span>{{ 0 }}</span>
                    <nz-slider
                      [nzMin]="0"
                      [nzMax]="job.iterations"
                      [(ngModel)]="job.currentIteration"
                      (nzOnAfterChange)="this.plotLineChart()"
                    ></nz-slider>
                    <span>{{ job.iterations }}</span>
                  </div>
                </ng-template>

                <nz-divider
                  style="margin: 10px 0px; border-color: #ddd"
                ></nz-divider>
              </div>

              <ng-container *ngIf="!is2d && linePlotXConfig == 'spatial'">
                <div>
                  <nz-input-group
                    class="legacy-input-styling"
                    style="padding: 0; display: flex"
                  >
                    <label style="left: 10px">Tolerance</label>
                    <input
                      nz-input
                      type="number"
                      min="1"
                      placeholder="Tolerance"
                      [(ngModel)]="tolerance"
                    />
                    <button
                      nz-button
                      nzType="primary"
                      (click)="this.plotLineChart()"
                    >
                      Update
                    </button>
                  </nz-input-group>
                </div>

                <div>
                  <button (click)="swapSpatialAxis()" nz-button nzBlock>
                    <span nz-icon nzType="swap" nzTheme="outline"></span>Swap
                    Axis
                  </button>
                </div>
              </ng-container>

              <div>
                Line Plot X Axis
                <div>
                  <nz-radio-group
                    [(ngModel)]="linePlotXConfig"
                    (ngModelChange)="this.plotLineChart()"
                  >
                    <label nz-radio nzValue="spatial">Spatial</label>
                    <label nz-radio nzValue="iteration">Iteration</label>
                  </nz-radio-group>
                </div>
              </div>
              <div
                *ngIf="linePlotXConfig == 'spatial'"
                style="margin-top: 10px"
              >
                Plot Type
                <div>
                  <nz-radio-group
                    [(ngModel)]="spatial_plotType"
                    (ngModelChange)="this.plotLineChart()"
                  >
                    <label nz-radio nzValue="line">Line</label>
                    <label nz-radio nzValue="scatter">Scatter</label>
                  </nz-radio-group>
                </div>
              </div>
              <div style="margin-top: 10px">
                Fit Type
                <div>
                  <nz-radio-group
                    [(ngModel)]="tracetype"
                    (ngModelChange)="plotLineChart()"
                  >
                    <label nz-radio nzValue="trace_fit">Trace fit</label>
                    <label nz-radio nzValue="fnlc">Functional</label>
                    <label nz-radio nzValue="dc_raw">Trace fit RAW</label>
                  </nz-radio-group>
                </div>
              </div>
            </nz-tab>
            <nz-tab nzTitle="Heat Map">
              <button nz-button (click)="plotHeatmaps()">Plot</button>
              <nz-collapse nzAccordion style="margin: 10px 0">
                <nz-collapse-panel
                  *ngFor="let config of heatmapConfigs; index as i"
                  [nzHeader]="
                    config.x &&
                    config.y &&
                    config.x.value + ' / ' + config.y.value
                  "
                >
                  <div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin: 0 0 10px;
                      "
                    >
                      <button nz-button nzType="primary">Update</button>
                      <button
                        nz-button
                        nzType="dashed"
                        (click)="
                          heatmapAxisSelectionModal = true;
                          activeConfig = i;
                          treeviewInitValue = [
                            heatmapConfigs[activeConfig].x.value,
                            heatmapConfigs[activeConfig].y.value
                          ]
                        "
                      >
                        <span nz-icon nzType="setting" nzTheme="outline"></span>
                      </button>
                    </div>
                    <nz-input-group style="display: flex">
                      <input
                        readonly
                        nz-input
                        style="flex: 1 0 50px"
                        [formControl]="config.x"
                      />
                      <button
                        nz-button
                        nzType="primary"
                        (click)="swapHeatmapAxis(config)"
                      >
                        <span nz-icon nzType="swap" nzTheme="outline"></span>
                      </button>
                      <input
                        readonly
                        nz-input
                        style="flex: 1 0 50px"
                        [formControl]="config.y"
                      />
                    </nz-input-group>
                    <nz-slider
                      nzRange
                      [(ngModel)]="config.colorRange"
                    ></nz-slider>
                  </div>
                </nz-collapse-panel>
              </nz-collapse>
              <div>
                <button
                  nz-button
                  nzType="dashed"
                  nzBlock
                  (click)="appendHeatmapConfig()"
                >
                  Add
                </button>
              </div>
            </nz-tab>
          </nz-tabset>
        </nz-spin>
      </div>
    </div>
  </div>
</div>

<nz-modal
  nzTitle="Heatmap Configuration"
  [(nzVisible)]="heatmapAxisSelectionModal"
  (nzOnCancel)="heatmapAxisSelectionModal = false"
  (nzOnOk)="heatmapAxisSelectionModal = false"
  [nzMask]="false"
  [nzMaskClosable]="true"
>
  <ng-container *nzModalContent>
    <div style="display: flex; gap: 10px">
      <div style="width: 300px">
        {{ this.activeConfig }}
        <nz-input-group>
          <input
            readonly
            nz-input
            style="flex: 1 0 50px"
            [formControl]="heatmapConfigs[activeConfig].x"
          />
          <button
            nz-button
            nzType="primary"
            (click)="swapHeatmapAxis(heatmapConfigs[activeConfig])"
          >
            <span nz-icon nzType="swap" nzTheme="outline"></span>
          </button>
          <input
            readonly
            nz-input
            style="flex: 1 0 50px"
            [formControl]="heatmapConfigs[activeConfig].y"
          />
        </nz-input-group>

        <nz-slider
          nzRange
          [(ngModel)]="heatmapConfigs[activeConfig].colorRange"
        ></nz-slider>
        <!-- [formControl]="heatmapConfigs[activeConfig].colorRange" -->
      </div>
      <nz-divider nzType="vertical" style="height: 300px"></nz-divider>
      <div style="height: 300px; width: 300px">
        <app-zorro-treeview
          [maxSelected]="2"
          [treeData]="this.paramViewFields"
          [initValue]="treeviewInitValue"
          (onSelectedChanged)="onParametersChangedForConfig($event)"
        ></app-zorro-treeview>
      </div>
    </div>
  </ng-container>
</nz-modal>

<ng-template #selectJobsTemplate>
  <nz-alert
    *ngIf="!areFrequenciesComparable"
    nzType="warning"
    nzMessage="Selected jobs use different frequencies."
  ></nz-alert>

  <div
    style="
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    "
  >
    Select Jobs to plot
    <button nz-button (click)="plotSelectedJobs()">Plot</button>
  </div>
  <nz-select
    nzShowSearch
    nzMode="multiple"
    style="width: 100%; max-height: 250px; overflow-y: scroll"
    nzPlaceHolder="Select Jobs"
    [(ngModel)]="this.jobsSelected"
  >
    <nz-option
      *ngFor="let job of this.allJobs"
      [nzLabel]="job.job_name"
      [nzValue]="job"
    ></nz-option>
  </nz-select>
</ng-template>
