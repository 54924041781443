import { Component } from '@angular/core';

@Component({
  selector: 'app-copilot-bot',
  templateUrl: './copilot-bot.component.html',
  styleUrls: ['./copilot-bot.component.css']
})
export class CopilotBotComponent {

}
