import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CopilotComponent } from "./copilot.component";
import { SharedModule } from "../shared/shared.module";
import { CopilotSidebarComponent } from "./copilot-sidebar/copilot-sidebar.component";
import { RouterModule } from "@angular/router";
import { CopilotSettingsComponent } from './copilot-settings/copilot-settings/copilot-settings.component';
import { CopilotBotComponent } from './copilot-bot/copilot-bot.component';

@NgModule({
  declarations: [CopilotComponent, CopilotSidebarComponent, CopilotSettingsComponent, CopilotBotComponent],
  imports: [RouterModule, CommonModule, SharedModule],
  exports: [CopilotComponent],
})
export class CopilotModule {}
