import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import type { ECharts, EChartsOption } from "echarts";
import { NzFormatEmitEvent, NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { parameters } from "./data";
import { cloneDeep, isArray, max } from "lodash";
import { forkJoin } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { JobDetailComponent } from "../../project/dialogs/job-detail/job-detail.component";
import { JobDetailDialog } from "../../../models/jobDetailDialog";
import { NzSelectItemInterface } from "ng-zorro-antd/select";
import { Job } from "../../../models/job";

interface CpFilterInterface {
  job: { id: string; name: string };
  cp: number[];
  cpOptions: any[];
}

@Component({
  selector: "app-flowchart2",
  templateUrl: "./flowchart2.component.html",
  styleUrls: ["./flowchart2.component.less"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class Flowchart2Component {
  @ViewChild("graph", { static: true }) graph: ElementRef;
  chartInstance: ECharts;

  project_id: string = "";
  project_name: string = "";
  loading: boolean = false;
  searchValue: string = "";
  options: EChartsOption;

  data: any = [];

  jobs: NzTreeNodeOptions[] = [];
  jobsSelectAll: boolean = true;
  jobsSelectIndeterminate: boolean = false;
  parameterOptions: NzTreeNodeOptions[] = null;
  filteredParams: NzTreeNodeOptions[] = [];
  searchValueParam: string = "";
  series: Array<any> = [];
  legendData: Array<any> = [];
  fm: boolean = false;
  activateGrouping: boolean = false;
  edittableJob: any = null;
  edittableJobInfo: { jobInfo: object; paramsInfo: object } = null;
  isJobInfoLoading: boolean = false;
  searchStr: string = "";
  filteredSeries: any[] = [];
  configHidden: boolean = false;
  cpFilters: CpFilterInterface[] = [];
  jobSelectOptionsArray: NzSelectItemInterface[] = [];
  rawJobsData: {
    id: string;
    job_name: string;
    project__name: string;
    parent_job_id__job_name: string;
    parent_job_id: string;
  }[] = [];

  constructor(
    @Inject(CachedProjectService) private projectService: CachedProjectService,
    private _snackbar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.loading = true;
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.get_jobs();
        this.get_project_flowchart();
      }
    });

    const temp = [];
    Object.keys(parameters).forEach((key) => {
      const children = parameters[key].map((param) => ({
        title: param,
        key: param,
        isLeaf: true,
      }));
      temp.push({
        title: key,
        key: key,
        children: children,
      });
    });
    this.parameterOptions = temp;
    this.filteredParams = [...temp];
  }

  getDepth(struct: any) {
    if (isArray(struct)) {
      let depths = [];
      for (let child of struct) {
        depths.push(this.getDepth(child));
      }
      return max(depths);
    } else if (struct.children?.length > 0) {
      return 1 + this.getDepth(struct.children);
    }
    return 0;
  }

  get_project_flowchart(forceRefresh = false) {
    this.loading = true;
    this.jobsSelectAll = true;
    this.updateAllChecked();
    this.chartInstance?.clear();
    this.chartInstance?.showLoading();

    // its dependent on the FM changing, so calling here. if FM changes it'll be fired
    this.jobSelectOptionsArray = this.getJobsWithChildren();

    this.projectService
      .getProjectFlowchart(this.project_id, this.fm, forceRefresh)
      .toPromise()
      .then((data) => {
        // init/reinit the cpFilters.
        this.cpFilters = [];

        this.data = { name: "project", children: data["tree"] };

        // Generate legend data
        this.legendData = this.makeLegendData(data["tree"]);

        // singleton
        const singletons = data["tree"].filter(
          (datum: any) => this.getDepth(datum) == 0
        );
        // depth = 1
        const medTrees = data["tree"].filter(
          (datum: any) => this.getDepth(datum) == 1
        );
        // deepth > 1
        const largeTrees = data["tree"].filter(
          (datum: any) => this.getDepth(datum) > 1
        );
        // Map tree data to series with dynamic spacing
        this.series = data["tree"].map((datum) => {
          const depth = this.getDepth(datum);
          let left: any;
          if (singletons.includes(datum)) {
            let separation = 10;
            let i = singletons.indexOf(datum);
            let placement = Math.floor((i + 1) / 2) * separation;
            let sign = (i + 1) % 2 == 0 ? "" : "-";
            let center = 50;
            if (sign == "-") {
              left = center - placement;
            } else {
              left = center + placement;
            }
            // left = `${sign}${placement}%`;
          } else if (medTrees.includes(datum)) {
            let available = 80;
            let separation = available / medTrees.length;
            let i = medTrees.indexOf(datum);
            let placement = Math.floor((i + 1) / 2) * separation;
            let sign = (i + 1) % 2 == 0 ? "" : "-";
            let center = 30;
            if (sign == "-") {
              left = center - placement;
            } else {
              left = center + placement;
            }
            // console.log(left, datum.name);
          } else {
            let available = 80; // -60% to 60%
            let separation = available / largeTrees.length;
            let i = largeTrees.indexOf(datum);
            let placement = Math.floor((i + 1) / 2) * separation;
            let sign = (i + 1) % 2 == 0 ? "" : "-";
            let center = 30;
            if (sign == "-") {
              left = center - placement;
            } else {
              left = center + placement;
            }
            // console.log(left, datum.name);
          }

          // ------------
          // If grouping is activated, set the item style of the jobs in group as opacity 1 and color as the one from the group name hash
          apply_group_coloring(datum, this.activateGrouping);
          // ------------

          return {
            type: "tree",
            name: datum.name,
            data: [datum],
            roam: true,
            left: `${left}%`,
            id: datum.id,
            // right: right,
            // zoom: 0.5,
            width: depth > 0 ? 250 : 10,
            top: datum.children?.length == 0 ? 40 : depth == 1 ? 100 : 300,
            height: depth <= 1 ? "100px" : "300px",
            symbolSize: 40,
            orient: "vertical",
            edgeShape: "polyline",
            itemStyle: {
              color: "orange",
              opacity: this.activateGrouping ? 0.15 : 1,
            },
            label: {
              position: "inside",
              verticalAlign: "middle",
              align: "center",
              offset: [0, -5],
              formatter: (d) => {
                return [
                  `{a|${
                    d.data.valueOf()["iteration"] == 0
                      ? "0"
                      : d.data.valueOf()["iteration"] !== null
                      ? d.data.valueOf()["iteration"]
                      : ""
                  }}`,
                  `{b|${d.data.valueOf()["name"]}}`,
                ].join("\n");
              },
              rich: {
                a: {
                  color: "red",
                  lineHeight: 10,
                },
                b: {
                  height: 30,
                  lineHeight: 45,
                },
              },
            },
            leaves: {
              label: {
                position: "inside",
                verticalAlign: "middle",
                align: "center",
              },
            },
            emphasis: {
              focus: "relative",
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          };
        });
        this.filteredSeries = cloneDeep(this.series);
        this.makeTreeChart();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.loading = false;
        this.chartInstance?.hideLoading();
      });
  }

  get_jobs() {
    this.projectService
      ._getProjectJobs(this.project_id, {
        fields: [
          "id",
          "job_name",
          "project__name",
          "parent_job_id__job_name",
          "parent_job_id",
        ],
      })
      .toPromise()
      .then((data) => {
        // the jobSelectOptionsArray is used by the By Job CP filtering job select.
        // there, we only want jobs which have children and not ALL the jobs. Simple solution? we'll go over the whole data and make a set of just the parent jobs
        let parent_ids = new Set<string>();
        let tempArray: any[] = [];

        let jobs = [];
        this.rawJobsData = data["data"];
        jobs = this.rawJobsData.map((d) => ({
          title: d.job_name,
          key: d.id,
          isLeaf: true,
          checked: true,
        }));
        this.jobs = jobs.sort((a, b) => String(a.title).localeCompare(b.title));
        this.jobSelectOptionsArray = this.getJobsWithChildren();
      })
      .catch()
      .finally();
  }

  /**
   * return an array of jobs which have children. It also takes into accound if the children are FM not in the case that FM is switched on
   */
  getJobsWithChildren(): NzSelectItemInterface[] {
    let parent_ids = new Set<string>();
    let tempArray: any[] = [];
    this.rawJobsData.forEach((d) => {
      if (!this.fm) {
        if (d.job_name.includes("FM")) {
          return;
        }
      }

      if (!parent_ids.has(d.parent_job_id) && d.parent_job_id) {
        parent_ids.add(d.parent_job_id);
        tempArray.push({
          nzLabel: d.parent_job_id__job_name,
          nzValue: {
            id: d.parent_job_id,
            name: d.parent_job_id__job_name,
          },
          label: d.parent_job_id__job_name,
          value: {
            id: d.parent_job_id,
            name: d.parent_job_id__job_name,
          },
        });
      }
    });
    return tempArray.sort((a, b) => String(a.label).localeCompare(b.label));
  }

  updateCpFilter(job: { id: string; name: string }, index: number) {
    this.cpFilters[index].job = job;
    var node: any;
    // get the CP options for the selected job. get this dynaimcally. i.e. find the job in the trees and find what CPs are available
    for (let s of this.series) {
      node = findNode(job, s.data[0]);
      if (node) {
        break;
      }
    }

    if (!node) return; // node was not found in the trees

    // node found in the trees? get its CP options from its children.
    let cpOptions = new Set<string>(
      node.children
        .map((child) => child?.iteration)
        ?.filter((i) => i !== null && i !== undefined)
    );
    // comvert the cpOtions set to array, and save them in the format that can be used in the nzSelect
    this.cpFilters[index].cpOptions = Array.from(cpOptions).map((c) => ({
      label: `CP ${c}`,
      value: c,
    }));
    this.cpFilters[index].cp = [];
  }

  applyCpFiltering2() {
    this.cleanCpFilteringObject();
    // 1. first we want to get all the children of the jobs that have been selected.
    var node: any = null;
    for (let cpFilter of this.cpFilters) {
      for (let s of this.series) {
        node = findNode(cpFilter.job, s.data[0]);
        // early stop and exit when node found.
        if (node) {
          break;
        }
      }
      if (!node) {
        // the case where the node was not found at all.
        // could happen if a wrong option in the selectedJob options, or if an FM is selected which is currently not in the series (if the FM switch is off)
        continue;
      }
      // 2. children are the jobs. Now we basically just need to select the nodes for the children.
      node.children.forEach((child) => {
        // this.jobs is NzTreeNodeOptions[]. in those the key is the job ID
        let job = this.jobs.find((job) => job.key === child.id);
        if (job) {
          // should get checked only if the CP is matching
          let checked = cpFilter.cp.includes(child.iteration);
          job.checked = checked;
          // now there is a unique case. If a child has been unselected, but it has further children, it will keep showing regardless of being unselected.
          // this is because the children of that child are still selected, and so the whole tree will still be visible. To solve this, when unselecting
          // a node, we have to unselect all its descendants also
          if (!checked) {
            this.uncheckAllDescendants(child);
          }
        }
      });
      // 3. want to uncheck the node itself also.
      this.jobs.find((job) => job.key === node.id).checked = false;
      // this.uncheckAllDescendants(node)
    }
    // 4. making clone cause that helps rerender the tree properly. And then just updateSingleChecked()
    this.jobs = cloneDeep(this.jobs);
    this.updateSingleChecked();
    this.updateNodes();
  }

  uncheckAllDescendants(node: any) {
    if (node.children?.length > 0) {
      for (let child of node.children) {
        this.uncheckAllDescendants(child);
        this.jobs.find((j) => j.key === child.id).checked = false;
      }
    }
  }

  /**
   * Mutates the this.cpFilters to be clean basically.
   */
  cleanCpFilteringObject() {
    // if no filters are added but still filtered
    if (this.cpFilters.length == 0) return;

    // first sanitize the this.cpFilter for empty DTOs
    this.cpFilters = this.cpFilters.filter((f) => f.job && f.cp);
    // now sanitize for duplicate job entires, we will append their CPs
    const sanitizedFilters: CpFilterInterface[] = [];
    this.cpFilters.forEach((f) => {
      let duplicate = sanitizedFilters.find((sf) => sf.job.id == f.job.id);
      if (duplicate) {
        let cps = new Set([...duplicate.cp, ...f.cp]); // makes a list of CPs not repeated.
        duplicate.cp = Array.from(cps);
      } else {
        // check if it actually has both the cp and job selected
        if (
          (f.cp !== null || f.cp !== undefined || f.cp.length !== 0) &&
          (f.job !== null || f.job !== undefined)
        )
          sanitizedFilters.push(f);
      }
    });
    this.cpFilters = sanitizedFilters;
  }

  applyCpFiltering() {
    const newSeriesArray = []; // these will be the ones added to the tree

    this.cleanCpFilteringObject();

    for (let cpFilter of this.cpFilters) {
      const job = cpFilter.job;

      var relTree: any = null;
      var targetSeries: any = null;

      for (let s of this.series) {
        // The reason for making a deepClone of the series is so the original one is not changed
        // Additionally, in the code block after this where we call the limitCps(), i simply pass the reference of the node
        // This makes it easier to actually change everything in the series.data while not doing anything to the OG
        targetSeries = cloneDeep(s);
        relTree = getRelativeTree(targetSeries.data[0], {
          title: job.name,
          key: job.id,
        });
        if (relTree) {
          break;
        }
      }

      // get a newNode, which has the CPs limited to the chosen one
      if (relTree) {
        let duplicateSeries = newSeriesArray.find((s) => s.id == relTree.id);
        if (duplicateSeries) {
          // the case where the tree needs expansion. i.e. tree already there, you need to append a sibling
          // 1. find the node that has to be changed from the relativeTree. How? just a findNode(job, relTree)
          let node = findNode(job, relTree);
          // 2. Now I have the node that has to be changed, so limitCps()
          limitCps(node, cpFilter);
          // 3. The node has been modified. Now, have to actually expand the tree. How? get the parent of the node that has been modified
          let parent = findParentOfSubTree(relTree, node);
          // 4. have the parent? and we have the reference to that series also in duplicateSeries. Now look for the parent in the duplicateSeries.data[0]
          let nodeToBeExpanded = findNode(parent, duplicateSeries.data[0]);
          // 5. Now there is a possibility that we didn't find the parent. perhaps have to go a level up further? so run a while loop till you find it
          if (!nodeToBeExpanded) {
            parent = findParentOfSubTree(relTree, nodeToBeExpanded);
            nodeToBeExpanded = findNode(parent, duplicateSeries.data[0]);
            // the reason I'm not worried about exiting? Cause we checked for the existence of this node in this series. and the rootNode is DEFINITELY the same atleast,
            // so even if we end up at the top root node, we will still in all cases find the common parent.
          }
          // 6. Now that we have the common parent, add to its children array the new subtree. Good thing is, evrything is a reference at this point
          // 1 more thing, at this stage, the parent and nodeToBeExpanded will be showing to the same data. But the references are different,
          // parent is just working on the relTree we made, while the nodeToBeExpanded is working on the duplicateSeries, which we needed to actually change.
          nodeToBeExpanded.children.push(...parent.children);
          // 7. At this point, we're done. Cause the nodeToBeExpaned was a reference to the series data, so we already added everything we needed to add to it
        } else {
          // new series being added, straight forward and easy.
          let node = findNode(job, relTree);
          limitCps(node, cpFilter);
          newSeriesArray.push(targetSeries);
        }
      }
    }

    // legend data is created again since there is a different set of trees now for the chart
    const legendData = this.makeLegendData(
      newSeriesArray.map((f) => f.data).flat()
    );
    this.chartInstance.setOption(
      {
        series: newSeriesArray,
        legend: {
          show: true,
          top: "0%",
          left: "0%",
          orient: "vertical",
          borderColor: "#c23531",
          data: legendData,
        },
      },
      {
        replaceMerge: ["series", "legend"],
      }
    );
  }

  makeLegendData(data: any): any[] {
    return data.map((datum: any) => {
      return {
        name: datum.name,
        icon: "circle",
        itemStyle: datum.itemStyle || {},
      };
    });
  }

  makeTreeChart() {
    this.options = {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        formatter: (d: any) => {
          if (d.data) {
            return (
              "<b>" +
              (d.data.fullname.length > 50
                ? d.data.fullname.substring(0, 50) + "..."
                : d.data.fullname) +
              "</b>" +
              "<br/>" +
              (d.data?.comments || "")
            );
          }
        },
      },
      legend: {
        show: true,
        top: "0%",
        left: "0%",
        orient: "vertical",
        data: this.legendData,
        borderColor: "#c23531",
      },
      toolbox: {
        show: true,
        feature: {
          restore: {
            show: true,
          },
        },
      },
      series: this.series,
    };
    this.changeDetectorRef.detectChanges();
  }

  chartInit(event: any) {
    this.chartInstance = event;
    this.chartInstance.on("click", "series.tree", (e) => {
      this.edittableJob = e.data;
      this.getJobData();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {}

  clickHandler(event) {
    // console.log(event);
  }

  paramTreeActionEvent(event: NzFormatEmitEvent): void {
    // console.log(event);
  }

  filterParams() {
    // this.filteredParams = this.parameterOptions.filter(
    //   (param: NzTreeNodeOptions) => param.children.filter.includes(this.searchValueParam)
    // );

    // const temp = [...this.parameterOptions];
    const temp = cloneDeep(this.parameterOptions);
    temp?.forEach((category, index) => {
      category.children = category.children.filter((param) =>
        param.title.includes(this.searchValueParam)
      );
    });
    this.filteredParams = temp.filter(
      (category) => category.children.length > 0
    );
    // console.log(this.filteredParams, this.parameterOptions);
  }

  // --------------------------------
  // jobs tree funcions

  /**
   * Fired when you make a change to NzTree checkbox options. This one is for the jobs NzTree
   * @param event - Event from the NzTree
   */
  jobTreeActionEvent(event: NzFormatEmitEvent): void {
    if (event.eventName == "check") {
      this.updateSingleChecked();
    }
  }

  updateAllChecked(): void {
    this.jobsSelectIndeterminate = false;
    if (this.jobsSelectAll) {
      this.jobs = this.jobs.map((item) => ({
        ...item,
        checked: true,
      }));
    } else {
      this.jobs = this.jobs.map((item) => ({
        ...item,
        checked: false,
      }));
    }
  }

  /**
   * This doesn't exactly change the value of the true or false,m that is done directly by the input
   * this is responsible for the state of the 'updateAll' checkbox
   */
  updateSingleChecked(): void {
    if (this.jobs.every((item) => !item.checked)) {
      this.jobsSelectAll = false;
      this.jobsSelectIndeterminate = false;
    } else if (this.jobs.every((item) => item.checked)) {
      this.jobsSelectAll = true;
      this.jobsSelectIndeterminate = false;
    } else {
      this.jobsSelectIndeterminate = true;
    }
  }

  updateNodes() {
    this.filterSeries();
  }

  /**
   * flters the serieses with respect to the selected jobs
   * Results in showing only the hierarchy of the selected job and its childrens. hides its siblings
   */
  filterSeries() {
    const selectedJobs = this.jobs.filter((job) => job.checked);
    const filteredSeries = [];
    for (let selectedJob of selectedJobs) {
      // first check if the node is already there in the filteredOptions as maybe a child of one of the other nodes? Avoid duolication
      var alreadyExists = false;
      for (let s of filteredSeries) {
        alreadyExists = checkIfNodeExistsInTree(s.data[0], selectedJob);
        if (alreadyExists) break;
      }
      if (alreadyExists) continue; // if it already exists, go to the next job. This one is already there

      for (let datum of cloneDeep(this.series)) {
        // check if the node exists in any of the trees
        // the datum is the whole series object for the ECHARTS options. We just wanna search in the data
        let found = checkIfNodeExistsInTree(datum.data[0], selectedJob);
        if (found) {
          // sending the whole series, not just data
          let targetSeries = datum;
          // prune the tree, i.e remove all but the children and ancestors of the selected node and then break loop for early exit
          this.pruneTree(targetSeries, selectedJob, filteredSeries);
          break;
        }
      }
    }

    // legend data is created again since there is a different set of trees now for the chart
    const legendData = this.makeLegendData(
      filteredSeries.map((f) => f.data).flat()
    );
    this.chartInstance.setOption(
      {
        series: filteredSeries,
        legend: {
          show: true,
          top: "0%",
          left: "0%",
          orient: "vertical",
          borderColor: "#c23531",
          data: legendData,
        },
      },
      {
        replaceMerge: ["series", "legend"],
      }
    );
  }

  /** 
    * Function to actually handle the pruning of the tree depending on the jobs Selected
    @param {object | any} targetSeries - The series containing the selected job currently being iterated upon
    @param {NzTreeNodeOptions} selectedJob - The selected job DTO from the NZTree. Contains the necessary information for pruning and filtering
    @param {any[]} filteredSeries - reference to the filtered series array which will then be used to make the graph. Contains the trees containing the PRUNED trees containing the jobs
  */
  pruneTree(
    targetSeries: any,
    selectedJob: NzTreeNodeOptions,
    filteredSeries: any[]
  ) {
    let findSeries = filteredSeries.find((d) => d.id == targetSeries.id); // the series that contains the node that we want to add
    if (findSeries) {
      // the case where we wanna 'expand' the tree. So probably a node in this tree has also been selected
      const newTree = expandRelativeTree(
        cloneDeep(targetSeries.data[0]),
        selectedJob,
        cloneDeep(findSeries.data[0])
      );
      findSeries.data = [newTree];
    } else {
      // the case where we wanna add the tree. Probably first node in the tree selected to be shown
      const newTree = getRelativeTree(
        cloneDeep(targetSeries.data[0]),
        selectedJob
      );
      targetSeries.data = [newTree];
      filteredSeries.push(targetSeries);
    }
  }

  /**
   * Gets the Job data from the Fw3dJob table, and the parameters of the job from the parameters table
   */
  getJobData() {
    this.isJobInfoLoading = true;
    forkJoin({
      jobInfo: this.projectService._getJobDetail(this.edittableJob.id),
      paramsInfo: this.projectService.getParameterTable(
        this.project_id,
        false,
        [this.edittableJob.id]
      ),
    })
      .toPromise()
      .then((res) => {
        this.edittableJobInfo = {
          jobInfo: res.jobInfo,
          paramsInfo: res.paramsInfo?.results[0] || {},
        };
      })
      .catch((err) => console.log("error", err))
      .finally(() => {
        this.isJobInfoLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Responsible for opening the JobDialog, and handling the return from that modal.
   * The modal is reused from the one that is opened from the model page to edit a model
   */
  openJobDialog() {
    var job: any = this.edittableJobInfo.jobInfo;
    // refresh job details before open job dialog
    this.projectService.refreshJobDetails(job.id).subscribe(
      (refresh) => {
        this.projectService.clearJobDetailCache([job.id]);
        job.name = refresh.job_name;
        job.status = refresh.status;

        // open job dialog
        let dialogRef = this.dialog.open(JobDetailComponent, {
          width: "400px",
          data: {
            id: job.id,
            name: job.name,
            projectId: this.project_id,
            basePath: job.basePath,
            iterations: job.iterations,
            iterationsComplete: job.iterationsComplete,
            status: job.status,
            comments: job.comments,
            tag: job.tag,
            parent_job_id: job.parent_job_id,
            parent_cp_num: job.parent_cp_num,
            offshoot: job.offshoot,
          },
        });

        dialogRef.afterClosed().subscribe((result: JobDetailDialog) => {
          if (!result) return;
          this.projectService.updateJobDetails(result).subscribe(
            (data) => {
              job.name = result.name;
              job.basePath = result.basePath;
              job.iterations = result.iterations;
              job.iterationsComplete = result.iterationsComplete;
              job.status = result.status;
              job.comments = result.comments;
              job.parent_job_id = result.parent_job_id;
              job.parent_cp_num = result.parent_cp_num;
              job.offshoot = result.offshoot;
              job.name = result.name;
              job.status = result.status;

              this.projectService.clearProjectsCache();
              this.projectService.clearJobDetailCache(
                this.jobs.map((j) => j.key)
              );
              this._snackbar.open("Job Details updated", null, {
                duration: 2000,
              });
              this.get_project_flowchart(true);
              this.get_jobs();
            },
            (error) => {
              this._snackbar.open(
                "Sorry, the job details could not be updated",
                null,
                { duration: 2000 }
              );
            }
          );
        });
      },
      (error) => {
        this._snackbar.open(
          "Sorry, the job details could not be updated",
          null,
          { duration: 2000 }
        );
      }
    );
  }

  getObjectKeys(obj: object) {
    return Object.keys(obj);
  }

  /**
   * Caches the state of the graph and the Jobs NZTree. Pretty simple and straightforward really.
   */
  cacheState() {
    localStorage.setItem(
      "flowchart_state",
      JSON.stringify({
        chartOptions: this.chartInstance.getOption(),
        jobsSelectionState: this.jobs,
      })
    );
  }

  /**
   * Loads the state of the flowchart and the Jobs NZTree which has been previously saved in the state. Again, fairly straight forward.
   */
  loadState() {
    const loadedOptions = JSON.parse(localStorage.getItem("flowchart_state"));

    // some options were not properly copying, so added them statically like the tooltip and label
    // But they aren't changing in anycase so its fine.
    if (loadedOptions) {
      this.chartInstance.setOption(
        {
          ...loadedOptions.chartOptions,
          label: {
            position: "inside",
            verticalAlign: "middle",
            align: "center",
            offset: [0, -5],
            formatter: (d) => {
              return [
                `{a|${
                  d.data.valueOf()["iteration"] == 0
                    ? "0"
                    : d.data.valueOf()["iteration"] !== null
                    ? d.data.valueOf()["iteration"]
                    : ""
                }}`,
                `{b|${d.data.valueOf()["name"]}}`,
              ].join("\n");
            },
            rich: {
              a: {
                color: "red",
                lineHeight: 10,
              },
              b: {
                height: 30,
                lineHeight: 45,
              },
            },
          },
          tooltip: {
            trigger: "item",
            triggerOn: "mousemove",
            formatter: (d: any) => {
              if (d.data) {
                return (
                  "<b>" +
                  (d.data.fullname.length > 50
                    ? d.data.fullname.substring(0, 50) + "..."
                    : d.data.fullname) +
                  "</b>" +
                  "<br/>" +
                  (d.data?.comments || "")
                );
              }
            },
          },
        },
        true
      );
      this.jobs = loadedOptions.jobsSelectionState;
      this.updateSingleChecked();
    }
  }
}

/**
 * Applies coloring based on group, and grays out the rest. OR undoes that and reverts to the normal view if you wanna see it without grouping
 * @param datum - the series data
 * @param grouping - wether to activate grouping or not
 */
function apply_group_coloring(datum: any, grouping: boolean = false) {
  if (datum.children.length > 0) {
    datum.children.forEach((child) => apply_group_coloring(child, grouping));
  }

  if (grouping) {
    if (datum?.group_color) {
      datum["itemStyle"] = { color: datum.group_color, opacity: 1 };
    }
  } else {
    if (datum?.default_color) {
      datum["itemStyle"] = { color: datum.default_color };
    }
  }
}

/**
 * Simply checks if the job being iterated over is contained in the data of this series.
 * @param seriesData - The actual series data that is being iterated upon
 * @param selectedJob - The DTO of the selected job from the NzTree to be searched in the table
 * @returns - returns true if found, otherwise false
 */
function checkIfNodeExistsInTree(
  seriesData: any,
  selectedJob: NzTreeNodeOptions
): boolean {
  // Name pretty self explanatory. Recursive
  if (seriesData.children) {
    for (let child of seriesData.children) {
      let found = checkIfNodeExistsInTree(child, selectedJob);
      if (found) return true;
    }
  }
  if (seriesData.id == selectedJob.key) {
    return true;
  }
  return false;
}

/**
 * this will return the 'relative' tree of the found node.
 * i.e. the children and parents of the node, but not its siblings
 * @param data - The complere series data (tree) that contains the job
 * @param selectedJob - The DTO of the selected job from the NzTree to be searched in the tree
 * @returns - The pruned subtree containing the the jobs hierarchy, children and not its siblings
 */
function getRelativeTree(data: any, selectedJob: NzTreeNodeOptions) {
  // console.log(data, selectedJob);
  if (data.id == selectedJob.key) {
    return data;
  }
  if (data.children) {
    for (let child of data.children) {
      let subTree = getRelativeTree(child, selectedJob);
      if (subTree) {
        data.children = [subTree];
        return data;
      }
    }
  }
}

/**
 * This is similar to the getRelativeTree. The difference is, the getRelativeTree will return the hierarchy and children minus the siblings
 * now assume you selected another node from that same tree to be shown. The getRelativeTree will result now in 2 tree, mostly same, but have only the difference of the selected nodes
 * so this basically just merges them.
 *
 *
 * In hindsight, I could also just have written a code to merge 2 trees instead of this long expand tree thingy.
 * In the future if someone wants to change it to that, please do.
 * @param trueTree - complete tree data (deep copy)
 * @param selectedJob - The DTO of the selected job from the NzTree to be searched in the tree
 * @param semifilteredTree - The previously pruned tree (deep copy)
 * @returns - the semiFilteredTree but with the additional node added. Could be a sibling of a previously added job
 */
function expandRelativeTree(
  trueTree: any,
  selectedJob: NzTreeNodeOptions,
  semifilteredTree: any
) {
  // console.log(trueTree, selectedJob, cloneDeep(semifilteredTree));
  // 1: find the subtree to be added. i.e. the subtree with the selectedJob as the root
  var selectedJobSubtree = findSubtreeToBeAdded(trueTree, selectedJob);
  var subtree = selectedJobSubtree;
  var relativeTree = getRelativeTree(trueTree, selectedJob); // getting this cause we again, don't want the siblings from the trueTree for the selected
  // 2: find parent tree of the subtree to be added. i.e. the parent will now become the root of the tree
  var parent = findParentOfSubTree(relativeTree, subtree);
  // 3: check if the parent exists in the semi-filtered tree
  var nodeToBeExpanded = findNode(parent, semifilteredTree);
  // 4: if this node is undefined, means this parent is not in the semifiltered tree. So now, go 1 level up and find that parent
  while (!nodeToBeExpanded) {
    subtree = parent;
    parent = findParentOfSubTree(relativeTree, subtree);
    var nodeToBeExpanded = findNode(parent, semifilteredTree);
  }
  // 5: Add the subtree to the valid parent node
  nodeToBeExpanded.children.push(subtree);
  // 6: return the new expanded series
  return semifilteredTree;
}

/**
 * will take a tree and a subtree to be searched for in the tree
 * if it finds the subtree in the tree, it will return the parent of the tree
 * else if will return null
 * @param tree - The complete tree, for the subtree to be searched in. The actual set per say
 * @param subTree - The subtree to be seaeched for in the tree
 * @return - return the parent of the subtree. Rreturn the parent with the children and subtree within it. Return null if not found
 */
function findParentOfSubTree(tree: any, subTree: any): any {
  // find in the children first
  for (let child of tree.children) {
    if (child.id == subTree.id) {
      // if found in the children, return the tree
      return tree;
    }
  }
  // go into the children from here
  for (let child of tree.children) {
    let found = findParentOfSubTree(child, subTree);
    if (found) return found;
  }
  return null;
}

/**
 * Return the subtree from the tree with the selectedJob as the root node of the subtree
 * @param tree - the complete tree data
 * @param selectedJob - The DTO of the selected job from the NzTree to be searched in the tree
 * @returns - a reference of the subtree of the tree, with the selected job as its node
 */
function findSubtreeToBeAdded(tree: any, selectedJob: NzTreeNodeOptions) {
  if (tree.id == selectedJob.key) {
    return tree;
  }
  if (tree.children) {
    for (let child of tree.children) {
      let subTree = findSubtreeToBeAdded(child, selectedJob);
      if (subTree) {
        return subTree;
      }
    }
  }
}

/**
 * Finds the node that is being searched for in the tree provided.
 *
 * TLDR: I do feel like this is a replication of one of the other functions and isn't really needed really. Could be the same as checkIfNodeExistsInTree and findSubtreeToBeAdded
 * @param nodeToFind - The node that is being searched for
 * @param tree - The tree that is to be searched in
 * @returns - Reference to the node in the tree (if found) else undefined
 */
function findNode(nodeToFind: { id: string }, tree: any) {
  if (nodeToFind.id == tree.id) {
    return tree;
  }
  for (let child of tree.children) {
    let found = findNode(nodeToFind, child);
    if (found) return found;
  }
}

/**
 * Filters the CP of the node according to the selected CPs
 * @param node the node containing the job to apply CP filtering on
 * @param cpFilter the DTO of the cpFiltering. Could replace with just the cpFilter.cp
 * @returns a copy of the node, with the children filtered
 */
function limitCps(
  node: any,
  cpFilter: {
    job: { id: string; name: string };
    cp: number[];
    cpOptions: any[];
  }
) {
  const nodeCopy = cloneDeep(node);
  node.children = node.children.filter((child: any) =>
    cpFilter.cp.includes(child.iteration)
  );
  return nodeCopy;
}
