import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Project } from "../../models/project";
import { JobDetail } from "../../models/jobDetail";
import { PlotData } from "../../models/plotData";
import { PlotDetail } from "../../models/plotDetail";
import { SliceType } from "../../models/sliceType";
import { CacheService } from "../../shared/services/cache.service";
import { ProjectService } from "./project.service";
import { Cost } from "../../models/cost";
import { Coordinate } from "../../models/coordinate";
import { ProjectDialog } from "../../models/projectDialog";
import { JobDialog } from "../../models/jobDialog";
import { JobDetailDialog } from "../../models/jobDetailDialog";
import { JobStatusDialog } from "../../models/jobStatusDialog";
import { ShotOverlayDto } from "../../models/shotOverlayDto";
import { ModelType } from "../../models/modelType";
import { ShotOverlayType } from "../../models/shotOverlayType";
import { Runfile } from "../../models/runfileType";

export function CompareJobDetail(a: JobDetail, b: JobDetail) {
  if (a == null || b == null) return false;
  let keys = Object.keys(a);
  for (let key of keys) {
    if (a[key] != b[key]) {
      return false;
    }
  }
  return true;
}

declare const Buffer;
@Injectable()
export class CachedProjectService {
  private _currentJob: BehaviorSubject<JobDetail> =
    new BehaviorSubject<JobDetail>(null);
  public readonly currentJob: Observable<JobDetail> =
    this._currentJob.asObservable();

  private _currentProject: BehaviorSubject<Project> =
    new BehaviorSubject<Project>(null);
  public readonly currentProject: Observable<Project> =
    this._currentProject.asObservable();

  constructor(
    private projectService: ProjectService,
    private cacheService: CacheService
  ) {}

  getProjects(): Observable<Project[]> {
    return this.cacheService.get("projects", this.projectService.getProjects());
  }

  setCurrentProject(project: Project) {
    this._currentProject.next(project);
  }

  getProjectsNoCache(): Observable<Project[]> {
    console.log("Calling api to get projects");
    return this.projectService.getProjects();
  }

  getProjectById(id: string): Observable<Project> {
    return this.cacheService.get(
      "project_" + id,
      this.projectService.getProjectById(id)
    );
  }

  getProjectTable(id: string, rebuild = false, ids = []): Observable<any> {
    return this.cacheService.get(
      `project_table_${rebuild}_${ids}` + id,
      this.projectService.getProjectTable(id, rebuild, ids)
    );
  }
  // does not use cache
  _getProjectTable(id: string, rebuild = false, ids = []): Observable<any> {
    return this.projectService.getProjectTable(id, rebuild, ids);
  }
  getParameterTable(
    id: string,
    rebuild = false,
    ids = [],
    forceRefresh: boolean = false
  ): Observable<any> {
    return this.cacheService.get(
      `parameter_table_${rebuild}_${ids}` + id,
      this.projectService.getParameterTable(id, rebuild, ids),
      300000,
      forceRefresh
    );
  }
  getProjectNetwork(id: string, FM = false, ids = []): Observable<any> {
    return this.cacheService.get(
      `project_network_${FM}` + id,
      this.projectService.getProjectNetwork(id, FM)
    );
  }
  getProjectFlowchart(
    id: string,
    FM = false,
    forceRefresh = false
  ): Observable<any> {
    return this.cacheService.get(
      `project_network_v2_${FM}` + id,
      this.projectService.getProjectFlowchart(id, FM),
      null,
      forceRefresh
    );
  }
  // does not use cache
  _getParameterTable(id: string, rebuild = false, ids = []): Observable<any> {
    return this.projectService.getParameterTable(id, rebuild, ids);
  }

  getProjectTableCSV(id: string): Observable<any> {
    return this.projectService.getProjectTableCSV(id);
  }

  // does not use cache
  _getProjectTableCSV(id: string): Observable<any> {
    return this.projectService.getProjectTableCSV(id);
  }

  // getProjectByName(name:string): Observable<Project> {
  //   return this.cacheService.get("projectByName_" + name, this.projectService.getProjectByName(name));
  // }

  getJobDetail(id: string): Observable<JobDetail> {
    return this.cacheService
      .get("project_" + id, this.projectService.getJobDetail(id))
      .pipe(
        map((d) => {
          let currentJob = this._currentJob.getValue();
          if (!CompareJobDetail(currentJob, d)) {
            console.warn("setting current job");
            this._currentJob.next(d);
          }
          return d;
        })
      );
  }

  _getJobDetail(id: string): Observable<JobDetail> {
    // same as above, don't set current job, not intended to use with subscribe.
    return this.projectService.getJobDetail(id);
  }

  __getJobDetail(id: string): Observable<JobDetail> {
    // same as above, sets current job
    return this.projectService.getJobDetail(id).pipe(
      map((d) => {
        let currentJob = this._currentJob.getValue();
        if (!CompareJobDetail(currentJob, d)) {
          console.warn("setting current job");
          this._currentJob.next(d);
        }
        return d;
      })
    );
  }

  _getProjectDatatable(projectId: string): Observable<any> {
    return this.projectService.getProjectDatatable(projectId);
  }

  _updateProjectDatatable(projectId: string, body: object): Observable<any> {
    return this.projectService.updateProjectDatatable(projectId, body);
  }

  _deleteProjectDatatableRecord(
    projectId: string,
    body: object
  ): Observable<any> {
    return this.projectService.deleteProjectDatatableRecord(projectId, body);
  }

  _rebuildProjectDatatable(projectId: string, body: object): Observable<any> {
    return this.projectService.rebuildProjectDatatable(projectId, body);
  }

  _getProjectModelTable(projectId: string): Observable<any> {
    return this.projectService.getModelTableByProject(projectId);
  }

  _rebuildProjectModelTable(projectId: string): Observable<any> {
    return this.projectService.rebuildModelTable(projectId);
  }

  _updateModeltable(projectId: string, body: object): Observable<any> {
    return this.projectService.updateModeltable(projectId, body);
  }

  _getProjectJobs(projectId: string, body: object): Observable<any> {
    return this.projectService.getProjectJobs(projectId, body);
  }

  getJobIterationsCompleted(id: string): Observable<number> {
    return this.cacheService.get(
      "project_" + id + "_iterationsCompleted",
      this.projectService.getJobIterationsCompleted(id)
    );
  }

  getJobMeta(id: string): Observable<any> {
    return this.cacheService.get(
      "project_" + id + "_meta",
      this.projectService.getJobMeta(id)
    );
  }

  getRunfile(id: string, iteration: number = 0): Observable<any> {
    return this.cacheService.get(
      `project_${id}_runfile+${iteration}`,
      this.projectService.getRunfile(id, iteration)
    );
  }

  getRunfileObject(id: string, keywords: string[] = null): Observable<any> {
    return this.cacheService.get(
      `project_${id}_runfile_object_${keywords.join(",")}`,
      this.projectService.getRunfileObject(id, keywords)
    );
  }

  getPlotData(id: string): Observable<PlotData> {
    return this.cacheService.get(
      "project_" + id + "_plot_data",
      this.projectService.getPlotData(id)
    );
  }

  getMatchedJobs(
    rules,
    page: number = 1,
    sort: string = "_score"
  ): Observable<any> {
    return this.cacheService.get(
      "project_" + JSON.stringify(rules) + `_${page}_${sort}_search`,
      this.projectService.getMatchedJobs(rules, page, sort)
    );
  }

  getSearchResults(rules): Observable<any> {
    return this.cacheService.get(
      "project_" + JSON.stringify(rules) + "_search_result",
      this.projectService.getSearchResults(rules)
    );
  }

  getMultipleJobData(jobIds): Observable<any> {
    let idString = jobIds.join("_");
    let hashedString = this._hashString(idString);
    return this.cacheService.get(
      "job_group_" + hashedString,
      this.projectService.getMultipleJobs(jobIds)
    );
  }

  getGroupData(jobs: any): Observable<any> {
    return this.cacheService.get(
      "group_data_" + this._hashString(jobs.join(";")),
      this.projectService.getGroupData(jobs)
    );
  }

  getGroupParams(jobs: any, keywords: any): Observable<any> {
    return this.cacheService.get(
      "group_param_" + this._hashString(jobs.join(";")) + keywords.join("-"),
      this.projectService.getGroupParams(jobs, keywords)
    );
  }

  createSearchResultGroup(
    rule: any,
    jobIds: any,
    comment: string,
    name: string
  ): Observable<any> {
    return this.projectService.createSearchResultGroup(
      rule,
      jobIds,
      comment,
      name
    );
  }

  updateSearchResultGroup(
    groupId: any,
    comments: string,
    name: string
  ): Observable<any> {
    return this.projectService.updateSearchResultGroup(groupId, comments, name);
  }

  getModelTypes(jobId: string, iteration: number): Observable<ModelType[]> {
    return this.cacheService.get(
      "getModelTypes_" + jobId + "_" + iteration,
      this.projectService.getModelTypes(jobId, iteration)
    );
  }

  getSlice(
    id: string,
    modelType: string,
    iteration: number,
    sliceType: SliceType,
    sliceNumber: number,
    is2D: boolean,
    palette: string,
    minVelocity: number = null,
    maxVelocity: number = null,
    shotNumber: number = null,
    fwd: number = null,
    vpRef: number = null,
    timeSlice: number = null
  ): Observable<PlotDetail> {
    return this.cacheService.get(
      `slice_${id}_${modelType}_${iteration}_${timeSlice}_${sliceType}_${sliceNumber}_${is2D}_${palette}_${minVelocity}_${maxVelocity}_${shotNumber}_${fwd}_${vpRef}`,
      this.projectService.getSlice(
        id,
        modelType,
        iteration,
        sliceType,
        sliceNumber,
        is2D,
        palette,
        minVelocity,
        maxVelocity,
        shotNumber,
        fwd,
        vpRef,
        timeSlice
      )
    );
  }

  getSliceArray(
    url: string,
    width: number,
    height: number,
    min: number,
    max: number
  ): Observable<Array<Array<number>>> {
    return this.projectService.getSliceArray(url, width, height, min, max);
  }

  getRanges(
    id: string,
    modelType: string,
    iteration: number,
    sliceParams: object,
    shot: number = null,
    timeSlice: number = null
  ) {
    return this.projectService.getRanges(
      id,
      modelType,
      iteration,
      sliceParams,
      shot,
      timeSlice
    );
  }

  getHorizonTypes(id: string): Observable<string[]> {
    return this.cacheService.get(
      `hasHorizon_${id}`,
      this.projectService.getHorizonTypes(id)
    );
  }

  getHorizon(
    id: string,
    sliceNumber: number,
    sliceType: SliceType,
    horizonName: string
  ): Observable<number[]> {
    return this.cacheService.get(
      `getHorizon_${id}_${sliceNumber}_${sliceType}_${horizonName}`,
      this.projectService.getHorizon(id, sliceNumber, sliceType, horizonName)
    );
  }

  getSonicLog(id: string): Observable<Array<Coordinate>> {
    return this.cacheService.get(
      `sonicLog_${id}`,
      this.projectService.getSonicLog(id)
    );
  }

  getLog(id: string): Observable<string> {
    return this.cacheService.get("log_" + id, this.projectService.getLog(id));
  }

  getCosts(
    id: string,
    totalIterations: number,
    switched: boolean = false
  ): Observable<Cost> {
    return this.cacheService.get(
      `cost_${id}_${totalIterations}_${switched}`,
      this.projectService.getCosts(id, totalIterations, switched)
    );
  }

  getShotOverlay(id: string, iteration: number): Observable<ShotOverlayDto[]> {
    return this.cacheService.get(
      `getShotOverlay_${id}_${iteration}`,
      this.projectService.getShotOverlay(id, iteration)
    );
  }

  getFnlcRanges(id: string): Observable<any> {
    return this.cacheService.get(
      `getFnclRanges_${id}`,
      this.projectService.getFnlcRanges(id)
    );
  }
  getShotOverlayTypes(
    id: string,
    iteration: number = 1
  ): Observable<ShotOverlayType[]> {
    return this.cacheService.get(
      `getShotOverlayTypes_${id}`,
      this.projectService.getShotOverlayTypes(id, iteration)
    );
  }

  getShotsAvailableForModel(
    id: string,
    iteration: number,
    modelType: string = null,
    timeSlice: number = null
  ): Observable<number[]> {
    return this.cacheService.get(
      `getShotsAvailableForModel_${id}_${iteration}_${modelType}`,
      this.projectService.getShotsAvailableForModel(
        id,
        iteration,
        modelType,
        timeSlice
      )
    );
  }

  getTimeSliceAvailableForModel(
    id: string,
    iteration: number,
    shot_id: number,
    modelType: string
  ): Observable<any> {
    return this.cacheService.get(
      `getTimeSliceAvailableForModel_${id}_${iteration}_${shot_id}_${modelType}`,
      this.projectService.getTimeSliceAvailableForModel(
        id,
        iteration,
        shot_id,
        modelType
      )
    );
  }

  getTraceFilesByIteration(
    id: string,
    iteration: number
  ): Observable<number[]> {
    return this.cacheService.get(
      `getAvailableTraceFiles_${id}_${iteration}`,
      this.projectService.getTraceFilesByIteration(id, iteration)
    );
  }

  getTraceFilesById(id: string, data: any): Observable<number[]> {
    let contentHash = this._hashString(Object.values(data).join("_"));
    return this.cacheService.get(
      `getAvailableTraceFiles_${id}_${contentHash}`,
      this.projectService.getTraceFilesById(id, data)
    );
  }

  getTraceMeta(id: string, data): Observable<any> {
    let contentHash = this._hashString(Object.values(data).join("_"));
    return this.cacheService.get(
      `getTraceMeta_${id}_${contentHash}`,
      this.projectService.getTraceMeta(id, data)
    );
  }

  getRunfileParameters(
    id: string,
    setDefault: boolean = false
  ): Observable<Runfile> {
    return this.cacheService.get(
      `getRunfileParameters_${id}_${setDefault}`,
      this.projectService.getRunfileParameters(id, setDefault)
    );
  }

  getScreenShots(id: string): Observable<string[]> {
    return this.projectService.getScreenShots(id);
  }

  getRecentSearchGroups(): Observable<any> {
    return this.cacheService.get(
      `getRecentSearches`,
      this.projectService.getRecentSearchGroups()
    );
  }

  getAvailableCustomNames(id: string, iteration: number, shot_id: number) {
    return this.cacheService.get(
      `getAvailableCustomNames${id}_${iteration}_${shot_id}`,
      this.projectService.getAvailableCustomNames(id, iteration, shot_id)
    );
  }

  getRcvrData(
    id: string,
    iteration: number,
    shot_id: number,
    fwd: number,
    filterParams: any = {},
    x2IsInline: boolean = false
  ) {
    return this.cacheService.get(
      `getRcvrData_${id}_${iteration}_${shot_id}_${fwd}_${Object.values(
        filterParams
      )}_${x2IsInline}`,
      this.projectService.getRcvrData(
        id,
        iteration,
        shot_id,
        fwd,
        filterParams,
        x2IsInline
      )
    );
  }

  getRcvrData_data(
    id: string,
    iteration: number,
    shot_id: number,
    fwd: number,
    filterParams: any = {},
    x2IsInline: boolean = false
  ) {
    return this.cacheService.get(
      `getRcvrData_data_${id}_${iteration}_${shot_id}_${fwd}_${Object.values(
        filterParams
      )}_${x2IsInline}`,
      this.projectService.getRcvrData_data(
        id,
        iteration,
        shot_id,
        fwd,
        filterParams,
        x2IsInline
      )
    );
  }

  getSearchKeywords() {
    return this.cacheService.get(
      "getSearchKeywords",
      this.projectService.getSearchKeywords()
    );
  }

  getFilesList(id: string, base_path: string) {
    return this.cacheService.get(
      `getFilesList_${id}_${base_path}`,
      this.projectService.getFilesList(id, base_path)
    );
  }

  getFunctionalSequence(id: string, path: string, job_prefix: string) {
    return this.cacheService.get(
      `getFunctionalSequence_${id}_${path}_${job_prefix}`,
      this.projectService.getFunctionalSequence(id, path, job_prefix)
    );
  }

  getEnvVariables(id: string) {
    return this.cacheService.get(
      `getEnvVariables_${id}`,
      this.projectService.getEnvVariables(id)
    );
  }

  createProject(data: ProjectDialog): Observable<any> {
    this.clearProjectsCache();
    return this.projectService.createProject(data);
  }

  updateProjectDetails(data: ProjectDialog): Observable<any> {
    return this.projectService.updateProjectDetails(data);
  }

  deleteJob(id: string): Observable<any> {
    return this.projectService.deleteJob(id);
  }

  deleteSearchGroup(id: string): Observable<any> {
    return this.projectService.deleteSearchGroup(id);
  }

  deleteScreenshot(id: string): Observable<any> {
    return this.projectService.deleteScreenshot(id);
  }

  updateJobDetails(data: JobDetailDialog): Observable<any> {
    return this.projectService.updateJobDetails(data);
  }

  updateJobEpicModComments(id: string, epicmod_comments): Observable<any> {
    return this.projectService.updateJobEpicModComments(id, epicmod_comments);
  }

  refreshJobDetails(job_id: number): Observable<any> {
    return this.projectService.refreshJobDetails(job_id);
  }

  updateJobStatus(data: JobStatusDialog): Observable<any> {
    return this.projectService.updateJobStatus(data);
  }

  updateScreenshotsCaption(id: string, caption: string): Observable<any> {
    return this.projectService.updateScreenshotCaption(id, caption);
  }

  uploadScreenshots(id: string, data: any): Observable<any> {
    return this.projectService.uploadScreenshots(id, data);
  }

  getAllProjectsCosts(): Observable<any> {
    return this.projectService.getAllProjectsCosts();
  }

  clearJobDetailCache(ids: string[]) {
    ids.forEach((id) => this.cacheService.delete(`project_${id}`));
  }
  clearJobDetail() {
    this._currentJob.next(null);
  }

  clearProjectsCache() {
    this.cacheService.delete("projects");
  }

  getModelInformation(jobId: string, iteration: number): Observable<any> {
    return this.projectService.getModelInformation(jobId, iteration);
  }

  private _hashString(string) {
    // Source: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
    var hash = 0,
      i,
      chr;
    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
