<div style="position: relative; height: 100%; overflow: scroll">
  <nz-input-group
    [nzSuffix]="suffixIcon"
    style="position: sticky; top: 0px; z-index: 1"
  >
    <input
      type="text"
      nz-input
      placeholder="Search"
      ngModel
      (ngModelChange)="searchValue$.next($event)"
    />
  </nz-input-group>
  <ng-template #suffixIcon>
    <span nz-icon nzType="search"></span>
  </ng-template>
  <nz-tree-view
    [nzTreeControl]="treeControl"
    [nzDataSource]="dataSource"
    nzNoAnimation
  >
    <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodePadding>
      <nz-tree-node-toggle nzTreeNodeNoopToggle></nz-tree-node-toggle>
      <nz-tree-node-checkbox
        [nzDisabled]="node.disabled"
        [nzChecked]="checklistSelection.isSelected(node)"
        (nzClick)="leafItemSelectionToggle(node)"
      ></nz-tree-node-checkbox>
      <span
        (nzClick)="leafItemSelectionToggle(node)"
        [innerHTML]="node.name | nzHighlight : searchValue : 'i' : 'highlight'"
      ></span>
    </nz-tree-node>

    <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodePadding>
      <nz-tree-node-toggle>
        <span nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></span>
      </nz-tree-node-toggle>
      <nz-tree-node-checkbox
        [nzDisabled]="node.disabled"
        [nzChecked]="descendantsAllSelected(node)"
        [nzIndeterminate]="descendantsPartiallySelected(node)"
        (nzClick)="itemSelectionToggle(node)"
      ></nz-tree-node-checkbox>
      <span
        (nzClick)="itemSelectionToggle(node)"
        [innerHTML]="node.name | nzHighlight : searchValue : 'i' : 'highlight'"
      ></span>
    </nz-tree-node>
  </nz-tree-view>
</div>
