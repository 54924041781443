import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { JobDetail } from "../../models/jobDetail";
import { Project } from "../../models/project";
import { Subscription } from "rxjs";
import { ProjectNameComponent } from "./dialogs/project-name/project-name.component";
import { SearchGroupComponent } from "./dialogs/search-group/search-group.component";
import { MatDialog } from "@angular/material/dialog";
import { ProjectDialog } from "../../models/projectDialog";
import { JobDetailComponent } from "./dialogs/job-detail/job-detail.component";
import { MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from "@angular/material/snack-bar";
import { JobStartComponent } from "./dialogs/job-start/job-start.component";
import { JobStatusDialog } from "../../models/jobStatusDialog";
import { JobDetailDialog } from "../../models/jobDetailDialog";
import { AuthService } from "../../shared/services/auth.service";
import { StoredSettingsService } from "../services/stored-settings.service";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { ElementRef } from "@angular/core";
import { take } from "rxjs/operators";
import { KeepJobComponent } from "./dialogs/keep-job/keep-job.component";

declare var $: any;
@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.less"],
})
export class ProjectComponent implements OnInit, OnDestroy {
  jobDetail: JobDetail;

  jobs: any[];
  jobSearchString: string = "";

  popularJobs: any[] = [];
  popularJob_ids = new Set();
  selectedIndex: number = 0;
  selectedJobName: string = "";
  selectedJobIndex: number = 0;
  selectedIndexPopular: number = null;
  currentId: string;
  project: Project;
  tabBarVisible: boolean = false;
  tabIsEmpty: boolean = true;
  synchroniseSettings = false;
  isTmpGroup: boolean = false;
  projectSettingsBarBottom = "53px";
  private _request: Subscription = null;
  private _synchoriseSubscription: Subscription = null;
  private _searchId = null;
  private _rules = null;
  private _name = null;
  private _comment = null;
  windowWidth: number;
  isMobile: boolean;
  lastSelectedItem: MatMenuItem;
  isReloadingJob: boolean = false;
  parentJobDisplayStr: string;
  parentJobFullStr: string;
  jobComments: Map<string, string> = new Map<string, string>();
  comments_Disabled = [];
  searchStr = "";
  jobFilteredOptions = [];
  projectJobs: any[] = []
  @ViewChildren(MatMenuItem) menuItems: QueryList<MatMenuItem>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private projectService: CachedProjectService,
    private router: Router,
    private storedSettingsService: StoredSettingsService,
    public authService: AuthService,
    private elementRef: ElementRef,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.windowWidth = window.innerWidth;
    this.isMobile = /Mobile/.test(navigator.userAgent);
    this.route.params.subscribe((params: Params) => {
      if (!params["id"]) {
        console.warn("No project id provided");
        return;
      }
      let id = params["id"];
      this.currentId = id;
      if (!this.jobs) {
        this.projectService.getProjects().subscribe((projects) => {
          var project = projects.find((p) => !!p.jobs.find((j) => j.id === id));
          this.project = project;

          if (project) {
            let snapShotParams = this.route.snapshot.queryParams;
            if (snapShotParams.jobs && snapShotParams.jobs.length > 0) {
              this.jobs = [];
              this.projectJobs = []
              projects.forEach((p) => {
                p.jobs.forEach((j) => {
                  this.projectJobs.push(j);
                  if (snapShotParams.jobs.indexOf(j.id) >= 0) {
                    this.jobs.push(j);
                    this.comments_Disabled.push(true);
                  }
                });
              });
              if (snapShotParams.rules && snapShotParams.name) {
                this._searchId = snapShotParams.searchId;
                this._comment = snapShotParams.comment || "";
                this._rules = {};
                let rules = snapShotParams.rules.split(";");
                rules.forEach((r) => {
                  let kv = r.split(":");
                  this._rules[kv[0]] = kv[1].split(",");
                });
                this._name = snapShotParams.name;
                this.isTmpGroup = true;
                if (this.jobDetail) this.jobDetail.projectName = this._name;
                this.jobDetail.projectDescription = this._comment;
              }
            } else {
              this.jobs = project.jobs;
              this.projectJobs = project.jobs
              this.isTmpGroup = false;
            }
            this.selectedIndex = this.jobs.findIndex((j) => j.id === id);

            this.selectedJobName = this.jobs[this.selectedIndex].name;
            this.selectedJobIndex = this.selectedIndex;
            this.onSelectedChanged(this.selectedIndex, true, false);
          }
        });
      } else {
        this.selectedIndex = this.jobs.findIndex((j) => j.id === id);
        this.selectedJobName = this.jobs[this.selectedIndex].name;
        this.selectedJobIndex = this.selectedIndex;
        this.onSelectedChanged(this.selectedIndex, true, false);
      }
    });

    this._synchoriseSubscription =
      this.storedSettingsService.synchroniseSettings.subscribe(
        (val) => (this.synchroniseSettings = val)
      );
  }

  /**
   *
   * @param menuTrigger
   * @param event
   * @description when job menu is opened, scroll to selected job, and highlight it for 500ms (currently disabled)
   */
  menuOpen(menuTrigger: MatMenuTrigger, event) {
    if (this.selectedIndex != null) {
      this.lastSelectedItem = this.menuItems.toArray()[this.selectedIndex];
    }
    let item = this.lastSelectedItem;
    if (item != null) {
      item
        ._getHostElement()
        .scrollIntoView({ behavior: "smooth", block: "center" });
      // item._getHostElement().style.backgroundColor = '#FFD54F';
      // setTimeout(() => {
      //   item._getHostElement().style.backgroundColor = 'white';
      // }, 500)
    }
  }

  /**
   *
   * @param menuTrigger
   * @param event
   * @description trigger the same event as clicking on the tabs when clicking on job menu items
   */
  menuSelected(menuItem: MatMenuItem, index, event) {
    this.onSelectedChanged(index);
    this.lastSelectedItem = menuItem;
  }
  menuSelected2(index: number) {
    this.onSelectedChanged(index);
  }

  updateSearchStr() {
    if (this.searchStr == "") {
      this.jobFilteredOptions = [];
    } else {
      this.jobFilteredOptions = this.jobs.filter((job) => {
        return job.name.toLowerCase().includes(this.searchStr.toLowerCase());
      });
    }
  }

  jobSelected(event) {
    let index = this.jobs.findIndex((j) => j.name == event.option.value);
    if (index == -1) {
      return;
    }
    this.onSelectedChanged(index);
    this.lastSelectedItem = this.menuItems.toArray()[index];
  }

  modifySliceBottom() {
    let slider = this.elementRef.nativeElement.querySelector(".sliders-bar");
    if (slider) {
      slider.style.bottom = this.tabIsEmpty ? "53px" : "108px";
    }
    this.projectSettingsBarBottom = this.tabIsEmpty ? "53px" : "108px";
  }

  /**
   *
   * @description add current job to popular tab
   */
  addTab() {
    if (this.popularJob_ids.has(this.jobs[this.selectedIndex].id)) {
      return;
    }
    this.popularJobs.push(this.jobs[this.selectedIndex]);
    this.popularJob_ids.add(this.jobs[this.selectedIndex].id);
    this.selectedIndexPopular = this.popularJobs.length - 1;
    this.tabIsEmpty = this.popularJobs.length == 0;
    this.modifySliceBottom();
  }

  /**
   *
   * @description remove current job from popular tab
   */
  removeTab() {
    this.popularJob_ids.delete(this.popularJobs[this.selectedIndexPopular].id);
    this.popularJobs.splice(this.selectedIndexPopular, 1);
    if (this.popularJobs.length == 0 && this.popularJob_ids.size != 0) {
      // to avoid potential issues
      this.popularJob_ids.clear();
    }

    this.tabIsEmpty = this.popularJobs.length == 0;
    this.modifySliceBottom();
  }

  shiftLeft() {
    if (this.selectedIndexPopular <= 0) {
      return;
    }
    let temp = this.popularJobs[this.selectedIndexPopular - 1];
    this.popularJobs[this.selectedIndexPopular - 1] =
      this.popularJobs[this.selectedIndexPopular];
    this.popularJobs[this.selectedIndexPopular] = temp;
    this.selectedIndexPopular--;
  }

  shiftRight() {
    if (this.selectedIndexPopular >= this.popularJobs.length - 1) {
      return;
    }
    let temp = this.popularJobs[this.selectedIndexPopular + 1];
    this.popularJobs[this.selectedIndexPopular + 1] =
      this.popularJobs[this.selectedIndexPopular];
    this.popularJobs[this.selectedIndexPopular] = temp;
    this.selectedIndexPopular++;
  }

  ngOnDestroy() {
    this.titleService.setTitle("S-Cube Cloud");
    if (this._request) {
      this._request.unsubscribe();
      this._request = null;
    }
    if (this._synchoriseSubscription) {
      this._synchoriseSubscription.unsubscribe();
      this._synchoriseSubscription = null;
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    var offset = $("#tab-bar").offset();
    this.tabBarVisible = offset && offset.top + 50 < $(document).scrollTop();
  }

  @HostListener("window:resize", ["event"])
  onWindowResize(e) {
    this.windowWidth = window.innerWidth;
  }

  isDemoPage() {
    if (this.route.snapshot.firstChild) {
      if (this.route.snapshot.firstChild.url[0]) {
        return this.route.snapshot.firstChild.url[0].path === "demo";
      }
    }
    return false;
  }

  onSynchroniseChange(value) {
    this.storedSettingsService.setSynchroniseSettings(value);
  }

  getJob(id: string): void {
    // in order to get parent we need to ensure that this.jobs is not null
    if (this.popularJob_ids.has(this.currentId)) {
      this.selectedIndexPopular = this.popularJobs.findIndex(
        (j) => j.id === this.currentId
      );
    }
    if (this.jobs)
      this.selectedIndex = this.jobs.findIndex((j) => j.id === this.currentId);

    if (this._request) {
      this._request.unsubscribe();
      this._request = null;
    }

    this._request = this.projectService
      .getJobDetail(id)
      .pipe(take(1))
      .subscribe((p) => {
        let projectName = this.isTmpGroup ? this._name : p.projectName;
        let projectDescription = this.isTmpGroup
          ? this._comment
          : p.projectDescription;
        this.jobDetail = p;
        // console.log(p)
        this.jobDetail.projectName = projectName;
        this.jobComments.set(id, p.comments);
        this.titleService.setTitle(this.jobDetail.projectName);
        this.jobDetail.projectDescription = projectDescription;
        this.setParentJobDisplayStr();
      });
    if (this.jobs) {
      this.selectedJobName = this.jobs[this.selectedIndex].name;
      this.selectedJobIndex = this.selectedIndex;
    }
    this.comments_Disabled[this.selectedIndex] = false;
  }

  getJobStatus(currentJob: JobDetail) {
    if (currentJob.status == "RUNNING") {
      return "Started";
    } else if (currentJob.status == "NOTRUNNINNG") {
      return "Stopped";
    } else if (currentJob.status == "FINISHED") {
      return "Finished";
    } else {
      return "Disabled";
    }
  }

  onSelectedChanged(index: number, skip = false, use_cache = true) {
    var jobid = this.jobs[index].id;
    if (!skip) {
      if (jobid === this.currentId) return;
    }

    this.selectedIndex = index;
    if (this.popularJob_ids.has(this.currentId)) {
      this.selectedIndexPopular = this.popularJobs.findIndex(
        (j) => j.id === this.currentId
      );
    }
    if (this._request) {
      this._request.unsubscribe();
      this._request = null;
    }
    if (use_cache) {
      this._request = this.projectService
        .getJobDetail(jobid)
        .pipe(take(1))
        .subscribe((p) => {
          let projectName = this.isTmpGroup ? this._name : p.projectName;
          let projectDescription = this.isTmpGroup
            ? this._comment
            : p.projectDescription;
          this.jobDetail = p;
          this.jobComments.set(jobid, p.comments);
          this.titleService.setTitle(this.jobDetail.projectName);
          this.jobDetail.projectName = projectName;
          this.jobDetail.projectDescription = projectDescription;
          this.setParentJobDisplayStr();
          this.updateUrl(jobid);
        });
    } else {
      this._request = this.projectService
        .__getJobDetail(jobid)
        .pipe(take(1))
        .subscribe((p) => {
          let projectName = this.isTmpGroup ? this._name : p.projectName;
          let projectDescription = this.isTmpGroup
            ? this._comment
            : p.projectDescription;
          this.jobDetail = p;
          this.jobComments.set(jobid, p.comments);
          this.titleService.setTitle(this.jobDetail.projectName);
          this.jobDetail.projectName = projectName;
          this.jobDetail.projectDescription = projectDescription;
          this.setParentJobDisplayStr();
          this.updateUrl(jobid);
        });
    }
  }

  onSelectedChangedPopular(index: number, skip = false) {
    if (!this.popularJobs.length || index == null || index < 0) {
      return;
    }
    var jobid = this.popularJobs[index].id;
    if (!skip) {
      if (jobid === this.currentId) return;
    }

    this.selectedIndexPopular = index;
    // this.selectedIndex = this.jobs.findIndex((j) => j.id === this.currentId);
    if (this._request) {
      this._request.unsubscribe();
      this._request = null;
    }

    this._request = this.projectService
      .getJobDetail(jobid)
      .pipe(take(1))
      .subscribe((p) => {
        let projectName = this.isTmpGroup ? this._name : p.projectName;
        let projectDescription = this.isTmpGroup
          ? this._comment
          : p.projectDescription;
        this.jobDetail = p;
        this.jobComments.set(jobid, p.comments);
        this.titleService.setTitle(this.jobDetail.projectName);
        this.jobDetail.projectName = projectName;
        this.jobDetail.projectDescription = projectDescription;
        this.setParentJobDisplayStr();
        this.updateUrl(jobid);
      });
  }

  updateUrl(jobid, params?) {
    // params would be a URLSearchParam object
    let baseUrl = this.router.url.replace(this.currentId, jobid).split("?")[0];
    if (params != null) {
      baseUrl = baseUrl + "?" + params.toString();
    }
    if (this.router.url != baseUrl) {
      this.router.navigateByUrl(baseUrl);
    }
  }

  openProjectDialog() {
    let dialogRef = this.dialog.open(ProjectNameComponent, {
      width: "400px",
      data: {
        id: this.project.id,
        name: this.jobDetail.projectName,
        description: this.jobDetail.projectDescription,
      },
    });

    dialogRef.afterClosed().subscribe((result: ProjectDialog) => {
      if (!result) return;
      this.projectService.updateProjectDetails(result).subscribe(
        (data) => {
          this.project.name = result.name;
          this.project.description = result.description;
          this.jobDetail.projectName = result.name;
          this.jobDetail.projectDescription = result.description;

          this.projectService.clearProjectsCache();
          this.projectService.clearJobDetailCache(
            this.project.jobs.map((j) => j.id)
          );
          this.snackBar.open("Project details updated", null, {
            duration: 2000,
          });
        },
        (error) => {
          this.snackBar.open(
            "Sorry, the project details could not be updated",
            null,
            { duration: 2000 }
          );
        }
      );
    });
  }

  openSearchGroupDialog() {
    let dialogRef = this.dialog.open(SearchGroupComponent, {
      height: "320px",
      width: "400px",
      data: {
        id: null,
        name: this._name,
        description: this._comment,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result || !result.name) return;
      let searchGroupSubscription = null;
      if (this._searchId) {
        searchGroupSubscription = this.projectService.updateSearchResultGroup(
          this._searchId,
          result.description,
          result.name
        );
      } else {
        searchGroupSubscription = this.projectService.createSearchResultGroup(
          this._rules,
          this.jobs.map((j) => j.id),
          result.description,
          result.name
        );
      }

      searchGroupSubscription.subscribe(
        (data) => {
          this.jobDetail.projectName = result.name;
          this._name = result.name;
          this.jobDetail.projectDescription = result.description;
          this._comment = result.description;
          this.snackBar.open("Search result saved", null, { duration: 2000 });
        },
        (error) => {
          this.snackBar.open("Sorry, save search result failed", null, {
            duration: 2000,
          });
        }
      );
    });
  }

  openJobDialog(isPopular: boolean) {
    var job = this.jobs.find(
      (j) =>
        j.id ===
        (isPopular
          ? this.popularJobs[this.selectedIndexPopular].id
          : this.currentId)
    );
    if (!job) return;
    // refresh job details before open job dialog
    this.projectService.refreshJobDetails(job.id).subscribe(
      (refresh) => {
        this.projectService.clearJobDetailCache([job.id]);

        this.jobDetail.name = refresh.job_name;
        this.jobDetail.basePath = refresh.job_basepath;
        this.jobDetail.iterations = refresh.iterations;
        this.jobDetail.iterationsComplete = refresh.iterations_completed;
        this.jobDetail.status = refresh.status;
        this.jobDetail.comments = refresh.comments;
        this.jobDetail.tag = refresh.tag;
        job.name = refresh.job_name;
        job.status = refresh.status;

        // console.log('print');
        // console.log(refresh.comments);

        // this.snackBar.open("Job Details updated", null, { duration: 2000 });

        // open job dialog
        let dialogRef = this.dialog.open(JobDetailComponent, {
          // height: "500px",
          width: "400px",
          data: {
            id: job.id,
            name: job.name,
            projectId: this.project.id,
            basePath: this.jobDetail.basePath,
            iterations: this.jobDetail.iterations,
            iterationsComplete: this.jobDetail.iterationsComplete,
            status: this.jobDetail.status,
            comments: this.jobDetail.comments,
            tag: this.jobDetail.tag,
            parent_job_id: this.jobDetail.parent_job_id,
            parent_cp_num: this.jobDetail.parent_cp_num,
            offshoot: this.jobDetail.offshoot,
          },
        });

        dialogRef.afterClosed().subscribe((result: JobDetailDialog) => {
          if (!result) return;
          this.projectService.updateJobDetails(result).subscribe(
            (data) => {
              this.jobDetail.name = result.name;
              this.jobDetail.basePath = result.basePath;
              this.jobDetail.iterations = result.iterations;
              this.jobDetail.iterationsComplete = result.iterationsComplete;
              this.jobDetail.status = result.status;
              this.jobDetail.comments = result.comments;
              this.jobDetail.parent_job_id = result.parent_job_id;
              this.jobDetail.parent_cp_num = result.parent_cp_num;
              this.jobDetail.offshoot = result.offshoot;
              job.name = result.name;
              job.status = result.status;

              this.projectService.clearProjectsCache();
              this.projectService.clearJobDetailCache(
                this.project.jobs.map((j) => j.id)
              );
              this.snackBar.open("Job Details updated", null, {
                duration: 2000,
              });

              if (result.projectId !== this.project.id) {
                this.router.navigate(["/projects"]);
                return;
              }
              this.setParentJobDisplayStr();
            },
            (error) => {
              this.snackBar.open(
                "Sorry, the job details could not be updated",
                null,
                { duration: 2000 }
              );
            }
          );
        });
      },
      (error) => {
        this.snackBar.open(
          "Sorry, the job details could not be updated",
          null,
          { duration: 2000 }
        );
      }
    );
  }

  onStartStopClicked() {
    var job = this.jobs.find((j) => j.id === this.currentId);
    if (!job) return;

    let dialogRef = this.dialog.open(JobStartComponent, {
      height: "210px",
      width: "300px",
      data: {
        id: job.id,
        name: job.name,
        status: job.status,
      },
    });

    dialogRef.afterClosed().subscribe((result: JobStatusDialog) => {
      if (!result) return;
      this.projectService.updateJobStatus(result).subscribe(
        (data) => {
          this.jobDetail.status = data.status;
          this.jobDetail.lastErrorStatus = data.last_error_status;
          this.jobDetail.startTime = !!data.last_started
            ? new Date(data.last_started.split("+")[0] + ".000Z")
            : null;
          this.jobDetail.runTime = data.cumulated_runtime_sec;

          job.status = data.status;
          job.lastErrorStatus = data.last_error_status;
          job.startTime = !!data.last_started
            ? new Date(data.last_started.split("+")[0] + ".000Z")
            : null;
          job.runTime = data.cumulated_runtime_sec;

          this.projectService.clearJobDetailCache([result.id]);
          this.snackBar.open("Job status updated", null, { duration: 2000 });
        },
        (error) => {
          this.snackBar.open(
            "Sorry, the job status could not be updated",
            null,
            { duration: 2000 }
          );
        }
      );
    });
  }

  private addURLParameter(url: string, parameter, value) {
    var result = this.removeURLParameter(url, parameter);
    if (value == null) return result;

    if (url.includes("?")) {
      result += "&" + parameter + "=";
    } else {
      result += "?" + parameter + "=";
    }
    result += value;

    return result;
  }

  private removeURLParameter(url, parameter) {
    var urlparts = url.split("?");
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + "=";
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    }
    return url;
  }

  toParentJob() {
    let parent_index = this.getIndexOfJob(this.jobDetail.parent_job_id);
    // if (parent_index == -1) {
    if (this.jobDetail.parent_cp_num != null) {
      // console.log("THIS WORKED")
      let param = new URLSearchParams();
      param.set("iteration", this.jobDetail.parent_cp_num.toString());
      this.updateUrl(this.jobDetail.parent_job_id, param);
    } else {
      this.updateUrl(this.jobDetail.parent_job_id);
    }
    // } else {
    this.onSelectedChanged(parent_index);
    // }
  }

  getIndexOfJob(jobId: string) {
    let index = -1;
    for (let i = 0; i < this.jobs.length; i++) {
      if (this.jobs[i].id == jobId) {
        index = i;
        break;
      }
    }
    return index;
  }

  addTabWithId(jobId: string) {
    if (this.popularJob_ids.has(jobId)) {
      return;
    }
    let index = this.getIndexOfJob(jobId);
    this.popularJobs.push(this.jobs[index]);
    this.popularJob_ids.add(jobId);
    // this.selectedIndexPopular = this.popularJobs.length - 1;
    this.tabIsEmpty = this.popularJobs.length == 0;
    this.modifySliceBottom();
  }

  /**
   *
   * @description refresh jobs when user clicks on the refresh button, does not use the local cache service,
   * not sure about other cache service.
   */
  refreshJobs() {
    this.isReloadingJob = true;
    setTimeout(() => {
      this.projectService.getProjectsNoCache().subscribe((projects) => {
        var project = projects.find(
          (p) => !!p.jobs.find((j) => j.id === this.currentId)
        );
        this.project = project;

        if (project) {
          let snapShotParams = this.route.snapshot.queryParams;
          if (snapShotParams.jobs && snapShotParams.jobs.length > 0) {
            this.jobs = [];
            this.projectJobs = []
            projects.forEach((p) => {
              p.jobs.forEach((j) => {
                this.projectJobs.push(j)
                if (snapShotParams.jobs.indexOf(j.id) >= 0) {
                  this.jobs.push(j);
                }
              });
            });
            if (snapShotParams.rules && snapShotParams.name) {
              this._searchId = snapShotParams.searchId;
              this._comment = snapShotParams.comment || "";
              this._rules = {};
              let rules = snapShotParams.rules.split(";");
              rules.forEach((r) => {
                let kv = r.split(":");
                this._rules[kv[0]] = kv[1].split(",");
              });
              this._name = snapShotParams.name;
              this.isTmpGroup = true;
              if (this.jobDetail) this.jobDetail.projectName = this._name;
              this.jobDetail.projectDescription = this._comment;
            }
          } else {
            this.jobs = project.jobs;
            this.projectJobs = project.jobs
            this.isTmpGroup = false;
          }
          this.selectedIndex = this.jobs.findIndex(
            (j) => j.id === this.currentId
          );

          this.selectedJobName = this.jobs[this.selectedIndex].name;
          this.selectedJobIndex = this.selectedIndex;
        }
        this.isReloadingJob = false;
      });
    }, 500);
  }

  /**
   *
   * @description if job has parent, form a string that displays the parent job name and cp number
   * assume the parent job is in the same project
   */
  setParentJobDisplayStr() {
    if (
      this.jobDetail.parent_job_id != null &&
      this.jobDetail.parent_cp_num != null
    ) {
      let parent_job = this.projectJobs.find(
        (job) => job.id == this.jobDetail.parent_job_id
      );
      if (parent_job != null) {
        this.parentJobDisplayStr =
          parent_job.name.substring(0, 10) +
          "-CP" +
          this.jobDetail.parent_cp_num.toString();
        if (this.jobDetail.offshoot == true) {
          this.parentJobDisplayStr = this.parentJobDisplayStr + " (offshoot)";
        }
        this.parentJobFullStr = parent_job.name;
      } else {
        this.parentJobDisplayStr = null;
        this.parentJobFullStr = null;
      }
      // this.projectService.getJobDetail(this.jobDetail.parent_job_id).subscribe((res)=> {
      //   this.parentJobDisplayStr = res.projectName.substring(0,10) + '-'
      //                            + res.name.substring(0,10)
      //                            + '-CP' + this.jobDetail.parent_cp_num.toString()
      //   this.parentJobFullStr = res.name;
      // })
    } else {
      this.parentJobDisplayStr = null;
      this.parentJobFullStr = null;
    }
  }

  /**
   *
   * @description opens a dialog to ask user to confirm if they want to keep / not keep the job
   */
  keep_job() {
    let name = this.jobDetail.name;
    let keep_job = this.jobDetail.keep_job;
    this.dialog
      .open(KeepJobComponent, {
        height: "320px",
        width: "400px",
        data: {
          job_name: name,
          keep_job: keep_job,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          keep_job = !keep_job;
          this.update_entry(this.jobDetail.id, keep_job);
        }
      });
  }

  /**
   *
   * @param job_id
   * @param keep_job
   * @description update the keep_job field in the database
   */
  update_entry(job_id, keep_job) {
    this.projectService
      ._getJobDetail(job_id)
      .toPromise()
      .then((data) => {
        let jobDetailDialog: JobDetailDialog = {
          id: data["id"],
          name: data["name"],
          projectId: data["projectId"],
          basePath: data["basePath"],
          iterations: data["iterations"],
          iterationsComplete: data["iterationsComplete"],
          status: data["status"],
          comments: data["comments"],
          tag: data["tag"],
          parent_job_id: data["parent_job_id"],
          parent_cp_num: data["parent_cp_num"],
          keep_job: keep_job,
        };
        this.projectService
          .updateJobDetails(jobDetailDialog)
          .toPromise()
          .then((data) => {
            this.jobDetail.keep_job = keep_job;
          });
      });
  }

  navigateToProjectTable() {
    // this.router.navigateByUrl('/project/' + this.jobDetail.projectId);
    window.open(
      "/project/" + this.jobDetail.projectId + "/parameter-view",
      "_blank"
    );
  }

  /**
   *
   * @description calculate the number of days left before the job is archived if the job is not marked kept
   * might not be entirely accurate due to time zone difference.
   * @returns number of days left
   */
  getJobCleanUpCountDown() {
    let now = new Date();
    let createdDate = new Date(this.jobDetail.createdDate);
    let sevendays = 7 * 24 * 60 * 60 * 1000;
    let cleanupdate = new Date(createdDate.getTime() + sevendays);
    let diff = cleanupdate.getTime() - now.getTime();
    let days = 0;
    if (diff > 0) {
      days = Math.floor(diff / (1000 * 60 * 60 * 24));
    }
    return days;
  }

  /**
   *
   * @description based on values in the jobDetail object, determine the archive status of the job
   * @returns string representing the archive status
   */
  getJobArchiveStatus() {
    let job = this.jobDetail;
    let created = new Date(this.jobDetail.createdDate);
    if (job.keep_job) {
      return "keep";
    } else if (
      created.getTime() + 7 * 24 * 60 * 60 * 1000 >
      new Date().getTime()
    ) {
      return "< 7 days (full storage)";
    } else if (job.soft_archive_status == true) {
      return "glacier instant retrieval";
    } else if (job.is_archived == true) {
      return "glacier deep archive";
    } else {
      return "full storage";
    }
  }

  /**
   *
   * @description determine if we need to show the countdown for the job
   * @returns boolean
   */
  showCountDown() {
    return this.getJobArchiveStatus() == "< 7 days (full storage)";
  }
}
