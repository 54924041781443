<app-banner heading="Projects" description="Select your project or create a new one to add your data" *ngIf="!authService.isDemoUser() && isStaff" button-text="Parameter View" (button-event)="gotoMonitoring()"></app-banner>
<app-banner heading="Projects" description="Select your project or create a new one to add your data" *ngIf="!authService.isDemoUser() && !isStaff"></app-banner>
<app-banner heading="Projects" description="Select your project or create a new one to add your data" *ngIf="authService.isDemoUser()"></app-banner>

<section class="content apc standard-components  c5-bg" id="pos_1404">
  <div class="container" data-os-animation="fadeIn" data-os-animation-delay="0">
    <div class="row">
      <div class="spc accordion">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div role="tab" *ngFor="let project of projects; let i = index">
            <p class="heading">
              <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse-1293-1" aria-expanded="false" (click)="clickProject(project)">
                <div style="display: flex; justify-content: space-between;">
                  <p class="heading">{{project.name}}</p>
                  <span *ngIf="isActiveProject(project)" style="margin-right: 50px;" (click)="navigateToProjectTable(project)">Project Page</span>
                </div>
                <div class="text"><p><em>{{project.description}}</em></p></div>
                <i *ngIf="isActiveProject(project)" class="zmdi zdmi-minus"></i><i *ngIf="!isActiveProject(project)" class="zdmi zdmi-plus"></i>
                <app-list [@slideIn] [hidden]="!isActiveProject(project)" [list]="jobItems[project.id]"></app-list>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
