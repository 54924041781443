import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import html2canvas from "html2canvas";
import { Subject, Subscription, forkJoin, of, timer } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { JobDetail } from "../../../models/jobDetail";
import { MinMaxDialog } from "../../../models/minMaxDialog";
import { ModelSettings } from "../../../models/modelSettings";
import { ModelType } from "../../../models/modelType";
import { ModelTypeSettings } from "../../../models/modelTypeSettings";
import { ModelViewSettings } from "../../../models/modelViewSetting";
import { Ranges } from "../../../models/ranges";
import { ShotOverlayType } from "../../../models/shotOverlayType";
import { Slice } from "../../../models/slice";
import { SliceType } from "../../../models/sliceType";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { AuthService } from "../../../shared/services/auth.service";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { CachedPaletteService } from "../../services/cached-palette.service";
import { StoredSettingsService } from "../../services/stored-settings.service";
import {
  ColorBarTextInfo,
  stripDash,
  vpLowerBound,
  vpUpperBound,
} from "../chart-slice/chart-slice.component";
import { VelocityComponent } from "../dialogs/velocity/velocity.component";
import { allSliceProcessings } from "./slice_processings";
import { MatSnackBar } from "@angular/material/snack-bar";

declare var $: any;
@Component({
  selector: "app-model",
  templateUrl: "./model.component.html",
  styleUrls: ["./model.component.less"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
    trigger("flyInOut", [
      state("in", style({ opacity: 1, transform: "translateY(0)" })),
      transition("void => *", [
        style({
          opacity: 0,
          transform: "translateY(-100%)",
        }),
        animate("0.2s ease-in"),
      ]),
      transition("* => void", [
        animate(
          "0.2s 0.1s ease-out",
          style({
            opacity: 0,
            transform: "translateY(100%)",
          })
        ),
      ]),
    ]),
  ],
})
export class ModelComponent implements OnInit, OnDestroy, AfterViewInit {
  previous_projectID: string = null;
  currentJob: JobDetail;
  height: number = 625;
  inputHeight: number = 625;
  inputDepthHeight = 250;
  settingsKey = "page";
  inlineNumber: number = null;
  crosslineNumber: number = null;
  depthSlice: number = null;
  iteration: number = null;
  timeSlice: number = null;
  availTimeSlices: number[] = [];
  showDepthSliceScreenshot: boolean = true;
  depthScreenshotText: string = "(w/o depth)";
  selectedJobs: any = null;
  shotId: number = null;
  availShots: number[] = [];
  timeSliceModels = ["ForwardWavefield", "BackwardWavefield"];
  shotModels = ["ForwardWavefield", "BackwardWavefield", "grad", "den", "dens"];
  selectedSonicId: any = null;
  velocityAuto = true;
  private _rules: any = null;
  private _comment: string = null;
  private _name: string = null;
  private _searchId: any = null;

  steps: number[] = [10, 10, 10, 10];
  ranges: Ranges = {
    x: [0, 0],
    y: [0, 0],
    z: [0, 0],
  };
  slices: Slice[] = [
    {
      sliceType: SliceType.inline,
      span: 2,
    },
    {
      sliceType: SliceType.crossLine,
      span: 1,
    },
  ];

  isLoading: boolean = false;
  updateChart: boolean = false;
  refreshChart: boolean = false;

  inlineWidth: number = 100;
  crosslineWidth: number = 100;

  maxDepth: number = 0;
  depthHeight: number = 250;
  palettes: string[] = [];
  modelTypes: ModelType[] = [
    { id: "Vp", name: "Vp", processing: [] },
    { id: "Gradient", name: "Gradient", processing: [] },
    { id: "InvVp Update", name: "InvVp Update", processing: [] },
  ];
  shotOverlayTypes: ShotOverlayType[] = [
    { id: "traces_fit", name: "Traces Fit", min: 0, max: 100 },
    { id: "norm_ratio", name: "Norm Ratio", min: 0, max: 1 },
    { id: "fitting_factor", name: "Fitting Factor", min: 0, max: 1 },
  ];
  horizonTypes: any = [];
  currentModelTypeId = null;
  currentModelTypeProcessing: Array<string> = [];
  currentShotOverlayType: ShotOverlayType = null;
  showSonicLog: boolean = false;
  showShotOverlay: boolean = false;
  showHorizonTypes = [];
  showHorizon: boolean = false;
  color: string = "#000000";
  horizonColors: string[] = [];

  epochs: number;
  costPerIteration: number;
  hasCosts: boolean = true;
  elapsedTime: string;
  sliderBarOpen: boolean = false;
  sliderBarVisible: boolean = false;
  loadedJob = false;
  synchroniseSettings = false;
  vpUpdateCheck = false;
  vpDiffIteration: number;

  isStaff = false;
  isMobile: boolean;

  private _costsSubscription: Subscription;
  private _projectsSubscription: Subscription;
  private _timerSubscription: Subscription;
  private _modelTypesSubscription: Subscription;
  private _shotOverlaySubscription: Subscription;
  private _routeSubscription: Subscription;
  private _routeQuerySubscription: Subscription;
  private _waterBottomSubscription: Subscription;
  private _rangeSubscription: Subscription;
  private _userSubscription: Subscription;
  private _synchoriseSubscription: Subscription = null;
  private _timeSliceSubscription: Subscription = null;

  minVal: number;
  maxVal: number;
  minValZ: number;
  maxValZ: number;
  private _paletteOfCurrentType: string;
  velocities: number[];
  depth: number;
  verticalProfileAxis: number[] = [null, null];
  inlineColorBarMinMax: number[] = [null, null];
  verticalProfileIsLoading = true;
  verticalProfileHasError: boolean;
  velocityProfileCrossline: number;
  syncAxis: boolean;
  vpPositionSwapped = false;
  vpHeight: number;
  enableClick = false;
  scrollPos: number;
  restoredScrollPos: number;
  time_to_scroll_subscription = new Subject<string>();
  waitingFor: number;
  functionals;
  frequencies;
  additionals;
  dataSource: object[] = [];
  cols = ["Block", "Functional", "Frequency", "Iterations"];
  FUNCTIONAL_COLORS = {
    AWI: "#F8DC6F",
    FWI: "#EE865B",
    RWI: "#4295A5",
  };
  tableHeight = "300px";
  storedMinMax; // for synching the min/max between Slowness update and gradient, it is set when manually set the min/max, and reset when set to auto.
  shotRadius = 2;
  showDottedLines = true;
  currentJobSubscription: Subscription;
  ILXLStartDepth = null;
  ILXLEndDepth = null;
  ILXLStartInline = null;
  ILXLEndInline = null;
  ILXLStartCrossline = null;
  ILXLEndCrossline = null;
  max_inline = null;
  max_crossline = null;
  showDepthSliceGraph = false;
  comments: string = "";
  screenshotWithVertProfile: boolean = false;
  modelInfoLoading: boolean = false;
  modelInfoFetched: boolean = false;
  // syncRangeBetweenSlices: boolean = true;

  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private projectService: CachedProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private paletteService: CachedPaletteService,
    public authService: AuthService,
    private storedSettingsService: StoredSettingsService,
    private userService: CachedUserService,
    private zone: NgZone
  ) {
    this.currentShotOverlayType = this.shotOverlayTypes[0];
  }

  ngOnInit(): void {
    this.time_to_scroll_subscription.pipe(take(1)).subscribe((res) => {
      if (this.restoredScrollPos != null) {
        window.scrollTo(0, this.restoredScrollPos);
        this.restoredScrollPos = null;
      }
    });
    this.isMobile =
      /Mobile/.test(navigator.userAgent) || window.innerWidth <= 600;
    this.paletteService.getPalettes().subscribe((data) => {
      this.palettes = data;
    });

    if (this._costsSubscription) {
      this._costsSubscription.unsubscribe();
      this._costsSubscription = null;
    }
    if (this._projectsSubscription) {
      this._projectsSubscription.unsubscribe();
      this._projectsSubscription = null;
    }
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
      this._routeSubscription = null;
    }
    if (this._routeQuerySubscription) {
      this._routeQuerySubscription.unsubscribe();
      this._routeQuerySubscription = null;
    }
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = null;
    }
    if (this._waterBottomSubscription) {
      this._waterBottomSubscription.unsubscribe();
      this._waterBottomSubscription = null;
    }

    if (this._rangeSubscription) {
      this._rangeSubscription.unsubscribe();
      this._rangeSubscription = null;
    }

    if (this._timeSliceSubscription) {
      this._timeSliceSubscription.unsubscribe();
      this._timeSliceSubscription = null;
    }

    this._synchoriseSubscription =
      this.storedSettingsService.synchroniseSettings.subscribe((val) => {
        this.synchroniseSettings = val;
        if (val) {
          if (
            this.minVal != null &&
            this.maxVal != null &&
            this.minValZ != null &&
            this.maxValZ != null
          ) {
            this.velocityAuto = false;
            this.storeRangeAndPaletteForCurrentType();
          }
        }
      });

    this._userSubscription = this.userService.userDetail.subscribe((d) => {
      this.isStaff = d && d.isStaff;
    });
    this.otherInit();
  }

  ngOnDestroy(): void {
    this.storeSettings();
    this.storeRangeAndPaletteForCurrentType();
    this.loadedJob = false;

    if (this._costsSubscription) {
      this._costsSubscription.unsubscribe();
      this._costsSubscription = null;
    }
    if (this._projectsSubscription) {
      this._projectsSubscription.unsubscribe();
      this._projectsSubscription = null;
    }
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
      this._routeSubscription = null;
    }
    if (this._routeQuerySubscription) {
      this._routeQuerySubscription.unsubscribe();
      this._routeQuerySubscription = null;
    }

    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = null;
    }
    if (this._waterBottomSubscription) {
      this._waterBottomSubscription.unsubscribe();
      this._waterBottomSubscription = null;
    }

    if (this._rangeSubscription) {
      this._rangeSubscription.unsubscribe();
      this._rangeSubscription = null;
    }

    if (this._timeSliceSubscription) {
      this._timeSliceSubscription.unsubscribe();
      this._timeSliceSubscription = null;
    }

    if (this._synchoriseSubscription) {
      this._synchoriseSubscription.unsubscribe();
      this._synchoriseSubscription = null;
    }

    if (this.time_to_scroll_subscription) {
      this.time_to_scroll_subscription.unsubscribe();
      this.time_to_scroll_subscription = null;
    }

    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    var offset = $("#slice-settings").offset();
    this.sliderBarVisible = offset && offset.top + 50 < $(document).scrollTop();
    this.scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }

  ngAfterViewInit(): void {}

  // ngAfterViewInit() {
  otherInit() {
    if (this._routeQuerySubscription) {
      this._routeQuerySubscription.unsubscribe();
      this._routeQuerySubscription = null;
    }
    this._routeQuerySubscription = this.route.queryParams.subscribe(
      (queryParams: Params) => {
        if (!this.currentJob) return;

        this.updateFromQueryParams(queryParams);
      }
    );

    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }
    this.currentJobSubscription = this.projectService.currentJob.subscribe(
      (d) => {
        if (this.currentJob) {
          this.storeSettings();
          this.storeRangeAndPaletteForCurrentType();
        }
        if (!d) return;
        this.clearModelInfo();
        this.currentJob = d;
        this.comments = d.comments;
        this.waitingFor = this.currentJob.modelGrid.is2d ? 1 : 3;

        this.max_inline = this.currentJob.x2_is_inline
          ? this.currentJob.modelGrid.nx1
          : this.currentJob.modelGrid.nx2;
        this.max_crossline = this.currentJob.x2_is_inline
          ? this.currentJob.modelGrid.nx2
          : this.currentJob.modelGrid.nx1;
        if (this.currentJob.modelGrid.is2d) {
          [this.max_inline, this.max_crossline] = [
            this.max_crossline,
            this.max_inline,
          ];
        }

        this.restoreSettings();

        if (this.previous_projectID == this.currentJob.projectId) {
          if (this.ILXLStartDepth == null) this.ILXLStartDepth = 0;
          if (this.ILXLEndDepth == null)
            this.ILXLEndDepth =
              (this.currentJob.modelGrid.nx3 - 1) *
              this.currentJob.modelGrid.dx;
          if (this.ILXLStartInline == null) this.ILXLStartInline = 0;
          if (this.ILXLEndInline == null)
            this.ILXLEndInline = Math.max(
              1,
              (this.max_inline - 1) * this.currentJob.modelGrid.dx
            );
          if (this.ILXLStartCrossline == null) this.ILXLStartCrossline = 0;
          if (this.ILXLEndCrossline == null)
            this.ILXLEndCrossline = Math.max(
              1,
              (this.max_crossline - 1) * this.currentJob.modelGrid.dx
            );
        } else {
          this.ILXLStartDepth = 0;
          this.ILXLEndDepth =
            (this.currentJob.modelGrid.nx3 - 1) * this.currentJob.modelGrid.dx;
          this.ILXLStartInline = 0;
          this.ILXLEndInline = Math.max(
            1,
            (this.max_inline - 1) * this.currentJob.modelGrid.dx
          );

          this.ILXLStartCrossline = 0;
          this.ILXLEndCrossline = Math.max(
            1,
            (this.max_crossline - 1) * this.currentJob.modelGrid.dx
          );
        }
        this.previous_projectID = this.currentJob.projectId;

        if (this._costsSubscription) {
          this._costsSubscription.unsubscribe();
          this._costsSubscription = null;
        }
        if (this._projectsSubscription) {
          this._projectsSubscription.unsubscribe();
          this._projectsSubscription = null;
        }

        if (this._shotOverlaySubscription) {
          this._shotOverlaySubscription.unsubscribe();
          this._shotOverlaySubscription = null;
        }

        if (this._timerSubscription) {
          this._timerSubscription.unsubscribe();
          this._timerSubscription = null;
        }
        if (this._waterBottomSubscription) {
          this._waterBottomSubscription.unsubscribe();
          this._waterBottomSubscription = null;
        }

        if (!this.minVal && !this.maxVal) {
          this.getDefaultRanges(this.currentModelTypeId);
        }

        this.shotOverlayTypes = null;
        this._shotOverlaySubscription = forkJoin([
          this.projectService.getShotOverlayTypes(this.currentJob.id),
          this.projectService.getFnlcRanges(this.currentJob.id),
        ])
          .pipe(take(1))
          .subscribe(
            (data) => {
              if (!data || !data[0]) return;
              let shotTypes = data[0];
              let fnlcType = shotTypes.find((t) => t.id == "fnlc");
              if (!data[1] && fnlcType) {
                shotTypes = shotTypes.filter((type) => type.id != "fnlc");
              } else if (data[1] && fnlcType) {
                let fnlcRanges = data[1];
                if (fnlcType) {
                  fnlcType.min = fnlcRanges.max_val;
                  fnlcType.max = fnlcRanges.min_val;
                }
              }
              this.shotOverlayTypes = shotTypes;
            },
            (err) => {
              if (this.iteration == 0) {
                this.shotOverlayTypes = null;
              }
              forkJoin([
                this.projectService.getShotOverlayTypes(
                  this.currentJob.id,
                  this.iteration
                ),
                this.projectService.getFnlcRanges(this.currentJob.id),
              ])
                .pipe(take(1))
                .subscribe(
                  (data) => {
                    if (!data || !data[0]) return;
                    let shotTypes = data[0];
                    let fnlcType = shotTypes.find((t) => t.id == "fnlc");

                    if (!data[1] && fnlcType) {
                      shotTypes = shotTypes.filter((type) => type.id != "fnlc");
                    } else if (data[1] && fnlcType) {
                      let fnlcRanges = data[1];
                      if (fnlcType) {
                        fnlcType.min = fnlcRanges.max_val;
                        fnlcType.max = fnlcRanges.min_val;
                      }
                    }
                    this.shotOverlayTypes = shotTypes;
                  },
                  (err) => {
                    this.shotOverlayTypes = null;
                  }
                );
            }
          );

        this._waterBottomSubscription = this.projectService
          .getHorizonTypes(this.currentJob.id)
          .pipe(take(1))
          .subscribe(
            (data) => {
              this.horizonTypes = data;
              if (data && data.length > 0) {
                this.showHorizonTypes = [data[0]];
                this.horizonColors = [getRandomHexColor()];
              } else {
                this.horizonTypes = [];
                this.showHorizonTypes = [];
                this.horizonColors = [];
                this.showHorizon = false;
              }
            },
            (err) => {
              console.log(err);
              this.horizonTypes = [];
              this.showHorizonTypes = [];
              this.horizonColors = [];
              this.showHorizon = false;
            }
          );

        this._timerSubscription = timer(0, 1000)
          .pipe(take(1))
          .subscribe((t) => {
            this.getElapsedTime();
          });

        this._costsSubscription = this.projectService
          .getCosts(d.id, d.iterations)
          .pipe(take(1))
          .subscribe((cst) => {
            this._costsSubscription = null;

            if (
              !cst ||
              !cst.costItems ||
              !cst.costItems.length ||
              cst.type === "simple_shot_geom"
            ) {
              this.epochs = null;
              this.costPerIteration = null;
              this.hasCosts = false;
              return;
            }
            this.hasCosts = true;
            var batches = cst.totalShots / cst.shotsPerIteration;

            this.epochs = Math.round(
              cst.costItems
                .map((c) => c.iterations)
                .reduce((a, b) => a + b, 0) / cst.costItems.length
            );
            this.costPerIteration =
              cst.costItems.map((c) => c.cost).reduce((a, b) => a + b, 0) /
              this.epochs /
              batches;
          });

        this.ranges = {
          x: [0, this.currentJob.modelGrid.nx2],
          y: [0, this.currentJob.modelGrid.nx1],
          z: [0, this.currentJob.modelGrid.nx3],
        };

        if (this._timeSliceSubscription) {
          this._timeSliceSubscription.unsubscribe();
          this._timeSliceSubscription = null;
        }

        this.getSliceWidths();
        this.getSliceHeights();
        this.refreshChart = !this.refreshChart;

        if (!this.loadedJob)
          this.updateFromQueryParams(this.route.snapshot.queryParams);

        if (this.currentJob.modelGrid.is2d) {
          if (this.velocityProfileCrossline == null)
            this.velocityProfileCrossline = Math.round(
              this.currentJob.modelGrid.nx2 / 2
            );
        }
        this.vpHeight =
          this.currentJob.modelGrid.is2d || this.vpPositionSwapped
            ? this.height
            : this.depthHeight;
        this.inputHeight = this.height;
        this.inputDepthHeight = this.depthHeight;
        this.getVerticalProfile();
        this.getFunctionalSequence();
      }
    );
  }

  updateFromQueryParams(queryParams: Params) {
    if (!!queryParams["iteration"] || queryParams["iteration"] == 0) {
      this.iteration = parseInt(queryParams["iteration"]) || 0;
    }

    if (!!queryParams["timeSlice"] || queryParams["timeSlice"]) {
      this.timeSlice = parseInt(queryParams["timeSlice"]) || 0;
    }

    if (!!queryParams["shot"] || queryParams["shot"]) {
      this.shotId = parseInt(queryParams["shot"]) || 0;
    }

    if (!!queryParams["rules"]) this._rules = queryParams["rules"];

    if (!!queryParams["searchId"]) this._searchId = queryParams["searchId"];

    if (!!queryParams["comment"]) this._comment = queryParams["comment"];

    if (!!queryParams["name"]) this._name = queryParams["name"];

    if (!!queryParams["jobs"]) {
      this.selectedJobs = queryParams["jobs"];
    }

    if (this.isValidSlice(queryParams["inline"], 1)) {
      console.warn(
        `INLINE NUMBER IS UPDATED TO ${queryParams["inline"]} FROM QUERY PARAMS`
      );
      this.inlineNumber = parseInt(queryParams["inline"]);
    }

    if (this.isValidSlice(queryParams["crossline"], 2)) {
      console.warn(
        `CROSSLINE NUMBER IS UPDATED TO ${queryParams["crossline"]} FROM QUERY PARAMS`
      );
      this.crosslineNumber = parseInt(queryParams["crossline"]);
    }

    if (this.isValidSlice(queryParams["depth"], 3)) {
      console.warn(
        `DEPTH NUMBER IS UPDATED TO ${queryParams["depth"]} FROM QUERY PARAMS`
      );
      this.depthSlice = parseInt(queryParams["depth"]);
    }

    if (
      this.isValidModelType(queryParams["type"]) &&
      this.currentModelTypeId != queryParams["type"]
    ) {
      this.storeRangeAndPaletteForCurrentType();
      this.currentModelTypeId = queryParams["type"];
      // console.error (`MODEL TYPE IS UPDATED TO ${queryParams["type"]} FROM QUERY PARAMS`)
      if (this.currentModelTypeId == null) {
        this.currentModelTypeId = "Vp";
      }
      this.restoreRangeAndPaletteForCurrentType();
    }

    this.currentModelTypeProcessing =
      allSliceProcessings[this.currentModelTypeId];

    if (
      queryParams["minVal"] ||
      queryParams["maxVal"] ||
      queryParams["minValZ"] ||
      queryParams["maxValZ"]
    ) {
      if (this.currentModelTypeId != "Vp") {
        this.minVal = queryParams["minVal"];
        this.maxVal = queryParams["maxVal"];
        this.minValZ = queryParams["minValZ"];
        this.maxValZ = queryParams["maxValZ"];
        this.velocityAuto = false;
      } else {
        let minVal = Math.max(queryParams["minVal"], vpLowerBound);
        let maxVal = Math.min(queryParams["maxVal"], vpUpperBound);
        let minValZ = Math.max(queryParams["minValZ"], vpLowerBound);
        let maxValZ = Math.min(queryParams["maxValZ"], vpUpperBound);
        this.minVal = Math.min(minVal, maxVal);
        this.maxVal = Math.max(minVal, maxVal);
        this.minValZ = Math.min(minValZ, maxValZ);
        this.maxValZ = Math.max(minValZ, maxValZ);
      }
    }
    this.currentJob.last_used_palette = this._paletteOfCurrentType;

    this.validateIterations();
    this.updateModelTypeList();
    this.onSliderChanged();

    this.loadedJob = true;
    if (this.currentModelTypeId == null) {
      console.error("WHILE UPDATING FROM QUERY PARAMS TYPE WAS NULL");
      // this.currentModelTypeId = "Vp";
      this.setModelType({ id: "Vp", name: "Vp", processing: [] }, null);
    }
  }

  updateModelTypeList() {
    if (this._modelTypesSubscription) {
      this._modelTypesSubscription.unsubscribe();
      this._modelTypesSubscription = null;
    }

    if (this.iteration !== 0) {
      this._modelTypesSubscription = forkJoin([
        this.projectService.getModelTypes(this.currentJob.id, this.iteration),
        this.projectService.getModelTypes(this.currentJob.id, 0),
      ])
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.modelTypes = data[0];
            if (
              data[1].map((d) => d.id).includes("Delta") &&
              !this.modelTypes.map((m) => m.id).includes("Delta")
            )
              this.modelTypes.push({ id: "Delta", name: "Delta" });
            if (
              data[1].map((d) => d.id).includes("Epsilon") &&
              !this.modelTypes.map((m) => m.id).includes("Epsilon")
            )
              this.modelTypes.push({ id: "Epsilon", name: "Epsilon" });

            if (
              !this.modelTypes.some(
                (modelType) => modelType.id == this.currentModelTypeId
              )
            ) {
              console.error(
                "CURRENT MODELTYPE DOESN't EXIST IN MODELTYPE LIST"
              );
              // this.setModelType({id:"Vp",name:"Vp"}, null);
            }
          },
          (err) => {
            // console.log(err)
            this.modelTypes = [{ id: "Vp", name: "Vp" }];
            if (
              !this.modelTypes.some(
                (modelType) => modelType.id == this.currentModelTypeId
              )
            ) {
              // this.setModelType({id:"Vp",name:"Vp"}, null);
              console.error(
                "SOMETHING WENT WRONG WHILE GETTING THE MODELTYPE LIST"
              );
            }
          }
        );
    } else {
      this._modelTypesSubscription = this.projectService
        .getModelTypes(this.currentJob.id, this.iteration)
        .pipe(take(1))
        .subscribe(
          (data) => {
            this.modelTypes = data;
            if (this.currentJob.wellLogs && this.currentJob.wellLogs.length)
              this.modelTypes = this.modelTypes.concat([
                { id: "Gradient", name: "Gradient" },
                { id: "Total_Vp_Update", name: "Total_Vp_Update" },
              ]);

            if (
              !this.modelTypes.some(
                (modelType) => modelType.id == this.currentModelTypeId
              )
            ) {
              // console.error("CURRENT MODELTYPE DOESN't EXIST IN MODELTYPE LIST")
              // this.setModelType({id:"Vp",name:"Vp"}, null);
            }
          },
          (err) => {
            // console.log(err)
            this.modelTypes = [{ id: "Vp", name: "Vp" }];
            if (
              !this.modelTypes.some(
                (modelType) => modelType.id == this.currentModelTypeId
              )
            ) {
              // this.setModelType({id:"Vp",name:"Vp"}, null);
              // console.error("SOMETHING WENT WRONG WHILE GETTING THE MODELTYPE LIST")
            }
          }
        );
    }
  }

  getElapsedTime() {
    if (this.currentJob.runTime == null || !this.currentJob.startTime) {
      return;
    }

    var runTime =
      this.currentJob.runTime +
      Math.floor(
        Math.abs(this.currentJob.startTime.getTime() - new Date().getTime()) /
          1000
      );

    if (runTime < 60) this.elapsedTime = runTime + "s";
    else if (runTime < 60 * 60)
      this.elapsedTime = Math.floor(runTime / 60) + "m " + (runTime % 60) + "s";
    else {
      var hours = Math.floor(runTime / (60 * 60));
      var minutes = Math.floor(runTime / 60) - hours * 60;
      this.elapsedTime = `${hours}h ${minutes}m`;
    }
  }

  getWellName(sonicId) {
    let wellLog = this.currentJob.wellLogs.find((l) => l.id == sonicId);
    if (!wellLog || !this.showSonicLog) return "";
    return wellLog.name;
  }

  isValidSlice(num: number, index: number): boolean {
    if (!num) return false;
    if (num < 0) return false;

    if (!this.currentJob) return true;

    let val: number;
    if (index === 1) val = this.currentJob.modelGrid.nx1;
    else if (index === 2) val = this.currentJob.modelGrid.nx2;
    else val = this.currentJob.modelGrid.nx3;

    if (num > val) return false;

    return true;
  }

  isValidModelType(type: string): boolean {
    if (!type) return false;

    return !!this.modelTypes.find((m) => m.id == type);
  }

  onSonicLogClicked() {
    this.updateChart = true;
    setTimeout(() => {
      this.updateChart = false;
    }, 200);
  }

  onColorChanged(color) {
    this.onSonicLogClicked();
  }

  setLog(id) {
    var log = this.currentJob.wellLogs.find((d) => d.id == id);
    if (!log) return;

    this.selectedSonicId = id;
    this.inlineNumber = log.inline;
    this.crosslineNumber = log.crossline;
    this.showSonicLog = true;
    this.onSonicLogClicked();
    this.onSliderChanged();
  }

  setHorizon(id) {
    var type = this.showHorizonTypes.find((d) => d == id);
    if (type) {
      let i = this.showHorizonTypes.indexOf(type);
      this.showHorizonTypes.splice(i, 1);
      this.horizonColors.splice(i, 1);
    } else {
      this.showHorizon = true;
      this.showHorizonTypes.push(id);
      this.horizonColors.push(getRandomHexColor());
    }
    this.onSonicLogClicked();
  }

  isHorizonSelected(id) {
    return this.showHorizonTypes.find((d) => d == id);
  }

  onSliderChanged(iteration?) {
    let newParams = {};
    if (iteration != null) {
      if (iteration > this.currentJob.iterationsComplete) {
        return;
      }
      newParams["iteration"] = this.iteration;
    } else {
      newParams["iteration"] = this.iteration;
    }
    newParams["inline"] = this.inlineNumber;
    newParams["crossline"] = this.crosslineNumber;
    newParams["depth"] = this.depthSlice;
    newParams["type"] = this.currentModelTypeId;
    if (this.minVal != null && isNaN(this.minVal)) {
      this.minVal = null;
    }
    if (this.maxVal != null && isNaN(this.maxVal)) {
      this.maxVal = null;
    }
    newParams["minVal"] = this.minVal;
    newParams["maxVal"] = this.maxVal;
    if (this.minValZ != null && isNaN(this.minValZ)) {
      this.minValZ = null;
    }
    if (this.maxValZ != null && isNaN(this.maxValZ)) {
      this.maxValZ = null;
    }
    newParams["minValZ"] = this.minValZ;
    newParams["maxValZ"] = this.maxValZ;
    if (
      this.currentModelTypeId == "Gradient" ||
      this.currentModelTypeId == "InvVp Update"
    ) {
      if (this.storedMinMax != null) {
        newParams["minVal"] = this.storedMinMax["minVal"];
        newParams["maxVal"] = this.storedMinMax["maxVal"];
        newParams["minValZ"] = this.storedMinMax["minValZ"];
        newParams["maxValZ"] = this.storedMinMax["maxValZ"];
      }
    }

    newParams["jobs"] = this.selectedJobs;
    newParams["rules"] = this._rules;
    newParams["searchId"] = this._searchId;
    newParams["comment"] = this._comment;
    newParams["name"] = this._name;
    newParams["timeSlice"] = this.timeSlice;
    newParams["shot"] = this.shotId;
    this.getAvailableTimeSlices(this.currentModelTypeId);
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: newParams,
      replaceUrl: true,
    });
    this.getVerticalProfile();
  }

  onSynchroniseChange(value) {
    this.storedSettingsService.setSynchroniseSettings(value);
    this.storeSettings();
    this.storeRangeAndPaletteForCurrentType();
  }

  // Wrapper for keyboard event
  onKeySubmission(event: KeyboardEvent) {
    if (event.key == "Enter" && this.vpUpdateCheck) {
      this.onSonicLogClicked();
    }
  }

  onKeySubmissionShotOverlayType(event: KeyboardEvent) {
    if (event.key == "Enter") {
      let min = this.currentShotOverlayType.min;
      let max = this.currentShotOverlayType.max;
      if (min == null || isNaN(min) || min < 0) {
        min = 0;
      }
      if (max == null || isNaN(max) || max > 100) {
        max = 100;
      }
      this.currentShotOverlayType.min = min;
      this.currentShotOverlayType.max = max;
      this.currentShotOverlayType = Object.assign(
        {},
        this.currentShotOverlayType,
        {}
      ); // updates reference
    }
  }
  resetRanges() {
    this.ILXLStartInline = 0;
    this.ILXLStartCrossline = 0;
    this.ILXLStartDepth = 0;
    this.ILXLEndInline = Math.max(
      1,
      (this.max_inline - 1) * this.currentJob.modelGrid.dx
    );
    this.ILXLEndCrossline = Math.max(
      1,
      (this.max_crossline - 1) * this.currentJob.modelGrid.dx
    );
    this.ILXLEndDepth =
      (this.currentJob.modelGrid.nx3 - 1) * this.currentJob.modelGrid.dx;
    this.onSonicLogClicked();
  }

  submitSliceRanges(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onKeySubmissionDepthRange();
      this.onKeySubmissionCrosslineRange();
      this.onKeySubmissionInlineRange();
      this.onSonicLogClicked();
    }
  }
  onKeySubmissionDepthRange() {
    if (!this.ILXLStartDepth || this.ILXLStartDepth < 0) {
      this.ILXLStartDepth = 0;
    }
    if (
      this.ILXLStartDepth >
      (this.currentJob.modelGrid.nx3 - 1) * this.currentJob.modelGrid.dx - 500
    ) {
      this.ILXLStartDepth =
        (this.currentJob.modelGrid.nx3 - 1) * this.currentJob.modelGrid.dx -
        500;
    }
    if (
      !this.ILXLEndDepth ||
      this.ILXLEndDepth >
        (this.currentJob.modelGrid.nx3 - 1) * this.currentJob.modelGrid.dx
    ) {
      this.ILXLEndDepth =
        (this.currentJob.modelGrid.nx3 - 1) * this.currentJob.modelGrid.dx;
    }
    if (this.ILXLEndDepth < this.ILXLStartDepth) {
      this.ILXLEndDepth = this.ILXLStartDepth + 500;
    }
    // console.log("depth set to:",this.ILXLStartDepth,this.ILXLEndDepth)
  }

  onKeySubmissionInlineRange() {
    if (this.ILXLStartInline == null || this.ILXLStartInline < 0) {
      this.ILXLStartInline = 0;
    }
    if (
      this.ILXLEndInline == null ||
      this.ILXLEndInline > (this.max_inline - 1) * this.currentJob.modelGrid.dx
    ) {
      this.ILXLEndInline = (this.max_inline - 1) * this.currentJob.modelGrid.dx;
    }
    if (this.ILXLEndInline < this.ILXLStartInline) {
      this.ILXLEndInline = Math.min(
        (this.max_inline - 1) * this.currentJob.modelGrid.dx,
        this.ILXLStartInline + 500
      );
    }
    this.ILXLEndInline = Math.max(1, this.ILXLEndInline);
    console.log(
      "ilxl-inline start/end set to:",
      this.ILXLStartInline,
      this.ILXLEndInline
    );
  }

  onKeySubmissionCrosslineRange() {
    if (this.ILXLStartCrossline == null || this.ILXLStartCrossline < 0) {
      this.ILXLStartCrossline = 0;
    }
    if (
      this.ILXLEndCrossline == null ||
      this.ILXLEndCrossline >
        (this.max_crossline - 1) * this.currentJob.modelGrid.dx
    ) {
      this.ILXLEndCrossline =
        (this.max_crossline - 1) * this.currentJob.modelGrid.dx;
    }
    if (this.ILXLEndCrossline < this.ILXLStartCrossline) {
      this.ILXLEndCrossline = Math.min(
        (this.max_crossline - 1) * this.currentJob.modelGrid.dx,
        this.ILXLStartCrossline + 500
      );
    }
    this.ILXLEndCrossline = Math.max(1, this.ILXLEndCrossline);
  }

  showShowLogId() {
    if (
      this.selectedSonicId &&
      this.selectedSonicId.inline == this.inlineNumber &&
      this.selectedSonicId.crossline == this.crosslineNumber
    ) {
      return this.selectedSonicId;
    }
    if (
      !this.currentJob.wellLogs ||
      !this.showSonicLog ||
      this.currentJob.wellLogs.length == 0
    )
      return null;
    let log = this.currentJob.wellLogs.find(
      (l) => l.id == this.selectedSonicId
    );
    if (
      log &&
      log.inline == this.inlineNumber &&
      log.crossline == this.crosslineNumber
    ) {
      this.projectService.getSonicLog(this.selectedSonicId).subscribe((log) => {
        // console.log("GOT LOG")
        // console.log(log)
      });
      return this.selectedSonicId;
    }
    this.showSonicLog = false;
    this.selectedSonicId = null;
    return false;
  }

  getSonicNXForSliceType(type: SliceType) {
    let log = this.currentJob.wellLogs.find(
      (l) => l.id == this.selectedSonicId
    );
    if (!log) return null;

    switch (type) {
      case SliceType.inline: {
        return log.crossline;
      }
      case SliceType.crossLine: {
        return log.inline;
      }
      case SliceType.depth: {
        let locs = this.currentJob.wellLogs.map((w) => [
          w.crossline,
          w.inline,
          w.id == log.id,
        ]);
        return locs;
      }
    }
    return null;
  }

  getSonicWidthForSliceType(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return 60;
      case SliceType.crossLine:
        return (
          60 *
          (this.currentJob.modelGrid.nx1 / this.currentJob.modelGrid.nx2 < 0.2
            ? 0.8
            : 1)
        );
    }
  }

  getWidthForSliceType(type: SliceType) {
    const containerWidth = document
      .getElementsByClassName("chart-slice-container")[0]
      .getBoundingClientRect().width;
    // var inline = 140 + ((window.innerWidth - 400) * this.inlineWidth) / 100;
    var inline = 140 + ((containerWidth - 400) * this.inlineWidth) / 100;
    var crossline = $("#slice-settings").width() - inline - 10;

    // console.log("widths", inline, crossline, this.inlineWidth, containerWidth);

    switch (type) {
      case SliceType.inline:
        return this.inlineWidth !== 100
          ? this.sanitizer.bypassSecurityTrustStyle(inline + "px")
          : "100%";
      case SliceType.crossLine:
        return this.crosslineWidth !== 100
          ? this.sanitizer.bypassSecurityTrustStyle(crossline + "px")
          : "100%";
      case SliceType.depth:
        return this.inlineWidth !== 100
          ? this.sanitizer.bypassSecurityTrustStyle(inline + "px")
          : "100%";
      case null: // for velocity profile
        let is2d = this.currentJob.modelGrid.is2d;
        return this.crosslineWidth !== 100
          ? this.sanitizer.bypassSecurityTrustStyle(crossline / 2 + "px")
          : is2d
          ? "calc(25% - 30px)"
          : "calc(50% - 30px)";
    }
  }

  getSliceWidths() {
    if (
      window.innerWidth < 992 ||
      !this.currentJob ||
      this.currentJob.modelGrid.is2d
    ) {
      this.inlineWidth = 100;
      this.crosslineWidth = 100;
      return;
    }
    var inline = Math.round(
      (100 * this.currentJob.modelGrid.nx2) /
        (this.currentJob.modelGrid.nx1 + this.currentJob.modelGrid.nx2)
    );
    if (this.currentJob.modelGrid.nx1 / this.currentJob.modelGrid.nx2 < 0.2) {
      inline = inline * 0.97;
      //if (this.showSonicLog) {
      //  inline = inline * 0.95;
      //}
    }

    this.inlineWidth = inline;
    this.crosslineWidth = 100 - inline;
  }

  getShotOverlay(type: SliceType) {
    if (type == SliceType.depth) return this.showShotOverlay;

    return false;
  }

  getHorizons(type: SliceType) {
    if (
      type != SliceType.depth &&
      this.showHorizon &&
      this.horizonTypes &&
      this.showHorizonTypes.length > 0
    )
      return this.showHorizonTypes;
    return null;
  }

  getTimeSliceInterval() {
    if (!this.availTimeSlices || this.availTimeSlices.length <= 1) {
      return 0;
    }

    return this.availTimeSlices[1] - this.availTimeSlices[0];
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.getSliceWidths();
    this.getSliceHeights();
  }

  @HostListener("window:beforeunload", ["$event"])
  onbeforeunload(event) {
    this.storeSettings();
    this.storeRangeAndPaletteForCurrentType();
  }

  setShotOverlayType(shotOverlayType: ShotOverlayType) {
    this.currentShotOverlayType = shotOverlayType;
    if (this.currentShotOverlayType.id === "fnlc") {
      this.currentShotOverlayType.max = 0;
      this.currentShotOverlayType.min = 100;
    }
    this.showShotOverlay = true;
  }

  setModelType(modelType: ModelType, event) {
    if (event != null) {
      event.stopPropagation();
    }
    if (this.currentModelTypeId == modelType.id) return;
    this.storeRangeAndPaletteForCurrentType();
    this.currentModelTypeId = modelType.id;
    this.currentModelTypeProcessing = modelType.processing;
    this.restoreRangeAndPaletteForCurrentType();
    this.onSliderChanged();
  }

  setPalette(palette: string) {
    this.currentJob.last_used_palette = palette;
    this._paletteOfCurrentType = palette;
    this.storeRangeAndPaletteForCurrentType();
  }

  setTimeSlice(timeSlice) {
    this.timeSlice = timeSlice;
    this.onSliderChanged();
  }

  setShot(shot) {
    this.shotId = shot;
    this.onSliderChanged();
  }

  getSliceHeights() {
    if (this.maxDepth != 0) return;

    if (
      window.innerWidth < 992 ||
      !this.currentJob ||
      this.currentJob.modelGrid.is2d
    ) {
      if (!this.currentJob) {
        this.depthHeight = 250;
        return;
      }

      if (this.depthHeight != null) return;
      this.depthHeight = Math.round(
        80 +
          (this.currentJob.modelGrid.nx1 / this.currentJob.modelGrid.nx2) *
            (window.innerWidth - 320)
      );
      if (this.depthHeight < 200) this.depthHeight = 200;
      return;
    }
    var sliceWidth = $("#slice-settings").width();
    if (!sliceWidth) {
      sliceWidth = window.innerWidth - 180;
    }
    var inline = Math.round(
      110 + ((window.innerWidth - 400) * this.inlineWidth) / 100
    );
    var crossline = sliceWidth - inline + 30;

    if (this.depthHeight == null) this.depthHeight = crossline - 100;
    if (this.depthHeight < 200) this.depthHeight = 200;

    this.maxDepth = Math.max(650, this.depthHeight);
  }

  getAdjustedSliceType(type: SliceType) {
    if (!this.currentJob || this.currentJob.modelGrid.is2d) return type;

    switch (type) {
      case SliceType.inline:
        return this.currentJob.x2_is_inline
          ? SliceType.inline
          : SliceType.crossLine;
      case SliceType.crossLine:
        return this.currentJob.x2_is_inline
          ? SliceType.crossLine
          : SliceType.inline;
      case SliceType.depth:
        return SliceType.depth;
    }

    return type;
  }

  getTitleForSliceType(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return "Inline " + (!!this.inlineNumber ? this.inlineNumber : "");
      case SliceType.crossLine:
        return "Crossline " + this.crosslineNumber;
      case SliceType.depth:
        return "Depth " + this.depthSlice;
    }
    return "";
  }

  getHoverLabelX(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return "XL";
      case SliceType.crossLine:
        return "IL";
      case SliceType.depth:
        return "XL";
    }
  }

  getHoverLabelY(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return "Depth";
      case SliceType.crossLine:
        return "Depth";
      case SliceType.depth:
        return "IL";
    }
  }

  getRangesForSliceType(type: SliceType): Ranges {
    switch (type) {
      case SliceType.inline:
        return {
          x: [0, this.currentJob.modelGrid.nx2 * this.currentJob.modelGrid.dx],
          y: [this.currentJob.modelGrid.nx3 * this.currentJob.modelGrid.dx, 0],
        };
      case SliceType.crossLine:
        return {
          x: [0, this.currentJob.modelGrid.nx1 * this.currentJob.modelGrid.dx],
          y: [this.currentJob.modelGrid.nx3 * this.currentJob.modelGrid.dx, 0],
        };
      case SliceType.depth:
        return {
          x: [0, this.currentJob.modelGrid.nx2 * this.currentJob.modelGrid.dx],
          y: [0, this.currentJob.modelGrid.nx1 * this.currentJob.modelGrid.dx],
        };
    }
    return null;
  }

  getXStepForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.currentJob.modelGrid.nx3;
      case SliceType.crossLine:
        return this.currentJob.modelGrid.nx3;
      case SliceType.depth:
        return this.currentJob.modelGrid.nx1;
    }
  }

  getYStepForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.currentJob.modelGrid.nx2;
      case SliceType.crossLine:
        return this.currentJob.modelGrid.nx1;
      case SliceType.depth:
        return this.currentJob.modelGrid.nx2;
    }
  }

  getNumberForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.inlineNumber;
      case SliceType.crossLine:
        return this.crosslineNumber;
      case SliceType.depth:
        return this.depthSlice;
    }
    return 0;
  }

  getUnit(): string {
    switch (this.currentJob.modelGrid.unit) {
      case "M":
        return "km";
      case "I":
        return "ft";
    }
    return "";
  }

  getXTitleForSliceType(type: SliceType): string {
    switch (type) {
      case SliceType.inline:
        return "crossline (" + this.getUnit() + ")";
      case SliceType.crossLine:
        return "inline (" + this.getUnit() + ")";
      case SliceType.depth:
        return "crossline (" + this.getUnit() + ")";
    }
    return "";
  }

  getYTitleForSliceType(type: SliceType): string {
    switch (type) {
      case SliceType.inline:
        return "depth (" + this.getUnit() + ")";
      case SliceType.crossLine:
        return "depth (" + this.getUnit() + ")";
      case SliceType.depth:
        return "inline (" + this.getUnit() + ")";
    }
    return "";
  }

  getXForSliceType(type: SliceType): number {
    if (this.currentJob.modelGrid.is2d) {
      // return null;
      return this.velocityProfileCrossline * this.currentJob.modelGrid.dx;
    }

    var range = this.getRangesForSliceType(type);
    switch (type) {
      case SliceType.inline:
        return (
          this.crosslineNumber * this.currentJob.modelGrid.dx -
          this.currentJob.modelGrid.dx / 2
        );
      case SliceType.crossLine:
        return (
          this.inlineNumber * this.currentJob.modelGrid.dx -
          this.currentJob.modelGrid.dx / 2
        );
      case SliceType.depth:
        return (
          this.crosslineNumber * this.currentJob.modelGrid.dx -
          this.currentJob.modelGrid.dx / 2
        );
    }
    return null;
  }

  // slice numbers begin from 1
  getYForSliceType(type: SliceType): number {
    if (this.currentJob.modelGrid.is2d) {
      // return null;
      return (
        this.depthSlice * this.currentJob.modelGrid.dx -
        this.currentJob.modelGrid.dx / 2
      );
    }

    var range = this.getRangesForSliceType(type);
    switch (type) {
      case SliceType.inline:
        return (
          this.depthSlice * this.currentJob.modelGrid.dx -
          this.currentJob.modelGrid.dx / 2
        );
      case SliceType.crossLine:
        return (
          this.depthSlice * this.currentJob.modelGrid.dx -
          this.currentJob.modelGrid.dx / 2
        );
      case SliceType.depth:
        return (
          this.inlineNumber * this.currentJob.modelGrid.dx -
          this.currentJob.modelGrid.dx / 2
        );
    }
    return null;
  }

  getCurrentCost(): number {
    return 20 * this.iteration;
  }

  validateIterations() {
    if (!this.currentJob) return;

    if (!this.currentJob.modelGrid.is2d) {
      if (!this.isValidSlice(this.inlineNumber, 1)) {
        this.inlineNumber = this.currentJob.defaultNx1;
        console.warn(
          `INLINE NUMBER WAS NOT VALID, SET TO ${this.currentJob.defaultNx1} as default`
        );
      }

      if (!this.isValidSlice(this.crosslineNumber, 2)) {
        this.crosslineNumber = this.currentJob.defaultNx2;
        console.warn(
          `CROSSLINE NUMBER WAS NOT VALID, SET TO ${this.currentJob.defaultNx2} as default`
        );
      }

      if (!this.isValidSlice(this.depthSlice, 3)) {
        this.depthSlice = this.currentJob.defaultNx3;
        console.warn(
          `DEPTH NUMBER WAS NOT VALID, SET TO ${this.currentJob.defaultNx3} as default`
        );
      }
    }

    if (!this.iteration && this.iteration != 0) {
      this.iteration = 0;
    }
    if (this.iteration < 0) this.iteration = 0;
    else if (this.iteration > this.currentJob.iterationsComplete)
      this.iteration = this.currentJob.iterationsComplete;
  }

  expandChart(slice: Slice) {
    this.isLoading = true;
    let array = this.slices.filter((t) => t.sliceType !== slice.sliceType);
    array.unshift(slice);
    this.slices = array;

    setTimeout(() => {
      this.updateChart = true;

      setTimeout(() => {
        this.updateChart = false;
        this.isLoading = false;
      }, 200);
    }, 500);
  }

  getCurrentVeloctiyRange(type: SliceType): number[] {
    switch (type) {
      case SliceType.inline:
        return this.velocityAuto ? [null, null] : [this.minVal, this.maxVal];
      case SliceType.crossLine:
        return this.velocityAuto ? [null, null] : [this.minVal, this.maxVal];
      case SliceType.depth:
        return this.velocityAuto ? [null, null] : [this.minValZ, this.maxValZ];
    }
  }

  showMinMaxDialog(type: SliceType) {
    // this.restoreRangeAndPaletteForCurrentType();
    let dialogRef = this.dialog.open(VelocityComponent, {
      // height: "350px",
      width: "400px",
      data: {
        singleValue: false,
        min: type == SliceType.depth ? this.minValZ : this.minVal,
        max: type == SliceType.depth ? this.maxValZ : this.maxVal,
        auto: this.velocityAuto,
        slice_processing: this.currentModelTypeProcessing,
        // syncRange: this.syncRangeBetweenSlices,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: MinMaxDialog) => {
        if (!result) return;
        // this.syncRangeBetweenSlices = result.syncRange;
        // if (this.syncRangeBetweenSlices) {
        //   this.minVal = result.min;
        //   this.maxVal = result.max;
        //   this.minValZ = result.min;
        //   this.maxValZ = result.max;
        //   this.onSonicLogClicked();
        // }
        this.velocityAuto = result.auto;
        if ((!result.min && !result.max) || result.auto) {
          if (
            this.currentModelTypeId == "Gradient" ||
            this.currentModelTypeId == "InvVp Update"
          ) {
            this.storedMinMax = null;
          }
          this.getDefaultRanges(this.currentModelTypeId, type)
            .pipe(take(1))
            .subscribe((result) => {
              this.updateChart = true;
              this.onSliderChanged();
              setTimeout(() => {
                this.updateChart = false;
              }, 200);
            });
        } else {
          if (this.currentModelTypeId == "Vp") {
            if (result.min < vpLowerBound) {
              result.min = vpLowerBound;
            }
            if (result.max > vpUpperBound) {
              result.max = vpUpperBound;
            }
          }

          if (type == SliceType.depth) {
            this.minValZ = result.min;
            this.maxValZ = result.max;
            if (
              this.currentModelTypeId == "Gradient" ||
              this.currentModelTypeId == "InvVp Update"
            ) {
              if (this.storedMinMax == null) {
                this.storedMinMax = {};
              }
              this.storedMinMax["minValZ"] = this.minValZ;
              this.storedMinMax["maxValZ"] = this.maxValZ;
            }
            this.storeRangeAndPaletteForCurrentType();
          } else {
            this.minVal = result.min;
            this.maxVal = result.max;
            if (
              this.currentModelTypeId == "Gradient" ||
              this.currentModelTypeId == "InvVp Update"
            ) {
              if (this.storedMinMax == null) {
                this.storedMinMax = {};
              }
              this.storedMinMax["minVal"] = this.minVal;
              this.storedMinMax["maxVal"] = this.maxVal;
            }
            this.storeRangeAndPaletteForCurrentType();
          }
          this.updateChart = true;
          setTimeout(() => {
            this.updateChart = false;
          }, 200);
          this.onSliderChanged();
        }
      });
  }

  onScaleChange(type) {
    if (
      (type == SliceType.depth && !this.minValZ && !this.maxValZ) ||
      (!this.minVal && !this.maxVal)
    )
      this.getDefaultRanges(this.currentModelTypeId, type);
    this.storeRangeAndPaletteForCurrentType();
    this.updateChart = true;
    setTimeout(() => {
      this.updateChart = false;
    }, 200);
    this.onSliderChanged();
  }

  private getDefaultRanges(modelTypeId, sliceType?: SliceType) {
    var subject = new Subject();
    var obs = subject.asObservable();
    if (
      this.iteration == null ||
      (!this.inlineNumber &&
        !this.crosslineNumber &&
        !this.currentJob.modelGrid.is2d)
    ) {
      subject.next();
      return;
    }

    if (this.timeSliceModels.includes(modelTypeId) && !this.timeSlice) {
      subject.next();
      return;
    }

    if (this._rangeSubscription) {
      this._rangeSubscription.unsubscribe();
      this._rangeSubscription = null;
    }
    let sliceParams = {};

    if (sliceType && sliceType == SliceType.depth) {
      sliceParams["depth"] = this.depthSlice - 1;
    } else {
      sliceParams["inline"] = this.inlineNumber - 1;
      sliceParams["crossline"] = this.crosslineNumber - 1;
    }

    this._rangeSubscription = this.projectService
      .getRanges(
        this.currentJob.id,
        modelTypeId,
        this.iteration,
        sliceParams,
        this.shotId,
        this.timeSlice
      )
      .pipe(take(1))
      .subscribe((ranges) => {
        if (sliceType && sliceType == SliceType.depth) {
          this.minValZ = ranges.min_val;
          this.maxValZ = ranges.max_val;
        } else {
          this.minVal = ranges.min_val;
          this.maxVal = ranges.max_val;
        }
        this.storeRangeAndPaletteForCurrentType();
        this.updateChart = true;
        setTimeout(() => {
          this.updateChart = false;
        }, 200);
        this.onSliderChanged();
        subject.next(ranges);
        return ranges;
      });
    return obs;
  }

  private restoreSettings() {
    let modelViewSettings =
      this.storedSettingsService.getSettings<ModelViewSettings>(
        this.settingsKey,
        this.currentJob.projectId,
        SettingTypes.ModelView
      );
    if (modelViewSettings && modelViewSettings.height) {
      this.height = modelViewSettings.height;
    }
    if (modelViewSettings && modelViewSettings.depthHeight) {
      this.depthHeight = modelViewSettings.depthHeight;
    }

    // if (modelViewSettings && modelViewSettings.ILXLStartDepth) {
    //   if (this.ILXLStartDepth == null || this.ILXLStartDepth == 0) {
    //     this.ILXLStartDepth = Math.min (modelViewSettings.ILXLStartDepth, this.currentJob.modelGrid.nx3 * this.currentJob.modelGrid.dx);
    //   }
    // }
    // if (modelViewSettings && modelViewSettings.ILXLEndDepth) {
    //   if (this.ILXLEndDepth == null || this.ILXLEndDepth == this.currentJob.modelGrid.nx3 * this.currentJob.modelGrid.dx) {
    //     this.ILXLEndDepth = Math.min (modelViewSettings.ILXLEndDepth, this.currentJob.modelGrid.nx3 * this.currentJob.modelGrid.dx);
    //   }
    // }

    // if (modelViewSettings && modelViewSettings.ILXLStartInline) {
    //   if (this.ILXLStartInline == null || this.ILXLStartInline == 0) {
    //     this.ILXLStartInline = Math.min (modelViewSettings.ILXLStartInline, this.currentJob.modelGrid.nx1 * this.currentJob.modelGrid.dx);
    //   }
    // }
    // if (modelViewSettings && modelViewSettings.ILXLEndInline) {
    //   let num_inline = this.currentJob.x2_is_inline ? this.currentJob.modelGrid.nx1 : this.currentJob.modelGrid.nx2;
    //   if (this.ILXLEndInline == null || this.ILXLEndInline == num_inline * this.currentJob.modelGrid.dx) {
    //     this.ILXLEndInline = Math.min (modelViewSettings.ILXLEndInline, num_inline * this.currentJob.modelGrid.dx);
    //   }
    // }

    // if (modelViewSettings && modelViewSettings.ILXLStartCrossline) {
    //   if (this.ILXLStartCrossline == null || this.ILXLStartCrossline == 0) {
    //     this.ILXLStartCrossline = Math.min (modelViewSettings.ILXLStartCrossline, this.currentJob.modelGrid.nx2 * this.currentJob.modelGrid.dx);
    //   }
    // }
    // if (modelViewSettings && modelViewSettings.ILXLEndCrossline) {
    //   let num_crossline = this.currentJob.x2_is_inline ? this.currentJob.modelGrid.nx2 : this.currentJob.modelGrid.nx1;
    //   if (this.ILXLEndCrossline == null || this.ILXLEndCrossline == num_crossline * this.currentJob.modelGrid.dx) {
    //     this.ILXLEndCrossline = Math.min (modelViewSettings.ILXLEndCrossline, num_crossline * this.currentJob.modelGrid.dx);
    //   }
    // }

    this.resetDepthSliceVisiblity(
      !!modelViewSettings ? modelViewSettings.showDepthSlice : undefined
    );

    let settings = this.storedSettingsService.getSettings<ModelSettings>(
      this.settingsKey,
      this.currentJob.id,
      SettingTypes.Model
    );
    if (!settings) {
      // console.error("NO SETTINGS COULD BE RESTORED")
      // this.currentModelTypeId = this.currentModelTypeId || "Vp";
      return;
    }
    this.iteration = settings.iteration;
    this.showHorizonTypes = settings.horizonTypes;
    this.showHorizon = settings.showHorizon;
    if (this.inlineNumber == null) {
      this.inlineNumber = settings.inlineNumber;
      console.warn(
        "INLINE NUMBER WAS NULL, USING THE VALUE FROM STORED SETTINGS TO SET TO",
        settings.inlineNumber
      );
    }
    if (this.crosslineNumber == null) {
      this.crosslineNumber = settings.crosslineNumber;
      console.warn(
        "CROSSLINE NUMBER WAS NULL, USING THE VALUE FROM STORED SETTINGS TO SET TO",
        settings.crosslineNumber
      );
    }
    if (this.depthSlice == null) {
      this.depthSlice = settings.depthSlice;
      console.warn(
        "DEPTH SLICE WAS NULL, USING THE VALUE FROM STORED SETTINGS TO SET TO",
        settings.depthSlice
      );
    }
    if (this.syncAxis) {
      // console.log("SYNC AXIS")
    } else {
      // console.log("NOT SYNC AXIS")
      this.zone.run(() => {
        this.verticalProfileAxis =
          settings.verticalProfileAxis == null ||
          (settings.verticalProfileAxis[0] == null &&
            settings.verticalProfileAxis[1] == null)
            ? [null, null]
            : settings.verticalProfileAxis;
      });
    }
    this.restoredScrollPos = settings.scrollPos;

    // if (this.currentModelTypeId == settings.modelTypeId && this.currentJob.id == settings.id) return;
    // this.storeRangeAndPaletteForCurrentType();
    this.currentModelTypeId = settings.modelTypeId;
    // console.error(`model type restored to ${this.currentModelTypeId}`)
    // this.syncRangeBetweenSlices = settings.syncRange;

    if (this.currentModelTypeId && this.currentJob.id) {
      let modeTypeSettings =
        this.storedSettingsService.getSettings<ModelTypeSettings>(
          this.settingsKey,
          this.currentModelTypeId + this.currentJob.id,
          SettingTypes.ModelType
        );
      if (
        modeTypeSettings &&
        modeTypeSettings.ranges &&
        modeTypeSettings.ranges.slice_processing
      ) {
        this.currentModelTypeProcessing =
          modeTypeSettings.ranges.slice_processing;
      } else {
        this.currentModelTypeProcessing = [];
      }
    }
    this.restoreRangeAndPaletteForCurrentType();
  }

  private storeSettings() {
    let currentSettings: ModelSettings = {
      modelTypeId: this.currentModelTypeId,
      horizonTypes: this.showHorizonTypes,
      id: this.currentJob.id,
      projectId: this.currentJob.projectId,
      iteration: this.iteration,
      showHorizon: this.showHorizon,
      inlineNumber: this.inlineNumber,
      crosslineNumber: this.crosslineNumber,
      depthSlice: this.depthSlice,
      verticalProfileAxis: this.verticalProfileAxis,
      scrollPos: this.scrollPos,
      // syncRange: this.syncRangeBetweenSlices
    };
    this.storedSettingsService.setSettings<ModelSettings>(
      this.settingsKey,
      currentSettings,
      SettingTypes.Model
    );

    this.storedSettingsService.setSettings<ModelViewSettings>(
      this.settingsKey,
      {
        id: this.currentJob.projectId,
        projectId: this.currentJob.projectId,
        height: this.height,
        depthHeight: this.depthHeight,
        iteration: 0,
        showDepthSlice: this.showDepthSliceGraph,
      },
      SettingTypes.ModelView
    );
  }

  private storeRangeAndPaletteForCurrentType() {
    if (!this.currentModelTypeId || !this.currentJob.id) return;
    let ranges = {
      minValue: this.minVal,
      maxValue: this.maxVal,
      minValueZ: this.minValZ,
      maxValueZ: this.maxValZ,
      slice_processing: this.currentModelTypeProcessing,
    };
    let typeSettings: ModelTypeSettings = {
      id: this.currentModelTypeId + this.currentJob.id,
      modelId: this.currentModelTypeId,
      projectId: this.currentJob.projectId,
      iteration: null,
      palette: this._paletteOfCurrentType,
      ranges: ranges,
      velocityAuto: this.velocityAuto,
    };
    this.storedSettingsService.setSettings<ModelTypeSettings>(
      this.settingsKey,
      typeSettings,
      SettingTypes.ModelType
    );
  }

  private restoreRangeAndPaletteForCurrentType() {
    if (!this.currentModelTypeId || !this.currentJob.id) return;
    let settings = this.storedSettingsService.getSettings<ModelTypeSettings>(
      this.settingsKey,
      this.currentModelTypeId + this.currentJob.id,
      SettingTypes.ModelType
    );
    if (settings) {
      if (
        (this.currentModelTypeId.includes("vdiff") ||
          this.currentModelTypeId.includes("RTM")) &&
        settings.palette == null
      ) {
        this._paletteOfCurrentType = "greyscale";
      } else if (
        (this.currentModelTypeId.includes("Gradient") ||
          this.currentModelTypeId.includes("Update")) &&
        settings.palette == null
      ) {
        this._paletteOfCurrentType = "red_white_blue";
      } else if (
        (this.currentModelTypeId == "Vp" ||
          this.currentModelTypeId == "PreMergeVp" ||
          this.currentModelTypeId == "Vs") &&
        settings.palette == null
      ) {
        this._paletteOfCurrentType = "SEGRefl2";
      } else {
        this._paletteOfCurrentType = settings.palette;
      }

      if (
        (this.currentModelTypeId.includes("Error") ||
          this.currentModelTypeId.includes("Update")) &&
        settings.palette == null
      ) {
        this._paletteOfCurrentType = "red_white_blue";
      }
      if (
        this.currentModelTypeProcessing.includes(
          "_zero_centred_params_process"
        ) &&
        settings.palette == null
      ) {
        this._paletteOfCurrentType = "greyscale";
      }
      this.currentJob.last_used_palette = this._paletteOfCurrentType;
      let nums = [
        settings.ranges.minValue,
        settings.ranges.maxValue,
        settings.ranges.minValueZ,
        settings.ranges.maxValueZ,
      ];
      for (let i = 0; i < nums.length; i++) {
        let num = nums[i];
        if (num != null) {
          if (isNaN(num) || Number.isNaN(num)) {
            nums[i] = null;
          }
        }
      }
      if (this.currentModelTypeId == "Vp") {
        let minVal = Math.min(nums[0], nums[1]);
        let maxVal = Math.max(nums[0], nums[1]);
        let minValZ = Math.min(nums[2], nums[3]);
        let maxValZ = Math.max(nums[2], nums[3]);
        this.minVal = Math.max(minVal, vpLowerBound);
        this.maxVal = Math.min(maxVal, vpUpperBound);
        this.minValZ = Math.max(minValZ, vpLowerBound);
        this.maxValZ = Math.min(maxValZ, vpUpperBound);
      } else {
        this.minVal = nums[0];
        this.maxVal = nums[1];
        this.minValZ = nums[2];
        this.maxValZ = nums[3];
      }
      this.velocityAuto = settings.velocityAuto;
      if (
        this.currentModelTypeId == "Gradient" ||
        this.currentModelTypeId == "InvVp Update"
      ) {
        if (this.storedMinMax != null) {
          this.minVal = this.storedMinMax["minVal"];
          this.maxVal = this.storedMinMax["maxVal"];
          this.minValZ = this.storedMinMax["minValZ"];
          this.maxValZ = this.storedMinMax["maxValZ"];
          this.velocityAuto = !(this.minVal != null || this.minValZ != null);
        } else {
          this.minVal = null;
          this.maxVal = null;
          this.minValZ = null;
          this.maxValZ = null;
          this.velocityAuto = true;
        }
      }
    } else {
      if (
        this.currentModelTypeId.includes("Gradient") ||
        this.currentModelTypeId.includes("Update") ||
        this.currentModelTypeId.includes("Error")
      ) {
        this._paletteOfCurrentType = "red_white_blue";
      } else if (
        this.currentModelTypeId.includes("vdiff") ||
        this.currentModelTypeId.includes("RTM") ||
        this.currentModelTypeProcessing.includes("_zero_centred_params_process")
      ) {
        this._paletteOfCurrentType = "greyscale";
      }
      if (
        this.currentModelTypeId == "Vp" ||
        this.currentModelTypeId == "PreMergeVp" ||
        this.currentModelTypeId == "Vs"
      ) {
        this._paletteOfCurrentType = "SEGRefl2";
      }
      this.minVal = null;
      this.maxVal = null;
      this.minValZ = null;
      this.maxValZ = null;
      this.velocityAuto = true;
      if (
        this.currentModelTypeId == "Gradient" ||
        this.currentModelTypeId == "InvVp Update"
      ) {
        if (this.storedMinMax != null) {
          this.minVal = this.storedMinMax["minVal"];
          this.maxVal = this.storedMinMax["maxVal"];
          this.minValZ = this.storedMinMax["minValZ"];
          this.maxValZ = this.storedMinMax["maxValZ"];
          this.velocityAuto = !(this.minVal != null || this.minValZ != null);
        }
      }
    }
  }

  private getAvailableTimeSlices(modelType) {
    if (!this.shotModels.includes(modelType)) return;
    this._timeSliceSubscription = this.projectService
      .getShotsAvailableForModel(this.currentJob.id, this.iteration, modelType)
      .pipe(
        switchMap((shots) => {
          if (!shots || shots.length <= 0) return of(null);
          shots = shots.sort((a, b) => a - b);
          this.availShots = shots;
          if (!this.shotId) this.shotId = shots[0];
          if (!this.timeSliceModels.includes(modelType)) return of(null);
          return this.projectService.getTimeSliceAvailableForModel(
            this.currentJob.id,
            this.iteration,
            this.shotId,
            modelType
          );
        }),
        take(1)
      )
      .subscribe((timeSlices) => {
        if (!timeSlices) return;
        this.availTimeSlices = timeSlices;
        if (
          this.availTimeSlices &&
          this.availTimeSlices.length > 0 &&
          !this.timeSlice
        )
          this.timeSlice = this.availTimeSlices[0];
      });
  }

  onVpUpdateChange(event) {}

  /**
   *
   * @description get the vertical profile i.e. the corresponding column in the slice array.
   */
  getVerticalProfile() {
    this.verticalProfileIsLoading = true;
    this.verticalProfileHasError = false;
    this.velocities = [];
    let velocityRange = this.getCurrentVeloctiyRange(this.slices[0].sliceType);
    var hasVelocity =
      velocityRange &&
      velocityRange.length == 2 &&
      velocityRange[0] &&
      velocityRange[1];
    this.projectService
      .getSlice(
        this.currentJob.id,
        this.currentModelTypeId,
        this.iteration,
        this.getAdjustedSliceType(this.slices[0].sliceType),
        this.getNumberForSliceType(this.slices[0].sliceType),
        this.currentJob.modelGrid.is2d,
        this.currentJob.last_used_palette,
        hasVelocity ? velocityRange[0] : null,
        hasVelocity ? velocityRange[1] : null,
        this.shotId,
        1,
        this.vpUpdateCheck ? this.vpDiffIteration : 0,
        this.timeSlice
      )
      .pipe(
        take(1),
        switchMap((res) => {
          if (!res) {
            this.verticalProfileHasError = true;
            this.verticalProfileIsLoading = false;
            return null;
          }
          return this.projectService.getSliceArray(
            res.sliceUrl,
            this.getYStepForSliceType(this.slices[0].sliceType),
            this.getXStepForSliceType(this.slices[0].sliceType),
            res.trueMin,
            res.trueMax
          );
        })
      )
      .subscribe((res) => {
        if (res != null) {
          let velocities = [];
          let crossline = this.currentJob.modelGrid.is2d
            ? this.velocityProfileCrossline
            : this.crosslineNumber;
          for (let i = 0; i < res.length; i++) {
            velocities.push(res[i][crossline - 1]);
          }
          this.velocities = velocities;
          let ranges = this.getRangesForSliceType(this.slices[0].sliceType);
          this.depth = ranges.y[0];
          this.verticalProfileIsLoading = false;
        } else {
          this.verticalProfileHasError = true;
          this.verticalProfileIsLoading = false;
        }
      });
  }

  /**
   *
   * @description get the functional sequence for the current job by reading runfile
   */
  getFunctionalSequence() {
    // this.dataSource = []
    this.projectService
      .getFunctionalSequence(
        this.currentJob.id,
        this.currentJob.basePath,
        this.currentJob.prefix
      )
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          let dataSource = [];
          this.functionals = [];
          this.frequencies = [];
          this.additionals = [];
          for (let i = 0; i < res.functionals.length; i++) {
            this.functionals.push(res.functionals[i][1]);
            this.frequencies.push(res.frequencies[i][1]);
            this.additionals.push(res.additionals[i][1]);
            let iter = res.additionals[i][1].Iters[0];
            // console.warn("iter", iter)
            // console.warn("this.currentJob.iterationsComplete", this.currentJob.iterationsComplete)
            let entry = {
              block: i + 1,
              functional: res.functionals[i][1],
              frequency: res.frequencies[i][1],
              iters: res.additionals[i][1],
              block_started: this.currentJob.iterationsComplete >= iter,
            };
            // console.warn("entry", entry)
            dataSource.push(entry);
          }
          this.dataSource = Array.from(dataSource);
        }
      });
  }

  onVerticalProfileAxisMinMaxUpdate(data) {
    this.zone.run(() => {
      this.verticalProfileAxis = data;
    });
  }

  onSyncAxisUpdate(data) {
    this.syncAxis = data;
  }

  /**
   *
   * @param event
   * @description under 2d, when the user click on the slice,
   * it emits the crossline and depth of the clicked point so that vertical profile can be updated accordingly.
   */
  onClickEmit(event) {
    if (this.currentJob.modelGrid.is2d && this.enableClick) {
      this.velocityProfileCrossline = event.crossline;
      this.depthSlice = event.depth;
      this.getVerticalProfile();
    }
  }

  /**
   *
   * @description swap the position of vertical profile and inline so that the vertical profile is on the right of the crossline
   * this is a bit hacky, maybe there's a better solution.
   */
  swapPosition() {
    const velocityProfileElement = document.getElementById("velocity-profile");
    const slices = document.getElementsByClassName("chart-slice");
    if (this.vpPositionSwapped) {
      const el = slices.item(1) as HTMLElement;
      el.style.order = "4";
      velocityProfileElement.style.order = "2";
      this.vpHeight = this.height;
    } else {
      const el = slices.item(1) as HTMLElement;
      el.style.order = "2";
      velocityProfileElement.style.order = "4";
      this.vpHeight = this.depthHeight;
    }
  }

  /**
   *
   * @param event
   * @description when chart slice finish loading, it emits a ColorBarTextInfo object, which is caught here.
   * the min and max values are used to set the default x axis range for the vertical profile if the slice type is crossline or the job is 2d.
   */
  onColorbarTextEmit(event: ColorBarTextInfo) {
    if (event.sliceType == 2 || this.currentJob.modelGrid.is2d) {
      this.minVal = event.min;
      this.maxVal = event.max;
      // affects the default x axis range for the vertical profile
      if (this.velocityAuto) {
        // this.zone.run(() => {
        //   this.inlineColorBarMinMax = [event.min, event.max]
        //   this.verticalProfileAxis[0] = null;
        //   this.verticalProfileAxis[1] = null;
        // })
      } else {
        this.zone.run(() => {
          this.verticalProfileAxis[0] = Number.parseFloat(event.min.toFixed(2));
          this.verticalProfileAxis[1] = Number.parseFloat(event.max.toFixed(2));
        });
      }
    } else if (event.sliceType == 3) {
      this.minValZ = event.min;
      this.maxValZ = event.max;
    }
  }

  /**
   *
   * @description when chart slices finish loading, it emits event which is caught here.
   * when all slices finish loading, emit the event to scroll to the stored scroll position, in order to maintain the scroll when coming back to the page.
   */
  onLoadFinishedEmit(event) {
    if (this.waitingFor > 0) {
      this.waitingFor = this.waitingFor - 1;
      if (this.waitingFor == 0) {
        this.time_to_scroll_subscription.next("TIME TO SCROLL");
      }
    }
  }

  onInputHeightChange(event) {
    if (this.inputHeight <= 625 && this.inputHeight >= 250) {
      this.inputHeight = Math.round(this.inputHeight);
      this.height = this.inputHeight;
      this.setVpHeight();
    }
  }

  onInputDepthHeightChange(event) {
    if (
      this.inputDepthHeight <= this.maxDepth &&
      this.inputDepthHeight >= 200
    ) {
      this.inputDepthHeight = Math.round(this.inputDepthHeight);
      this.depthHeight = this.inputDepthHeight;
      this.setVpHeight();
    }
  }

  onKeySubmissionHeight(event) {
    if (event.key == "Enter") {
      if (this.inputHeight < 250) {
        this.inputHeight = 250;
      }
      if (this.inputHeight > 625) {
        this.inputHeight = 625;
      }
      this.height = Math.round(this.inputHeight);
      this.setVpHeight();
    }
  }

  onKeySubmissionDepthHeight(event) {
    if (event.key == "Enter") {
      if (this.inputDepthHeight < 200) {
        this.inputHeight = 200;
      }
      if (this.inputDepthHeight > this.maxDepth) {
        this.inputDepthHeight = this.maxDepth;
      }
      this.depthHeight = Math.round(this.inputDepthHeight);
      this.setVpHeight();
    }
  }

  setVpHeight() {
    this.vpHeight =
      this.currentJob.modelGrid.is2d || this.vpPositionSwapped
        ? this.height
        : this.depthHeight;
  }

  /**
   *
   * @description use html2canvas to draw the element with id = 'slices' to a canvas, then convert to a png data url and download
   */
  depthScreenshotToggle() {
    if (this.showDepthSliceScreenshot) {
      this.depthScreenshotText = "(w/ depth)";
    } else {
      this.depthScreenshotText = "(w/o depth)";
    }
  }

  /**
   *
   * @description toggle the height of the table that displays the functional sequence to be fixed or fit-content
   */
  toggleTableHeight() {
    if (this.tableHeight == "300px") {
      this.tableHeight = "fit-content";
    } else {
      let pos = document.body.clientHeight - this.scrollPos;
      this.tableHeight = "300px";
      setTimeout(() => {
        window.scrollTo({
          top: document.body.clientHeight - pos,
          left: 0,
          behavior: "smooth",
        });
      }, 0);
    }
  }

  toggleDepthSlice({ checked }: { checked: boolean }) {
    if (checked) {
      this.showDepthSlice();
    } else {
      this.hideDepthSlice();
    }
  }

  private showDepthSlice() {
    this.showDepthSliceScreenshot = false;
    this.depthScreenshotText = "(w/ depth)";

    this.slices.push({
      sliceType: SliceType.depth,
      span: 1,
    });

    this.showDepthSliceGraph = true;
  }

  private hideDepthSlice() {
    this.showDepthSliceScreenshot = true;
    this.depthScreenshotText = "(w/o depth)";

    const depthSlice = this.slices.find(
      (slice) => slice.sliceType === SliceType.depth
    );
    if (!depthSlice) {
      return;
    }

    this.slices.splice(this.slices.indexOf(depthSlice), 1);

    this.showDepthSliceGraph = false;
  }

  private resetDepthSliceVisiblity(newShowDepthSliceValue?: boolean) {
    if (newShowDepthSliceValue === undefined) {
      this.hideDepthSlice();
      return;
    }

    // we only need to do anything if the visibility has changed from what we had before
    if (newShowDepthSliceValue && !this.showDepthSliceGraph) {
      this.showDepthSlice();
    }

    if (!newShowDepthSliceValue && this.showDepthSliceGraph) {
      this.hideDepthSlice();
    }
  }

  updateComment() {
    const details = {
      id: this.currentJob.id,
      name: this.currentJob.name,
      comments: this.comments,
    };
    this.projectService.updateJobDetails(details).subscribe(
      (data) => {
        this.projectService.clearProjectsCache();
        this.projectService.clearJobDetailCache([this.currentJob.id]);
        this.snackbar.open("Job Details updated", null, {
          duration: 2000,
        });
      },
      (error) => {
        this.snackbar.open(
          "Sorry, the job details could not be updated",
          null,
          { duration: 2000 }
        );
      }
    );
  }

  screenshotPage() {
    const printDiv = document.createElement("div");

    const element = document.getElementById("slices");

    const container = document.getElementById(
      "screenshot-container-div"
    ) as HTMLElement;
    const inlineDiv = element
      .querySelector("#slice_id_0")
      .cloneNode(true) as HTMLElement;
    inlineDiv.id = "_print_1";
    inlineDiv.style.order = "1";
    inlineDiv.style.color = "white";

    const crosslineDiv = element
      .querySelector("#slice_id_1")
      .cloneNode(true) as HTMLElement;
    crosslineDiv.id = "_print_2";
    crosslineDiv.style.order = "2";
    crosslineDiv.style.color = "white";

    printDiv.style.display = "flex";
    printDiv.style.flexWrap = "wrap";
    printDiv.style.background = "#f5f5f5";
    printDiv.style.width = "100%";
    printDiv.style.marginBottom = "10px";
    printDiv.appendChild(inlineDiv);

    if (crosslineDiv.children.length != 0) {
      // if both inline and crossline exist, show the inline crossline side by side
      printDiv.appendChild(crosslineDiv);
    }

    const vertProfileDiv =
      (document
        .getElementById("velocity-profile")
        ?.cloneNode(true) as HTMLElement) || null;
    const depthDiv =
      (element.querySelector("#slice_id_2")?.cloneNode(true) as HTMLElement) ||
      null;
    if (this.showDepthSlice && depthDiv) {
      depthDiv.id = "_print_3";
      printDiv.appendChild(depthDiv);
      depthDiv.style.order = "3";
      depthDiv.style.color = "white";
    }
    if (this.screenshotWithVertProfile && vertProfileDiv) {
      vertProfileDiv.id = "_print_4";
      printDiv.appendChild(vertProfileDiv);
      vertProfileDiv.style.order = "4";
      vertProfileDiv.style.color = "white";
    }

    if (this.vpPositionSwapped) {
      vertProfileDiv.style.order = "2";
      crosslineDiv.style.order = "3";
      if (depthDiv) {
        depthDiv.style.order = "4";
      }
    }

    container.appendChild(printDiv);

    html2canvas(printDiv).then((canvas) => {
      if (canvas.height == 0 || canvas.style.height == "0") {
        canvas.style.height = `${this.height}px`;
        canvas.height = this.height;
      }
      let url = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = url;
      let name;
      let zoomlevel = window.devicePixelRatio;
      if (this.currentJob.modelGrid.is2d) {
        name =
          stripDash(this.currentJob.projectName.substring(0, 6)) +
          "-" +
          stripDash(this.currentJob.name.substring(0, 6)) +
          "-CPNUM" +
          this.iteration +
          "-" +
          this.currentModelTypeId +
          "-Height" +
          this.height +
          "-ZOOM" +
          zoomlevel +
          ".png";
      } else {
        name =
          stripDash(this.currentJob.projectName.substring(0, 6)) +
          "-" +
          stripDash(this.currentJob.name.substring(0, 6)) +
          "-IL" +
          this.inlineNumber.toString() +
          "-XL" +
          this.crosslineNumber.toString() +
          "-DEP" +
          this.depthSlice.toString() +
          "-CPNUM" +
          this.iteration +
          "-" +
          this.currentModelTypeId +
          "-Height" +
          this.height +
          "_" +
          this.depthHeight +
          "-ZOOM" +
          zoomlevel +
          ".png";
      }
      link.download = name;
      link.click();

      container.removeChild(printDiv);
      // var windowish = window.open('', '_blank');
      // windowish.document.title = name
      // windowish.document.write(`
      //   <html><head><title>${name}</title></head><body>
      //   <img src="${url}"/></body></html>
      //   `);
    });
  }

  downloadSlicesAsPng() {
    const velocity_profile = document.getElementById("velocity-profile");
    const model_info = document.getElementById("_model_information_container");
    if (!this.screenshotWithVertProfile) {
      if (velocity_profile != null) {
        velocity_profile.style.display = "none";
      }
    }

    model_info.style.display = "none";

    const element = document.getElementById("slices");
    // Deselect the comment section
    const comment_section = document.getElementById("comment");
    let commentDisplay = comment_section.style.display;
    comment_section.style.display = "none";

    let current_color = element.style.backgroundColor;
    element.style.backgroundColor = "#F3F3F3";
    html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
      let url = canvas.toDataURL();
      const link = document.createElement("a");
      // console.log("url", url);
      link.href = url;
      let name;
      let zoomlevel = window.devicePixelRatio;
      if (this.currentJob.modelGrid.is2d) {
        name =
          stripDash(this.currentJob.projectName.substring(0, 6)) +
          "-" +
          stripDash(this.currentJob.name.substring(0, 6)) +
          "-CPNUM" +
          this.iteration +
          "-" +
          this.currentModelTypeId +
          "-Height" +
          this.height +
          "-ZOOM" +
          zoomlevel +
          ".png";
      } else {
        name =
          stripDash(this.currentJob.projectName.substring(0, 6)) +
          "-" +
          stripDash(this.currentJob.name.substring(0, 6)) +
          "-IL" +
          this.inlineNumber.toString() +
          "-XL" +
          this.crosslineNumber.toString() +
          "-DEP" +
          this.depthSlice.toString() +
          "-CPNUM" +
          this.iteration +
          "-" +
          this.currentModelTypeId +
          "-Height" +
          this.height +
          "_" +
          this.depthHeight +
          "-ZOOM" +
          zoomlevel +
          ".png";
      }
      link.download = name;
      link.click();
      // Restore changes
      element.style.backgroundColor = current_color;
      comment_section.style.display = commentDisplay;

      model_info.style.display = "";
      if (!this.screenshotWithVertProfile) {
        if (velocity_profile != null) {
          velocity_profile.style.display = "block";
        }
      }
    });
  }

  syntaxHighlight(jsonObj: object) {
    let json = JSON.stringify(jsonObj, null, 2);
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    let temp = json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        var sty = "color: darkorange;";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
            sty = "color: red;";
          } else {
            cls = "string";
            sty = "color: green;";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
          sty = "color: blue;";
        } else if (/null/.test(match)) {
          cls = "null";
          sty = "color: magenta;";
        }
        return (
          '<span style="' + sty + '" class="' + cls + '">' + match + "</span>"
        );
      }
    );
    return `<pre>${temp}</pre>`;
  }

  getModelInformation() {
    this.modelInfoLoading = true;
    this.modelInfoFetched = true;
    this.projectService
      .getModelInformation(this.currentJob.id, this.iteration)
      .toPromise()
      .then((res) => {
        document.getElementById("_model_information").innerHTML =
          this.syntaxHighlight(res);
      })
      .catch((err) => {
        console.log("error", err);
        document.getElementById("_model_information").innerHTML =
          this.syntaxHighlight({
            error: "No relevant EBCDIC data for this job and iteration",
          });
      })
      .finally(() => (this.modelInfoLoading = false));
  }

  clearModelInfo() {
    this.modelInfoFetched = false;
    if (document.getElementById("_model_information")?.innerHTML) {
      document.getElementById("_model_information").innerHTML = "";
    }
  }
}

export function getRandomHexColor(): string {
  const hexChars = "0123456789ABCDEF";
  let color = "#";

  // Generate a random six-digit hexadecimal color code
  for (let i = 0; i < 6; i++) {
    color += hexChars[Math.floor(Math.random() * 16)];
  }

  return color;
}
