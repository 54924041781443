import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { JobDetailDialog } from "../../../models/jobDetailDialog";
import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { ParameterViewEditDialogComponent } from "../../project/dialogs/parameter-view-edit-dialog/parameter-view-edit-dialog.component";
import { RebuildTableComponent } from "../../project/dialogs/rebuild-project-table/rebuild-project-table.component";
import { ButtonConfig } from "../data-table/custom-table/custom-table.component";
import { parameter_view_fields } from "./fields";
import { FormControl, Validators } from "@angular/forms";
import { ProjectService } from "../../../shared/services/project.service";
import { NzMessageService } from "ng-zorro-antd/message";

@Component({
  selector: "app-parameter-overview-2",
  templateUrl: "./parameter-overview-2.component.html",
  styleUrls: ["./parameter-overview-2.component.less"],
})
export class ParameterOverview2Component implements OnInit, OnDestroy {
  private _userSubscription: any;
  private _projectSubscription: Subscription;
  private _parameterTableSubscription: Subscription;

  isVisible: boolean = false;
  creating_group: boolean = false;
  nameControl: FormControl;
  commentControl: FormControl;
  jobsControl: FormControl;
  parentJobs: any[] = [];
  project_id: string;
  project_name: string;
  is_loading: boolean = false;
  data: Array<any> = [];
  options: Object = parameter_view_fields;
  linkDefinitions: Array<any> = [
    {
      key: "job_name",
      template: (row: object) => `projects/${row["job_id"]}/model`,
    },
    {
      key: "parent_job_name",
      template: (row: object) =>
        `projects/${row["parent_job_id"]}/model?iteration=${
          row["parent_job_cp"] || 0
        }`,
    },
  ];
  buttonConfig: ButtonConfig[] = [
    {
      text: "Open Showing Jobs",
      action: (event, info) => {
        window.open(
          `/projects/${info[0].job_id}/model?${info
            .map((job: Object) => `jobs=${job["job_id"]}`)
            .join("&")}`,
          "_blank"
        );
      },
    },
    {
      text: "Create Job Group",
      action: (event, info) => {
        console.log(
          info,
          info.map((p) => p.job_id)
        );
        this.jobsControl.setValue(info.map((p) => p.job_id));
        this.isVisible = true;
      },
    },
    {
      text: "Plot Tracefits",
      action: (event, info) => {
        window.open(
          `/project/${this.project_id}/trace-fits-plotting?${info
            .map((job: Object) => `jobs=${job["job_id"]}`)
            .join("&")}`,
          "_blank"
        );
      },
    },
  ];
  inMenuButtonConfig: ButtonConfig[] = [
    {
      text: "Rebuild Table",
      action: (event, info) => {
        this.rebuildTable();
      },
    },
    {
      text: "Refresh Table",
      action: (event, info) => {
        this.refreshTable();
      },
    },
    {
      text: "Export as TSV",
      action: (event, info, fields) => {
        this._exportAsTSV(event, info, fields);
      },
    },
  ];
  landingJobs: object[] = []; // These are the jobs to be shown when landing on the page. Are gotten from the URL

  constructor(
    private router: Router,
    private cachedProjectService: CachedProjectService,
    public dialog: MatDialog,
    private userService: CachedUserService,
    public snackBar: MatSnackBar,
    private _projectService: ProjectService,
    private _message: NzMessageService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._userSubscription = this.userService.userDetail.subscribe((d) => {
      // if (d && !(d.isStaff || d.email === "testuser01@s-cube.com"))
      //   console.log("this is whats happening");
      // this.router.navigateByUrl("/");
    });
    this._projectSubscription =
      this.cachedProjectService.currentProject.subscribe((project: Project) => {
        if (project != null) {
          this.project_id = project.id;
          this.project_name = project.name;
          this.nameControl = new FormControl("", Validators.required);
          this.commentControl = new FormControl("");
          this.jobsControl = new FormControl([], Validators.required);
          this.get_parameter_table();
          this.getParentJobs();
        }
      });
  }

  ngOnDestroy(): void {
    this._parameterTableSubscription.unsubscribe();
    this._userSubscription.unsubscribe();
    this._projectSubscription.unsubscribe();
  }

  getParentJobs() {
    this.creating_group = true;
    this.cachedProjectService
      ._getProjectJobs(this.project_id, {
        fields: ["id", "job_name"],
      })
      .toPromise()
      .then((res) => {
        this.parentJobs = res.data;
      })
      .catch((err) => console.log(err))
      .finally(() => (this.creating_group = false));
  }

  get_parameter_table(rebuild: boolean = false, forceRefresh: boolean = false) {
    this.is_loading = true;
    this._parameterTableSubscription = this.cachedProjectService
      .getParameterTable(this.project_id, rebuild, [], forceRefresh)
      .subscribe(
        (data) => {
          this.landingJobs = this._route.snapshot.queryParams.jobs;
          this.data = [...data["results"]];
          this.is_loading = false;
        },
        (err) => {
          console.log(err);
          this.is_loading = false;
        }
      );
  }

  refreshTable() {
    this.get_parameter_table(false, true);
  }

  rebuildTable() {
    this.dialog
      .open(RebuildTableComponent, {
        height: "320px",
        width: "400px",
        data: {
          project_name: this.project_name,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          this.get_parameter_table(true);
        }
      });
  }

  editComment(row: object) {
    this.dialog
      .open(ParameterViewEditDialogComponent, {
        data: { comments: row["comments"] },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result !== undefined) {
          const details: JobDetailDialog = {
            id: row["job_id"],
            name: row["job_name"],
            comments: result,
          };
          this.is_loading = true;
          this.cachedProjectService.updateJobDetails(details).subscribe(
            (data) => {
              this.cachedProjectService.clearProjectsCache();
              this.cachedProjectService.clearJobDetailCache([this.project_id]);
              this.snackBar.open("Job Details updated", null, {
                duration: 2000,
              });
              this.refreshTable();
            },
            (error) => {
              this.snackBar.open(
                "Sorry, the job details could not be updated",
                null,
                { duration: 2000 }
              );
            },
            () => {
              this.is_loading = false;
            }
          );
        }
      });
  }

  createGroup() {
    // For form validation and showing errors
    if (!this.nameControl.value || this.nameControl.value == "") {
      this.nameControl.setErrors({ required: true });
    } else if (!this.jobsControl.value || this.jobsControl.value.length == 0) {
      this.jobsControl.setErrors({ required: true });
    } else {
      // if all is well
      this.creating_group = true;
      this._projectService
        .createJobGroups({
          project_id: this.project_id,
          name: this.nameControl.value,
          comment: this.commentControl.value,
          jobs: this.jobsControl.value,
        })
        .toPromise()
        .then((res) => {
          this._message.success("Successfully created job group");
          this.resetFields();
        })
        .catch((err) => {
          console.log(err);
          this._message.error(err.message);
        })
        .finally(() => (this.creating_group = false));
    }
  }
  resetFields() {
    this.commentControl.reset();
    this.nameControl.reset();
    this.jobsControl.reset();
    this.isVisible = false;
  }

  _exportAsTSV(event, data, fields) {
    // its not csv, its tsv
    let csvstr = fields.map((f) => f["key"]).join("\t") + "\n";
    for (let row of data) {
      let entry = fields.map((x) => row[x["key"]]);
      csvstr += entry.join("\t") + "\n";
    }
    let blob = new Blob([csvstr], { type: "text/tab-separated-values" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "table.tsv";
    a.click();
  }
}
