import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  TemplateRef,
} from "@angular/core";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

declare var $: any;

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.less"],
})
export class SliderComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("slider", { static: true }) slider: ElementRef;
  @Input() completed: number;
  @Input() max: number;
  @Input() min: number;
  @Input() value: number;
  @Input() step: number;
  @Input() label: string = "";
  @Input() antd: boolean = false;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  inputValue: number;
  inputStep: number;
  valueChangeObservable = new Subject<number>();

  @ViewChild("sliderTemplateContainer", { read: ViewContainerRef })
  containerRef: ViewContainerRef;
  @ViewChild("sliderTemplate", { read: TemplateRef })
  sliderTemplateRef: TemplateRef<any>;

  constructor() {}

  ngOnInit() {
    this.inputValue = this.value;
    this.inputStep = this.step;
    this.valueChangeObservable
      .pipe(debounceTime(200))
      .subscribe((val: number) => {
        this.changeValue(val);
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateCompleted();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rerender();

    let changed = false;
    for (let propName in changes) {
      if (propName == "completed" || propName == "max") {
        changed = true;
      }
    }

    this.inputValue = this.value;
    this.inputStep = this.step;
    if (changed) {
      setTimeout(() => {
        this.updateCompleted();
      }, 0);
    }
  }

  updateCompleted() {
    if (
      !this.slider ||
      !this.slider.nativeElement ||
      !this.slider.nativeElement.firstChild
    )
      return;

    $(this.slider.nativeElement)
      .find(".mat-slider-track-background-complete")
      .remove();

    if (this.completed === this.max || (!this.completed && this.completed != 0))
      return;

    var scale = (this.max - this.completed) / this.max;
    $(this.slider.nativeElement)
      .find(".mat-slider-track-wrapper")
      .append(
        '<div class="mat-slider-track-background-complete" style="transform: translateX(0px) scaleX(' +
          scale +
          ');"></div>'
      );
  }

  liveChange(val) {
    this.valueChangeObservable.next(val.value);
  }

  changeValue(val) {
    console.log("ngModelChange", val);
    if (val == null) return;

    var max =
      !!this.completed || this.completed == 0 ? this.completed : this.max;

    if (val < 0) {
      setTimeout(() => {
        this.value = this.min;
        this.inputValue = this.min;
      }, 0);
    } else if (val > this.max) {
      setTimeout(() => {
        this.value = this.max;
        this.inputValue = this.max;
      }, 0);
    }

    if (val <= max && val >= this.min) {
      setTimeout(() => {
        this.value = val;
        this.inputValue = val;
      }, 0);
      this.valueChange.emit(val);
    }
  }

  stepChanged(val) {
    if (val == null) return;

    if (val < 0)
      setTimeout(() => {
        this.step = 1;
        this.inputStep = 1;
      }, 0);
    else if (val > this.max) {
      setTimeout(() => {
        this.step = this.max;
        this.inputStep = this.max;
      }, 0);
    }

    if (val > 0 && val <= this.max) {
      setTimeout(() => {
        this.step = val;
        this.inputStep = val;
      }, 0);
    }
  }

  plusClicked() {
    var max =
      !!this.completed || this.completed == 0 ? this.completed : this.max;

    if (this.value + this.step < max) this.value += this.step;
    else this.value = max;
    this.inputValue = this.value;
    this.valueChange.emit(this.value);
  }

  minusClicked() {
    if (this.value - this.step > this.min) this.value -= this.step;
    else this.value = this.min;
    this.inputValue = this.value;
    this.valueChange.emit(this.value);
  }

  rerender() {
    // this is a hack. Why?
    // suppose you iterationValue = 10, and you assign to 10 again. This doesn't rerender the DOM.
    // This is fine, but if the min and max of that slider change, the values get updated but the slider does not update its view
    // so the position of the thumb remains wrong. Doing this, will update the value and rerender. Tho i am only doing this for
    // when assigning the value to the the iteration cause in most other cases it still works and doesn't cause a problem.

    // honestly it could also have worked had I used a subject + observable + subscription kinda thing, but doesn that overcomplicate things.
    this.containerRef?.clear();
    this.containerRef?.createEmbeddedView(this.sliderTemplateRef);
  }
}
