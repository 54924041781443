import { Component, OnInit, HostListener } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import { CachedUserService } from "../../shared/services/cached-user.service";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.less"],
})
export class NavBarComponent implements OnInit {
  private destroy$ = new Subject<void>();

  isActive: boolean = false;
  showFull: boolean = false;
  isBillingUser: boolean = false;

  isStaff = false;
  subscription: Subscription = null;

  constructor(
    public auth: AuthService,
    private userService: CachedUserService
  ) {}

  ngOnInit() {
    this.auth.isLoggedIn.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (!result) {
        this.isBillingUser = false;
        return;
      }

      this.subscription = this.userService.userDetail
        .pipe(takeUntil(this.destroy$))
        .subscribe((d) => {
          this.isStaff = d && d.isStaff;
        });
      this.userService
        .isGroupAdmin()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.isBillingUser = !!result;
        });
    });
  }

  expandMenu(event) {
    this.isActive = !this.isActive;
  }

  @HostListener("window:scroll", ["$event"])
  doSomething(event) {
    this.showFull = window.pageYOffset > 10;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
