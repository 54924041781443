<div style="min-height: 50vh; background-color: white">
  <ul style="height: 100%" nz-menu nzMode="inline">
    <li
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Settings"
      nzSelected
      (click)="menuChange('/copilot')"
    >
      <span nz-icon nzType="setting" nzTheme="outline"></span>
      <span>Settings</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      nzTooltipTitle="Copilot"
      (click)="menuChange('/copilot/chat')"
    >
      <span nz-icon nzType="github" nzTheme="outline"></span>
      <span>Copilot</span>
    </li>
  </ul>
</div>
