<div style="display: flex">
  <div style="width: 80%">
    <div
      style="
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
        "
      >
        <button
          *ngIf="this.inMenuButtonConfig.length > 0"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          style="display: flex"
          aria-label="options"
        >
          <span
            style="rotate: 90deg"
            nz-icon
            nzType="ellipsis"
            nzTheme="outline"
          ></span>
        </button>
        <ng-content></ng-content>
        <button
          *ngFor="let config of this.buttonConfig"
          mat-raised-button
          (click)="config.action($event, this.filteredData)"
        >
          {{ config.text }}
        </button>
        <button mat-raised-button (click)="this.removeAllFilters()">
          Clear All Filters
        </button>
        <button (click)="this.showAppliedFilterDialog()" mat-raised-button>
          Show applied filters
        </button>
      </div>
      <span> Showing {{ this.showingRows }} out of {{ this.totalRows }} </span>
    </div>
    <div *ngIf="this.diffJob">
      All jobs are currently being compared to {{ this.diffJob }}
    </div>
    <div
      *ngIf="this.data.length > 0; else emptyTable"
      class="custom-data-table-container"
      [style.overflow]="this.loading ? 'hidden' : 'auto'"
    >
      <table class="data-table">
        <thead>
          <tr>
            <th *ngIf="this.collapsibleDetail"></th>
            <th
              *ngFor="let key of this.displayKeysArray"
              [class]="'key-table-heading ' + key.key"
              (click)="sorter(key.key)"
              [style.position]="
                this.stickyFieldKeys.includes(key.key) ? 'sticky' : ''
              "
              [style.left]="this.stickyFieldKeys.includes(key.key) ? '0px' : ''"
            >
              <div>
                <span>
                  {{ key.label }}
                </span>

                <div
                  style="
                    width: 50px;
                    display: flex;
                    justify-content: flex-end;
                    gap: 5px;
                    align-items: baseline;
                  "
                >
                  <ng-container
                    *ngIf="this.sortingKey == key.key; else emptyArrow"
                  >
                    <ng-container
                      *ngIf="this.sortingMode == 'asc'; else descArrow"
                    >
                      <i class="zmdi zmdi-long-arrow-up"></i>
                    </ng-container>
                    <ng-template #descArrow>
                      <i class="zmdi zmdi-long-arrow-down"></i>
                    </ng-template>
                  </ng-container>
                  <ng-template #emptyArrow>
                    <span></span>
                  </ng-template>

                  <div
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="menu"
                    [class]="
                      this.getObjectKeys(this.headerFilterationObject).includes(
                        key.key
                      )
                        ? 'filters-box-selector'
                        : 'filters-box-selector not-filtered'
                    "
                  >
                    <i class="fa-solid fa-filter"></i>
                  </div>

                  <mat-menu #menu="matMenu">
                    <div style="padding: 0px 5px">
                      <!-- [checked]="this.uglyFilter" -->
                      <!-- (click)="$event.stopPropagation()" -->
                      <button
                        (click)="toggleUglyFilterShow($event)"
                        style="
                          position: absolute;
                          z-index: 2;
                          right: 0px;
                          top: 45%;
                          outline: none;
                          border: none;
                          background: #2196f3;
                          color: white;
                          writing-mode: vertical-lr;
                          border-radius: 5px 0px 0px 5px;
                          text-orientation: upright;
                          letter-spacing: -2px;
                        "
                      >
                        <i class="zmdi zmdi-chevron-right"></i>
                      </button>
                      <mat-form-field
                        style="width: 100%"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-label>Search</mat-label>
                        <input matInput type="search" #searchOptions />
                      </mat-form-field>

                      <div class="search-options-listing">
                        <span
                          *ngFor="
                            let item of getFilterValuesForColumn(
                              key.key,
                              searchOptions
                            )
                          "
                          mat-menu-item
                          [title]="item"
                          (click)="$event.stopPropagation()"
                        >
                          <mat-checkbox
                            (click)="$event.stopPropagation()"
                            [checked]="
                              this.headerFilterationObject[key.key]
                                ? !this.headerFilterationObject[
                                    key.key
                                  ].includes(item)
                                : true
                            "
                            (change)="
                              this.searchFilterSelected($event, key.key, item)
                            "
                          >
                            <ng-container
                              *ngIf="
                                item !== null &&
                                  item !== '' &&
                                  item !== undefined;
                                else noneLabel
                              "
                            >
                              {{ item }}
                            </ng-container>
                            <ng-template #noneLabel>
                              <span style="color: #ccc; font-style: italic">
                                None
                              </span>
                            </ng-template>
                          </mat-checkbox>
                        </span>
                      </div>

                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          flex-wrap: wrap;
                          align-items: center;
                          gap: 5px;
                          margin-top: 5px;
                        "
                      >
                        <button
                          mat-raised-button
                          type="submit"
                          style="
                            height: fit-content;
                            background-color: #2196f3;
                            color: white;
                            width: 100%;
                          "
                          (click)="runSearch()"
                        >
                          Apply
                        </button>
                        <button
                          mat-raised-button
                          type="submit"
                          style="height: fit-content; flex-grow: 1"
                          (click)="
                            $event.stopPropagation(); deselectAllSearch(key.key)
                          "
                        >
                          Deselect All
                        </button>
                        <button
                          mat-raised-button
                          type="submit"
                          style="height: fit-content; flex-grow: 1"
                          (click)="
                            $event.stopPropagation(); selectAllSearch(key.key)
                          "
                        >
                          Select All
                        </button>
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </th>

            <th *ngIf="this.editRow || this.deleteRow" class="action-column">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let row of this.filteredData.slice(
                this.currentPage * this.pageSize,
                (this.currentPage + 1) * this.pageSize
              )
            "
          >
            <tr
              [id]="rowIdKey ? row[this.rowIdKey] : ''"
              [style.background]="
                this.highlightControlKey &&
                !row[this.highlightControlKey] &&
                this.highlightColor
              "
              #parentRow
            >
              <td
                *ngIf="this.collapsibleDetail"
                class="collapsible-row-trigger"
                (click)="activateRowCollapse(parentRow, row)"
              >
                <div><i class="zmdi zmdi-plus-circle-o"></i></div>
              </td>

              <td
                *ngFor="let obj of this.displayKeysArray"
                [innerHTML]="this.getEntryValue(row[obj.key], obj.key, row)"
                [class]="obj.key"
                [style.position]="
                  this.stickyFieldKeys.includes(obj.key) ? 'sticky' : ''
                "
                [style.left]="
                  this.stickyFieldKeys.includes(obj.key) ? '0px' : ''
                "
              ></td>

              <td *ngIf="this.editRow || this.deleteRow" class="action-column">
                <span
                  *ngIf="this.editRow"
                  (click)="this.editRowHandler.emit(row)"
                  ><i class="zmdi zmdi-edit"></i
                ></span>
                <span
                  *ngIf="this.deleteRow"
                  (click)="this.deleteRowHandler.emit(row)"
                  ><i class="zmdi zmdi-delete"></i>Delete</span
                >
              </td>
            </tr>

            <tr
              *ngIf="this.collapsibleDetail"
              class="row-collapsible-detail"
              [id]="'collapsible-row-' + row[this.rowIdKey]"
            >
              <!-- Can add to inner HTML here, but better to get it on collapse. Lesser function calls. Check function activateRowCollapse for innerHTML assignment  -->
              <!-- [innerHTML]="this.collapsibleBodyFunction(row)" -->
              <td colspan="100%"></td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div *ngIf="this.loading" id="data-table-loader">
        <div class="loader"></div>
      </div>

      <div *ngIf="this.pageCount > 1">
        <div class="pagination">
          <ul class="page">
            <li
              class="page__btn"
              (click)="
                !this.loading &&
                  (this.currentPage =
                    this.currentPage - 1 == -1
                      ? this.pageCount - 1
                      : this.currentPage - 1)
              "
            >
              <i class="zmdi zmdi-chevron-left"></i>
            </li>
            <li
              *ngFor="let index of [].constructor(this.pageCount); index as i"
              [class]="
                this.currentPage == i ? 'page__numbers active' : 'page__numbers'
              "
              (click)="this.currentPage = i"
            >
              {{ i + 1 }}
            </li>
            <li
              class="page__btn"
              (click)="
                !this.loading &&
                  (this.currentPage = (this.currentPage + 1) % this.pageCount)
              "
            >
              <i class="zmdi zmdi-chevron-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ng-template #emptyTable>
      <div class="custom-data-table-container">
        <ng-container *ngIf="this.loading; else noData">
          <div class="empty-data-table">Loading</div>
          <div id="data-table-loader">
            <div class="loader"></div>
          </div>
        </ng-container>
        <ng-template #noData>
          <div class="empty-data-table">No data to show</div>
        </ng-template>
      </div>
    </ng-template>
  </div>
  <div style="width: 20%">
    <app-parameter-filter-box
      (selected)="this.applyColumnHiding($event)"
      (applyDiff)="this.applyDiff($event)"
      (showChangingColumns)="this.showChangingColumns()"
      [isParameterView]="this.isParameterView"
      [jobs]="this.data"
      [options]="this.columns"
      [parameterViewMatchingOptions]="this.matchingJobOptions"
      [showChangingColumnsControl]="this.showChangingColumnsControl"
      [defaultSelectedColumns]="this.defaultSelectedColumns"
      [alawysSelectedColumns]="['job_name', 'diff']"
    >
    </app-parameter-filter-box>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button
    *ngFor="let config of this.inMenuButtonConfig"
    mat-menu-item
    (click)="config.action($event, this.filteredData, this.displayKeysArray)"
  >
    {{ config.text }}
  </button>
</mat-menu>
