<div>
  <button nz-button nzType="primary" (click)="drawerVisible = true">
    Add Resource
  </button>
  <nz-table
    id="copilot-data-table"
    #basicTable
    [nzData]="data"
    nzTableLayout="fixed"
  >
    <thead>
      <tr>
        <th>Project</th>
        <th>Job</th>
        <th>Image Type</th>
        <th>Shot Display</th>
        <th>Display Type</th>
        <th>Dashboard Link</th>
        <th>Speaker</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let datum of basicTable.data">
        <tr>
          <td>{{ datum.project.name }}</td>
          <td>{{ datum.job.name }}</td>
          <td>{{ datum.type }}</td>
          <td>{{ datum.shotDisplay }}</td>
          <td>{{ datum.displayType }}</td>
          <td>{{ datum.dashboardLink }}</td>
          <td>{{ datum.speaker }}</td>
          <td>
            <span
              class="_action_btn_icon"
              nz-icon
              nzType="eye"
              nzTheme="outline"
              (click)="datum.expand = !datum.expand"
            ></span>
          </td>
          <!-- (click)="getUrls(datum)" -->
        </tr>
        <tr [nzExpand]="datum.expand">
          <div>
            <ng-container *ngFor="let url of datum.presigned_urls">
              <a
                target="_blank"
                [href]="url"
                style="
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                "
                >{{ url }}</a
              >
            </ng-container>
          </div>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>

<nz-drawer
  [nzClosable]="true"
  [nzMask]="true"
  [nzMaskClosable]="true"
  [nzWidth]="320"
  nzTitle="Add resource"
  [nzVisible]="drawerVisible"
  (nzOnClose)="drawerVisible = false"
>
  <div *nzDrawerContent>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <!-- <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Description</label>
        <textarea formControlName="description" nz-input></textarea>
      </nz-input-group> -->
      <!-- <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Tags</label>
        <textarea formControlName="tags" nz-input></textarea>
      </nz-input-group> -->

      <nz-input-group
        class="legacy-input-styling"
        style="padding: 0; display: block"
      >
        <label style="left: 10px">Project</label>
        <nz-select
          style="width: 100%"
          nzShowSearch
          formControlName="project"
          [nzDisabled]="projects.length == 0"
          [nzLoading]="projects.length == 0"
          (ngModelChange)="projectSelected($event)"
        >
          <nz-option
            *ngFor="let p of projects"
            [nzLabel]="p.name"
            [nzValue]="p.id"
          >
            {{ p.name }}
          </nz-option>
        </nz-select>
      </nz-input-group>
      <nz-input-group
        class="legacy-input-styling"
        style="padding: 0; display: block"
      >
        <label style="left: 10px">Job</label>
        <nz-select
          style="width: 100%"
          nzShowSearch
          formControlName="job"
          [nzDisabled]="jobs.length == 0"
        >
          <nz-option *ngFor="let j of jobs" [nzValue]="j.id" [nzLabel]="j.name">
          </nz-option>
        </nz-select>
      </nz-input-group>
      <nz-input-group
        class="legacy-input-styling"
        style="padding: 0; display: block"
      >
        <label style="left: 10px">Type</label>
        <nz-select style="width: 100%" nzShowSearch formControlName="type">
          <nz-option
            *ngFor="let opt of ['Model', 'Shot', 'Charts', 'Table', 'Other']"
            [nzValue]="opt"
            [nzLabel]="opt"
          >
          </nz-option>
        </nz-select>
      </nz-input-group>
      <nz-input-group
        class="legacy-input-styling"
        style="padding: 0; display: block"
      >
        <label style="left: 10px">Display Type</label>
        <nz-select style="width: 100%" nzShowSearch formControlName="type">
          <nz-option
            *ngFor="
              let opt of ['VP', 'Total VP Update', 'Compare', 'Raw', 'Other']
            "
            [nzValue]="opt"
            [nzLabel]="opt"
          >
          </nz-option>
        </nz-select>
      </nz-input-group>
      <nz-input-group
        class="legacy-input-styling"
        style="padding: 0; display: block"
      >
        <label style="left: 10px">Shot Display</label>
        <nz-select
          style="width: 100%"
          nzShowSearch
          formControlName="shotDisplay"
        >
          <nz-option
            *ngFor="let opt of ['Predicted', 'Field', 'Interleaved', 'Other']"
            [nzValue]="opt"
            [nzLabel]="opt"
          >
          </nz-option>
        </nz-select>
      </nz-input-group>

      <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Dashboard Link</label>
        <input nz-input type="text" formControlName="dashboardLink" />
      </nz-input-group>
      <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Topic (main)</label>
        <input nz-input type="text" formControlName="topic" />
      </nz-input-group>
      <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Summary</label>
        <textarea nz-input type="text" formControlName="summary"></textarea>
      </nz-input-group>
      <nz-input-group
        class="legacy-input-styling"
        style="padding: 0; display: block"
      >
        <label style="left: 10px">Timestamp</label>
        <nz-time-picker
          (change)="onChangeTimestamp($event)"
          style="width: 100%"
        ></nz-time-picker>
      </nz-input-group>
      <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Transcript</label>
        <textarea nz-input type="text" formControlName="transcript"></textarea>
      </nz-input-group>
      <nz-input-group class="legacy-input-styling" style="padding: 0">
        <label style="left: 10px">Speaker</label>
        <input nz-input type="text" formControlName="speaker" />
      </nz-input-group>

      <nz-divider style="margin: 10px 0px" nzText="Media"></nz-divider>

      <!-- <nz-form-item
        style="margin: 0"
        *ngFor="let control of listOfControl; let i = index"
      >
        <nz-form-control nzErrorTip="Please input URL or upload media.">
          <nz-input-group
            nzSearch
            class="legacy-input-styling"
            style="padding: 0"
            [nzAddOnAfter]="suffixIconButton"
          >
            <label style="left: 10px">URL</label>
            <input
              nz-input
              placeholder="URL"
              [attr.id]="control.id"
              [formControlName]="control.controlInstance"
            />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button
              nz-button
              nzType="primary"
              nzDanger
              (click)="removeField(control, $event)"
            >
              <span nz-icon nzType="minus-circle-o"></span>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <button nz-button nzType="dashed" nzBlock (click)="addField($event)">
          <span nz-icon nzType="plus"></span>
          Add URL
        </button>
      </nz-form-item> -->
      <div>
        <div>
          <div
            *ngFor="let file of this.fileList; index as i"
            class="fileListing"
          >
            <span style="display: flex; flex-direction: column">
              {{ file.name }}
            </span>
            <span>
              <span
                class="_icon"
                nz-icon
                nzType="eye"
                nzTheme="outline"
                (click)="previewFile(file, i)"
                style="cursor: pointer"
              ></span>
              <span
                class="_icon"
                nz-icon
                nzType="delete"
                nzTheme="outline"
                style="cursor: pointer"
                (click)="removeFile(file, i)"
              ></span>
            </span>
          </div>
          <label for="file" style="width: fit-content">
            <button nz-button type="button" (click)="fileInp.click()">
              <span nz-icon nzType="download"></span>
              Choose Files
            </button>
          </label>
          <input
            #fileInp
            type="file"
            id="file"
            multiple
            style="display: none"
            (change)="handleFileInput($event.target.files)"
          />
        </div>
        <nz-modal
          [nzVisible]="previewVisible"
          [nzContent]="modalContent"
          [nzFooter]="null"
          (nzOnCancel)="previewVisible = false"
        >
          <ng-template #modalContent>
            <img [src]="previewImage" style="width: 100%" />
          </ng-template>
        </nz-modal>
      </div>

      <nz-form-item>
        <button nz-button nzType="primary">Submit</button>
      </nz-form-item>
    </form>
  </div>
</nz-drawer>
