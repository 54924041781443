import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-copilot-sidebar",
  templateUrl: "./copilot-sidebar.component.html",
  styleUrls: ["./copilot-sidebar.component.css"],
})
export class CopilotSidebarComponent {
  constructor(private _router: Router) {}

  menuChange(e) {
    this._router.navigate([e]);
  }
}
