import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../../../models/project';
import { CachedProjectService } from '../../../shared/services/cached-project.service';

import { instance } from '@viz-js/viz';


@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.css']
})
export class FlowchartComponent implements OnInit {
  project_id: string = '';
  project_name: string = '';
  is_getting_project_network: boolean = false;
  fm:boolean=false
  
  constructor(private router: Router, private route: ActivatedRoute, private projectService: CachedProjectService) {
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.get_project_network(this.project_id, false)
      } 
    });
  }

  getGraph(){
    this.get_project_network(this.project_id, this.fm)
  }

  async get_project_network(project_id, FM=false) {
    this.is_getting_project_network = true;
    this.projectService.getProjectNetwork(project_id, FM).toPromise().then(async (data) => {
      const datum = data.graphviz
      const legendData = data.group_names
      const legendBox = document.getElementById('legend')
      legendBox.innerHTML = ""
      for (let [key, value] of Object.entries(legendData)){
        const datum = document.createElement('span')
        datum.innerHTML = `
          <div style="display: flex; align-items: center; gap: 10px">
            <span style="display: inline-block; border-radius: 7.5px; height: 15px; width: 15px; background: ${value}"></span>
            <span>${key}</span>
          </div>`
        legendBox.appendChild(datum)
      }

      instance().then(viz => {
        const svg = viz.renderSVGElement(datum);
        document.getElementById("graph").innerHTML = ''
        document.getElementById("graph").appendChild(svg);
      });
    });
  }
  ngOnInit() {
    
  }

}
