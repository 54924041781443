import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzGridModule } from "ng-zorro-antd/grid";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTreeModule } from "ng-zorro-antd/tree";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzSwitchModule } from "ng-zorro-antd/switch";

import { NZ_ICONS, NzIconModule } from "ng-zorro-antd/icon";
import { NZ_I18N, NzI18nModule, en_US } from "ng-zorro-antd/i18n";
import { IconDefinition } from "@ant-design/icons-angular";
import * as AllIcons from "@ant-design/icons-angular/icons";

// this part is to convert the ANTD chinese texts to english
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTreeViewModule } from "ng-zorro-antd/tree-view";
import { NzHighlightModule } from "ng-zorro-antd/core/highlight";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzAlertModule } from "ng-zorro-antd/alert";

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    NzSliderModule,
    NzGridModule,
    NzRadioModule,
    NzButtonModule,
    NzInputNumberModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzCheckboxModule,
    NzI18nModule,
    NzIconModule,
    NzDrawerModule,
    NzTabsModule,
    NzTreeModule,
    NzTableModule,
    NzPopconfirmModule,
    NzMessageModule,
    NzBadgeModule,
    NzResultModule,
    NzModalModule,
    NzCollapseModule,
    NzSpinModule,
    NzToolTipModule,
    NzSwitchModule,
    NzTreeViewModule,
    NzHighlightModule,
    NzMenuModule,
    NzUploadModule,
    NzTypographyModule,
    NzNotificationModule,
    NzTimePickerModule,
    NzSpaceModule,
    NzAutocompleteModule,
    NzAlertModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
  ],
})
export class ZorroModule {}
