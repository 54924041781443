import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { PlotlyComponent } from "./plotly/plotly.component";
import { SliderComponent } from "./slider/slider.component";
import { ZorroModule } from "./zorro.module";
import { ZorroTreeviewComponent } from "./zorro-treeview/zorro-treeview.component";
import { Search2Pipe } from "./pipes/search2/search2.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ZorroModule,
    MaterialModule,
  ],
  declarations: [
    SliderComponent,
    PlotlyComponent,
    ZorroTreeviewComponent,
    Search2Pipe,
  ],
  exports: [
    SliderComponent,
    PlotlyComponent,
    Search2Pipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ZorroModule,
    ZorroTreeviewComponent,
    Search2Pipe,
  ],
})
export class SharedModule {}
