import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NzTreeFlatDataSource, NzTreeFlattener } from "ng-zorro-antd/tree-view";
import { BehaviorSubject, combineLatest } from "rxjs";
import { auditTime, map } from "rxjs/operators";

// for reference: https://ng.ant.design/components/tree-view/en#ng-content

interface TreeNode {
  name: string;
  children?: TreeNode[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

class FilteredTreeResult {
  constructor(
    public treeData: TreeNode[],
    public needsToExpanded: TreeNode[] = []
  ) {}
}

/**
 * From https://stackoverflow.com/a/45290208/6851836
 */
function filterTreeData(data: TreeNode[], value: string): FilteredTreeResult {
  const needsToExpanded = new Set<TreeNode>();
  const _filter = (node: TreeNode, result: TreeNode[]): TreeNode[] => {
    // construct regex from string, with case-insensitive flag (i)
    let re = new RegExp(value, "i");
    if (node.name.search(re) !== -1) {
      result.push(node);
      return result;
    }
    if (Array.isArray(node.children)) {
      const nodes = node.children.reduce(
        (a, b) => _filter(b, a),
        [] as TreeNode[]
      );
      if (nodes.length) {
        const parentNode = { ...node, children: nodes };
        needsToExpanded.add(parentNode);
        result.push(parentNode);
      }
    }
    return result;
  };
  const treeData = data.reduce((a, b) => _filter(b, a), [] as TreeNode[]);
  return new FilteredTreeResult(treeData, [...needsToExpanded]);
}

@Component({
  selector: "app-zorro-treeview",
  templateUrl: "./zorro-treeview.component.html",
  styleUrls: ["./zorro-treeview.component.css"],
})
export class ZorroTreeviewComponent {
  @Input() treeData: object = {};
  @Input() maxSelected: number = null;
  @Input() returnOnlyLeafs: boolean = true;
  @Input() initValue: any = null;
  @Output() onSelectedChanged = new EventEmitter<any[]>();

  nameformatter = (name: string) => name.replace(/_/g, " ");

  flatNodeMap = new Map<FlatNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, FlatNode>();
  expandedNodes: TreeNode[] = [];
  searchValue = "";
  originData$: BehaviorSubject<any> = new BehaviorSubject([]);
  searchValue$ = new BehaviorSubject<string>("");
  checklistSelection = new SelectionModel<FlatNode>(true);

  constructor() {
    this.filteredData$.subscribe((result) => {
      this.dataSource.setData(result.treeData);

      // this section is for setting the value of the treeView at initialization. The initValue simply contains a list of all the values to be selected (keys)
      // ideally this should be in the onChanges, but it doesn't work their for first initialization, cause the value of the treeControl and subsequent control variables is yet to be defined.
      if (this.initValue) {
        for (let val of this.initValue) {
          let node = this.treeControl.dataNodes.find((node) => {
            return node.name == val;
          });
          if (node) {
            if (node?.expandable) {
              this.itemSelectionToggle(node);
            } else {
              this.leafItemSelectionToggle(node);
            }
          }
        }
      }

      const hasSearchValue = !!this.searchValue;
      if (hasSearchValue) {
        if (this.expandedNodes.length === 0) {
          this.expandedNodes = this.treeControl.expansionModel.selected;
          this.treeControl.expansionModel.clear();
        }
        this.treeControl.expansionModel.select(...result.needsToExpanded);
      } else {
        if (this.expandedNodes.length) {
          this.treeControl.expansionModel.clear();
          this.treeControl.expansionModel.select(...this.expandedNodes);
          this.expandedNodes = [];
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes?.treeData) {
      this.constructOption(changes.treeData.currentValue);
    }
  }

  constructOption(data: object) {
    let temp: TreeNode[] = [];
    for (let [category, children] of Object.entries(data)) {
      temp.push({
        name: this.nameformatter(category),
        children: children.map((child: string) => ({
          name: this.nameformatter(child),
        })),
      });
    }
    this.originData$.next(temp);
  }

  // -------- tree view ng-zorro helper functions
  transformer = (node: TreeNode, level: number): FlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name
        ? existingNode
        : {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level,
          };
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  treeControl = new FlatTreeControl<FlatNode, TreeNode>(
    (node) => node.level,
    (node) => node.expandable,
    {
      trackBy: (flatNode) => this.flatNodeMap.get(flatNode)!,
    }
  );

  treeFlattener = new NzTreeFlattener<TreeNode, FlatNode, TreeNode>(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  filteredData$ = combineLatest([
    this.originData$,
    this.searchValue$.pipe(
      auditTime(300),
      map((value) => (this.searchValue = value))
    ),
  ]).pipe(
    map(([data, value]) =>
      value ? filterTreeData(data, value) : new FilteredTreeResult(data)
    )
  );

  hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every((child) => this.checklistSelection.isSelected(child))
    );
  }

  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  leafItemSelectionToggle(node: FlatNode): void {
    // check if maxSelected exits,
    // checks if the listLength is maxSelected (greater than equal to cause in the case that its a whole category selected, means 2 are selected but cause category selected it shows 3)
    // checks if you checking or unchecking with the isSelected so that we can allow unchecking but not checking
    if (
      this.maxSelected &&
      this.checklistSelection.selected.filter((n) => n.expandable == false)
        .length >= this.maxSelected && //the filter lets you get just the leafs
      !this.checklistSelection.isSelected(node)
    ) {
      // prevent selection of more than maxSelected (if value given)
      return;
    }

    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

    // emit event for parent to catch
    if (this.returnOnlyLeafs) {
      this.onSelectedChanged.emit(
        this.checklistSelection.selected.filter((n) => n.expandable == false)
      );
    } else {
      this.onSelectedChanged.emit(this.checklistSelection.selected);
    }
  }

  itemSelectionToggle(node: FlatNode): void {
    // check if maxSelected exits,
    // checks if the listLength + descendants of category is greater than maxSelected
    // and checks if you checking or unchecking with the category so that we can allow unchecking but not checking
    if (
      this.maxSelected &&
      this.checklistSelection.selected.length +
        this.treeControl.getDescendants(node).length >
        this.maxSelected &&
      !this.checklistSelection.isSelected(node)
    ) {
      // prevent selection of more than maxSelected (if value given)
      return;
    }

    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    // emit event for parent to catch
    if (this.returnOnlyLeafs) {
      this.onSelectedChanged.emit(
        this.checklistSelection.selected.filter((n) => n.expandable == false)
      );
    } else {
      this.onSelectedChanged.emit(this.checklistSelection.selected);
    }
  }

  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = node.level;

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
