import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search2",
})
export class Search2Pipe implements PipeTransform {
  transform(data: Array<any>, searchInput: string, key: string = null): any[] {
    if (!searchInput || data.length == 0) {
      return data;
    }
    searchInput = searchInput.toLowerCase();

    // if key is provided, the array is probably of objects, so filter by key.
    if (key) {
      let res = data.filter((x) => x[key].toLowerCase().includes(searchInput));
      return res;
    } else {
      // else, array of strings and can filter without key
      return data.filter((x) => x.toLowerCase().includes(searchInput));
    }
  }
}
