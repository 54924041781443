import { Component, OnInit } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { ProjectService } from "../../../shared/services/project.service";
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { CachedProjectService } from "../../../shared/services/cached-project.service";

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: "app-copilot-settings",
  templateUrl: "./copilot-settings.component.html",
  styleUrls: ["./copilot-settings.component.less"],
})
export class CopilotSettingsComponent implements OnInit {
  data: any[] = [];
  drawerVisible: boolean = false;

  validateForm!: UntypedFormGroup;
  listOfControl: Array<{ id: number; controlInstance: string }> = [];

  fileList: File[] = [];
  previewImage: string | undefined = "";
  previewVisible = false;

  isLoading: boolean = false;

  projects: any[] = [];
  jobs: any[] = [];

  constructor(
    private _projectService: ProjectService,
    private _cachedProjectService: CachedProjectService,
    private _message: NzMessageService,
    private _fb: FormBuilder,
    private _notification: NzNotificationService
  ) {
    this._cachedProjectService.getProjects().subscribe((p) => {
      this.projects = p;
    });

    this._projectService
      .getCopilotData()
      .toPromise()
      .then((res) => {
        console.log(res);
        this.data = res.data.map((datum) => ({ ...datum, expand: false }));
      })
      .catch((err) => {
        this._message.error(err);
      });
  }
  ngOnInit(): void {
    this.validateForm = this._fb.group({
      project: new UntypedFormControl(""),
      job: new UntypedFormControl(""),
      type: new UntypedFormControl("Other"),
      shotDisplay: new UntypedFormControl("Other"),
      displayType: new UntypedFormControl("Other"),
      dashboardLink: new UntypedFormControl(""),
      topic: new UntypedFormControl(""),
      summary: new UntypedFormControl(""),
      timestamp: new UntypedFormControl("00:00:00"),
      transcript: new UntypedFormControl(""),
      speaker: new UntypedFormControl(""),
    });
    // this.validateForm = this._fb.group({
    //   project: new UntypedFormControl("532245cf-d494-404e-b8f9-e18594055404"),
    //   job: new UntypedFormControl("3a07801d-170b-4624-878f-df0cd6fc3a4c"),
    //   type: new UntypedFormControl("Other"),
    //   shotDisplay: new UntypedFormControl("Field"),
    //   displayType: new UntypedFormControl("Other"),
    //   dashboardLink: new UntypedFormControl("http"),
    //   topic: new UntypedFormControl("a new topic"),
    //   summary: new UntypedFormControl("a random summary"),
    //   timestamp: new UntypedFormControl("00:00:00"),
    //   transcript: new UntypedFormControl("this transcript"),
    //   speaker: new UntypedFormControl("CM"),
    // });
    // this.addField();
  }

  projectSelected(selectedProject: string) {
    let project = this.projects.find((p) => p.id == selectedProject);
    if (!project) return;
    this.jobs = project.jobs;
  }

  onChangeTimestamp(event) {
    console.log("e", event);
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      var urls = [];
      this.listOfControl.map((control) => {
        var key = control.controlInstance;
        if (this.validateForm.value[key]) {
          urls.push(this.validateForm.value[key]);
        }
      });
      // console.log(urls);
      if (urls.length == 0 && this.fileList.length == 0) {
        this._notification.error(
          "Missing Media",
          "You must either provide URLs for the media, or the upload the media itself"
        );
        return;
      }

      // the case where all input is fine so actually make a request
      // console.log(this.validateForm);
      this.makeRequestToBackend();
    } else {
      // mainly boilerplate. Refer to ng-zorro documentation for better understanding
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  addField(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const id =
      this.listOfControl.length > 0
        ? this.listOfControl[this.listOfControl.length - 1].id + 1
        : 0;

    const control = {
      id,
      controlInstance: `url${id}`,
    };
    const index = this.listOfControl.push(control);
    console.log(this.listOfControl[this.listOfControl.length - 1]);
    this.validateForm.addControl(
      this.listOfControl[index - 1].controlInstance,
      new UntypedFormControl(null)
    );
  }
  removeField(i: { id: number; controlInstance: string }, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControl.length > 1) {
      const index = this.listOfControl.indexOf(i);
      this.listOfControl.splice(index, 1);
      console.log(this.listOfControl);
      this.validateForm.removeControl(i.controlInstance);
    }
  }

  handleFileInput(files: FileList) {
    this.fileList.push(...Array.from(files));
  }

  async previewFile(file: File, index: number) {
    var preview = null;
    preview = await getBase64(file);
    this.previewImage = preview || null;
    this.previewVisible = true;
  }
  removeFile(file: File, index: number) {
    this.fileList.splice(index, 1);
  }

  makeRequestToBackend() {
    this.isLoading = true;
    console.log("making backend request", this.fileList);

    var formData = new FormData();
    for (let file of this.fileList) {
      formData.append("file", file);
    }

    const vals = this.validateForm.value;
    for (let [key, value] of Object.entries(vals)) {
      formData.append(key, value?.toString());
    }
    // const data = [
    //   { key: 'name', value: 'John' },
    //   { key: 'age', value: 30 },
    //   { key: 'occupation', value: 'Developer' }
    // ];
    // data.forEach(item => formData.append(item.key, item.value?.toString()));

    this._projectService
      .putCopilotData(formData)
      .toPromise()
      .then((res) => console.log("res", res))
      .catch((err) => console.log(err))
      .finally(() => (this.isLoading = false));
  }

  getUrls(datum: { id: string }) {
    this._projectService
      .getCopilotDataUrls(datum.id)
      .toPromise()
      .then((res) => console.log("res", res))
      .catch((err) => console.log(err));
  }
}
