import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subscription, Observable, Subject } from "rxjs";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { CachedUserService } from "../../shared/services/cached-user.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.less"],
})
export class BannerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  isStaff = false;

  @Input("button-text") buttonText: string;
  @Input() heading: string;
  @Input() description: string;
  @Output("button-event") buttonLink = new EventEmitter();
  @Input() small: boolean;

  projectControl = new UntypedFormControl();
  jobControl = new UntypedFormControl();
  projectFilteredOptions: Observable<any[]>;
  jobFilteredOptions: Observable<any[]>;

  projects: any[];
  jobs: any[];

  constructor(
    private projectService: CachedProjectService,
    private router: Router,
    private userService: CachedUserService
  ) {}

  ngOnInit() {
    this.projectControl.disable();
    this.jobControl.disable();

    this.userService.userDetail
      .pipe(takeUntil(this.destroy$))
      .subscribe((d) => {
        this.isStaff = d && d.isStaff;
      });

    this.projectService
      .getProjects()
      .pipe(takeUntil(this.destroy$))
      .subscribe((p) => {
        this.projects = p;
        this.projectControl.enable();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  projectSelected2(selectedProject: string) {
    let project = this.projects.find((p) => p.name == selectedProject);
    if (!project) return;
    this.jobs = project.jobs;
    this.jobControl.enable();
  }

  jobSelected2(selectedJob: string) {
    let job = this.jobs.find((j) => j.name == selectedJob);
    this.router.navigate(["/projects/" + job.id]);
  }

  goToProjectPage() {
    if (this.projectControl.value == "" || this.projectControl.value == null)
      return;
    let activeProject = this.projects.find(
      (p) => p.name == this.projectControl.value
    );
    this.router.navigate(["project", activeProject.id, "parameter-view"]);
  }

  goToCostAnalysis() {
    this.router.navigate(["cost-analysis"]);
  }

  buttonPressed() {
    this.buttonLink.emit();
  }
}
