import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { ProjectsComponent } from "./projects/projects.component";
import { ProjectComponent } from "./projects/project/project.component";
import { ModelComponent } from "./projects/project/model/model.component";
import { LogComponent } from "./projects/project/log/log.component";
import { DataComponent } from "./projects/project/data/data.component";
import { CostsComponent } from "./projects/project/costs/costs.component";
import { CallbackComponent } from "./callback/callback.component";
import { BillingComponent } from "./billing/billing.component";

import { AuthGuardService } from "./shared/services/auth-guard.service";

import { PlotsComponent } from "./projects/project/plots/plots.component";
import { ShotImageComponent } from "./projects/project/shot-image/shot-image.component";
import { MonitoringComponent } from "./monitoring/monitoring.component";
import { ScoresComponent } from "./monitoring/scores/scores.component";
import { SearchComponent } from "./monitoring/search/search.component";
import { RunfileComponent } from "./projects/project/runfile/runfile.component";
import { FilesComponent } from "./projects/project/files/files.component";

import { ScreenshotComponent } from "./projects/project/screenshot/screenshot.component";
import { DemoPageComponent } from "./demo/demo-page/demo-page.component";
import { DemoPageResolver } from "./demo/demo-page/demo-page.resolver";
import { TablesComponent } from "./projects/project/tables/tables.component";
// import { DiffModelComponent } from "./projects/project/diff-model/diff-model.component";
import { ProjectTableComponent } from "./projects/project-view/project-table/project-table.component";
import { ParameterOverviewComponent } from "./projects/project-view/parameter-overview/parameter-overview.component";
import { FlowchartComponent } from "./projects/project-view/flowchart/flowchart.component";
import { ImagePanelComponent } from "./projects/project-view/image-panel/image-panel.component";
import { ProjectViewComponent } from "./projects/project-view/project-view.component";
import { DataTableComponent } from "./projects/project-view/data-table/data-table.component";
import { ModelTableComponent } from "./projects/project-view/model-table/model-table.component";
import { ParameterOverview2Component } from "./projects/project-view/parameter-overview-2/parameter-overview-2.component";
import { Flowchart2Component } from "./projects/project-view/flowchart2/flowchart2.component";
import { CostsAnalysisDashboardComponent } from "./costs/costs-analysis-dashboard/costs-analysis-dashboard.component";
import { StorageTableComponent } from "./projects/project-view/storage-table/storage-table.component";
import { JobGroupsComponent } from "./projects/project-view/job-groups/job-groups.component";
import { TraceFitsPlottingComponent } from "./projects/project-view/trace-fits-plotting/trace-fits-plotting.component";
import { CopilotComponent } from "./copilot/copilot.component";
import { CopilotSettingsComponent } from "./copilot/copilot-settings/copilot-settings/copilot-settings.component";
import { CopilotBotComponent } from "./copilot/copilot-bot/copilot-bot.component";

const routes: Routes = [
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: "unauthorised", redirectTo: "/login", pathMatch: "full" },
  { path: "", redirectTo: "/projects", pathMatch: "full" },
  { path: "callback", component: CallbackComponent },
  { path: "billing", component: BillingComponent, pathMatch: "full" },
  { path: "cost-analysis", component: CostsAnalysisDashboardComponent },
  {
    path: "monitoring",
    component: MonitoringComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        redirectTo: "scores",
        pathMatch: "full",
      },
      {
        path: "scores",
        component: ScoresComponent,
      },
      {
        path: "search",
        component: SearchComponent,
      },
    ],
  },
  {
    path: "projects",
    component: ProjectsComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService],
  },
  {
    path: "projects/:id",
    component: ProjectComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        redirectTo: "model",
        pathMatch: "full",
      },
      {
        path: "model",
        component: ModelComponent,
      },
      {
        path: "job",
        component: DataComponent,
      },
      {
        path: "costs",
        component: CostsComponent,
      },
      {
        path: "charts",
        component: PlotsComponent,
      },
      {
        path: "shots",
        component: ShotImageComponent,
      },
      {
        path: "demo",
        component: DemoPageComponent,
        resolve: { payload: DemoPageResolver },
      },
      {
        path: "runfile",
        component: RunfileComponent,
      },
      {
        path: "picture",
        component: ScreenshotComponent,
      },
      {
        path: "files",
        component: FilesComponent,
      },
      {
        path: "tables",
        component: TablesComponent,
      },
      // { path: "log", component: LogComponent },
    ],
  },
  {
    path: "copilot",
    canActivate: [AuthGuardService],
    component: CopilotComponent,
    children: [
      { path: "", component: CopilotSettingsComponent },
      { path: "chat", component: CopilotBotComponent },
    ],
  },
  {
    path: "project/:id",
    canActivate: [AuthGuardService],
    component: ProjectViewComponent,
    children: [
      {
        path: "",
        redirectTo: "table",
        pathMatch: "full",
      },
      // {
      //   path: "table",
      //   component: ProjectTableComponent,
      // },
      {
        path: "table",
        component: StorageTableComponent,
      },
      // {
      //   path: "parameter-view",
      //   component: ParameterOverviewComponent,
      // },
      {
        path: "parameter-view",
        component: ParameterOverview2Component,
      },
      {
        path: "trace-fits-plotting",
        component: TraceFitsPlottingComponent,
      },
      {
        path: "job-groups",
        component: JobGroupsComponent,
      },
      // {
      //   path: "parameter-view2",
      //   component: ParameterOverview2Component,
      // },
      {
        path: "data-table",
        component: DataTableComponent,
      },
      {
        path: "data-table/:trace_locator",
        component: DataTableComponent,
      },
      {
        path: "model-table",
        component: ModelTableComponent,
      },
      {
        path: "flowchart",
        component: FlowchartComponent,
      },
      {
        path: "flowchart2",
        component: Flowchart2Component,
      },
      {
        path: "image-panel",
        component: ImagePanelComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
