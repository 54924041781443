import { Component } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { ProjectService } from "../../../shared/services/project.service";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";

interface JobItem {
  id: string;
  job__id: string;
  job__job_name: string;
  job__job_basepath: string;
}

interface GroupItem {
  id: string;
  name: string;
  project_name: string;
  created_by_name: string;
  comment: string;
  jobs: JobItem[];
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<GroupItem> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterMultiple?: boolean;
  filterFn?: NzTableFilterFn<GroupItem> | null;
  width?: string;
}

@Component({
  selector: "app-job-groups",
  templateUrl: "./job-groups.component.html",
  styleUrls: ["./job-groups.component.less"],
})
export class JobGroupsComponent {
  listOfColumns: ColumnItem[] = [
    {
      name: "Name",
      sortOrder: null,
      sortFn: (a: GroupItem, b: GroupItem) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", null],
    },
    {
      name: "Comment",
      sortOrder: null,
      sortFn: (a: GroupItem, b: GroupItem) =>
        a.comment.localeCompare(b.comment),
      sortDirections: ["ascend", "descend", null],
    },
    {
      name: "Created By",
      sortOrder: null,
      sortFn: (a: GroupItem, b: GroupItem) =>
        a.created_by_name.localeCompare(b.created_by_name),
      sortDirections: ["ascend", "descend", null],
    },
  ];

  createModalVisible: boolean = false;
  createFormData: FormBuilder;
  project_id: string;
  project_name: string;
  parentJobs: any[] = [];
  nameControl: FormControl;
  commentControl: FormControl;
  jobsControl: FormControl;
  isLoading: boolean = false;
  gettingParentJobs: boolean = true;
  jobGroups: ColumnItem[] = [];
  edittingGroupId: string = null;

  constructor(
    private _cachedProjectService: CachedProjectService,
    private _projectService: ProjectService,
    private _message: NzMessageService
  ) {
    this._cachedProjectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.getJobGroups();
        this.getParentJobs();
      }
    });
  }

  ngOnInit() {
    this.nameControl = new FormControl("", Validators.required);
    this.commentControl = new FormControl("");
    this.jobsControl = new FormControl([], Validators.required);
  }

  getParentJobs() {
    this.gettingParentJobs = true;
    this._cachedProjectService
      ._getProjectJobs(this.project_id, {
        fields: ["id", "job_name"],
      })
      .toPromise()
      .then((res) => {
        this.parentJobs = res.data;
      })
      .catch((err) => console.log(err))
      .finally(() => (this.gettingParentJobs = false));
  }

  getJobGroups() {
    this.isLoading = true;
    this._projectService
      .getJobGroups(this.project_id)
      .toPromise()
      .then((res) => {
        this.jobGroups = res.data.map((d) => ({ ...d, expand: false }));
      })
      .catch((err) => this._message.error(err.message))
      .finally(() => (this.isLoading = false));
  }

  // this function is used for both creating and editting
  createGroup() {
    // For form validation and showing errors
    if (!this.nameControl.value || this.nameControl.value == "") {
      this.nameControl.setErrors({ required: true });
    } else if (!this.jobsControl.value || this.jobsControl.value.length == 0) {
      this.jobsControl.setErrors({ required: true });
    } else {
      // if all is well
      this.isLoading = true;
      this._projectService
        .createJobGroups({
          project_id: this.project_id,
          id: this.edittingGroupId,
          name: this.nameControl.value,
          comment: this.commentControl.value,
          jobs: this.jobsControl.value,
        })
        .toPromise()
        .then((res) => {
          this.jobGroups = res.data.map((d) => ({ ...d, expand: false }));
          if (this.edittingGroupId) {
            this._message.success("Successfully edited job group");
          } else {
            this._message.success("Successfully created job group");
          }
          this.resetFields();
        })
        .catch((err) => {
          console.log(err);
          this._message.error(err.message);
        })
        .finally(() => (this.isLoading = false));
    }
  }

  editGroup(data: GroupItem) {
    this.nameControl.setValue(data.name);
    this.edittingGroupId = data.id;
    this.commentControl.setValue(data.comment);
    this.jobsControl.setValue(data.jobs.map((j) => j.job__id));
    this.createModalVisible = true;
  }
  deleteGroup(data: GroupItem) {
    console.log("delete group", data);
    this.isLoading = true;
    this._projectService
      .deleteJobGroups({ group: [data.id] }, this.project_id)
      .toPromise()
      .then((res) => {
        this.jobGroups = res.data.map((d) => ({ ...d, expand: false }));
        this._message.success("Successfully remove job from group");
      })
      .catch((err) => {
        this._message.error(err.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  removeJobFromGroup(data: JobItem) {
    console.log("remove job from group", data);
    this.isLoading = true;
    this._projectService
      .deleteJobGroups({ jobs: [data.id] }, this.project_id)
      .toPromise()
      .then((res) => {
        this.jobGroups = res.data.map((d) => ({ ...d, expand: false }));
        this._message.success("Successfully remove job from group");
      })
      .catch((err) => {
        this._message.error(err.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  openGroup(data: GroupItem) {
    window.open(
      `/projects/${data.jobs[0].job__id}/model?${data.jobs
        .map((job: JobItem) => `jobs=${job.job__id}`)
        .join("&")}`,
      "_blank"
    );
  }

  openGroupInParameterView(data: GroupItem) {
    window.open(
      `/project/${this.project_id}/parameter-view?${data.jobs
        .map((job: JobItem) => `jobs=${job.job__id}`)
        .join("&")}`,
      "_blank"
    );
  }

  openGroupInTracefitsView(data: GroupItem) {
    window.open(
      `/project/${this.project_id}/trace-fits-plotting?${data.jobs
        .map((job: JobItem) => `jobs=${job.job__id}`)
        .join("&")}`,
      "_blank"
    );
  }

  resetFields() {
    this.commentControl.reset();
    this.nameControl.reset();
    this.jobsControl.reset();
    this.edittingGroupId = null;
    this.createModalVisible = false;
  }
}
